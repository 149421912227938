@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

:focus {
  outline: 0;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: block;
}

a:hover {
  text-decoration: none;
}

input, textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

label, button, input[type=submit], input[type=button] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

button, input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

* {
  box-sizing: border-box;
}

/*@import '_fonts.pcss';*/
:root {
  --black: #242426;
  --black-regular: #363638;
  --dark-gray: #4c4e50;
  --mid-gray: #75767a;
  --light-line-i: #d0d1d3;
  --light-line-ii: #e7e8e9;
  --gray-bg: #f3f3f3;
  --white: #fff;
  --midnight: #560b75;
  --purple: rgb(128, 0, 179);
  --lavender: #b34ce3;
  --pink: #c82a7a;
  --rose: #db509b;
  --font-main: "new-order", sans-serif;
  --font-second: "Rubik", sans-serif;
  --vh: calc(100vh / 100);
  --dvh: calc(100vh / 100);
  --fz: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  --line-height: 1.5;
  --header-height: clamp(78px, calc(78px + 42.00 * ((100vw - 768px) / 1)), 120px);
}

@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.eot?htnzac");
  src: url("../../assets/fonts/icomoon.eot?htnzac#iefix") format("embedded-opentype"), url("../../assets/fonts/icomoon.ttf?htnzac") format("truetype"), url("../../assets/fonts/icomoon.woff?htnzac") format("woff"), url("../../assets/fonts/icomoon.svg?htnzac#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail-button:before {
  content: "\e90f";
}

.icon-download:before {
  content: "\e901";
}

.icon-filters:before {
  content: "\e900";
}

.icon-arrow-down:before {
  content: "\e902";
}

.icon-arrow-dropdown:before {
  content: "\e903";
}

.icon-arrow-left:before {
  content: "\e904";
}

.icon-arrow-right:before {
  content: "\e905";
}

.icon-arrow-up:before {
  content: "\e906";
}

.icon-check:before {
  content: "\e907";
}

.icon-check-second:before {
  content: "\e908";
}

.icon-circle:before {
  content: "\e909";
}

.icon-close:before {
  content: "\e90a";
}

.icon-close-popup:before {
  content: "\e90b";
}

.icon-facebook-button:before {
  content: "\e90c";
}

.icon-instagram-button:before {
  content: "\e90d";
}

.icon-linkedin-button:before {
  content: "\e90e";
}

.icon-minus:before {
  content: "\e910";
}

.icon-pagination-left:before {
  content: "\e911";
}

.icon-pagination-right:before {
  content: "\e912";
}

.icon-phone-button:before {
  content: "\e913";
}

.icon-plus:before {
  content: "\e914";
}

.icon-plus-second:before {
  content: "\e915";
}

.icon-quotes-bottom:before {
  content: "\e916";
}

.icon-quotes-top:before {
  content: "\e917";
}

.icon-quotes-top-line:before {
  content: "\e918";
}

.icon-threds-button:before {
  content: "\e919";
}

.icon-twitter-button:before {
  content: "\e91a";
}

.icon-upload:before {
  content: "\e91b";
}

/*
@font-face {
  font-family: 'New Order';
  src: url('https://use.typekit.net/af/5689eb/00000000000000007735ee23/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5689eb/00000000000000007735ee23/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5689eb/00000000000000007735ee23/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'New Order';
  src: url('https://use.typekit.net/af/01d619/00000000000000007735ee26/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/01d619/00000000000000007735ee26/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/01d619/00000000000000007735ee26/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'New Order';
  src: url('https://use.typekit.net/af/0aebd6/00000000000000007735ee24/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/0aebd6/00000000000000007735ee24/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/0aebd6/00000000000000007735ee24/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}
*/
h1 {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: clamp(46px, 46px + 26 * (100vw - 768px) / 672, 72px);
  line-height: clamp(64px, 64px + 16 * (100vw - 768px) / 672, 80px);
}

h1 span {
  display: inline-block;
  font-weight: 600;
  text-transform: none;
  font-size: clamp(36px, 36px + 28 * (100vw - 768px) / 672, 64px);
  line-height: clamp(43px, 43px + 29 * (100vw - 768px) / 672, 72px);
}

@media not all and (min-width: 375px) {
  h1 {
    font-size: 40px;
  }
}
h2 {
  font-size: clamp(28px, 28px + 16 * (100vw - 768px) / 672, 44px);
  line-height: clamp(34px, 34px + 20 * (100vw - 768px) / 672, 54px);
  letter-spacing: 0.02em;
  font-weight: 600;
}

h2.md {
  font-size: clamp(28px, 28px + 28 * (100vw - 768px) / 672, 56px);
  line-height: clamp(34px, 34px + 30 * (100vw - 768px) / 672, 64px);
}

h2.mmd {
  font-size: clamp(28px, 28px + 36 * (100vw - 768px) / 672, 64px);
  line-height: 1.125;
  letter-spacing: 0.02em;
  font-weight: 600;
}

h2.lg {
  font-size: clamp(36px, 36px + 36 * (100vw - 768px) / 672, 72px);
  line-height: clamp(43px, 43px + 37 * (100vw - 768px) / 672, 80px);
}

h2.sm {
  font-size: clamp(24px, 24px + 12 * (100vw - 768px) / 672, 36px);
  line-height: clamp(30px, 30px + 18 * (100vw - 768px) / 672, 48px);
}

.sup {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  line-height: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  font-weight: 600;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

@media not all and (min-width: 640px) {
  .sup {
    letter-spacing: 0.16em;
  }
}
.sub {
  font-size: clamp(20px, 20px + 8 * (100vw - 768px) / 672, 28px);
  line-height: clamp(28px, 28px + 8 * (100vw - 768px) / 672, 36px);
  letter-spacing: 0.02em;
}

@media not all and (min-width: 375px) {
  h1 {
    font-size: 30px;
    line-height: 36px;
  }
  h1 span {
    font-size: 28px;
    line-height: 34px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h2.md {
    font-size: 26px;
    line-height: 34px;
  }
}
.content-element {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: clamp(27px, 27px + 3 * (100vw - 768px) / 672, 30px);
  letter-spacing: 0.01em;
}

.content-element p:not(:last-child), .content-element ul:not(:last-child), .content-element li:not(:last-child) {
  /*not design*/
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.content-element ol {
  list-style-type: none;
  counter-reset: num;
  display: grid;
}

.content-element ol li {
  position: relative;
  padding-left: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

.content-element ol li:before {
  position: absolute;
  color: inherit;
  top: 0;
  left: clamp(0px, 0px + 5 * (100vw - 768px) / 672, 5px);
  font: inherit;
  content: counter(num) ".";
  counter-increment: num;
}

.content-element ol li:not(:last-child) {
  margin-bottom: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
}

.content-element ul {
  display: grid;
}

.content-element ul li {
  position: relative;
  display: block;
  padding-left: clamp(14px, 14px + 12 * (100vw - 768px) / 672, 26px);
}

.content-element ul li:before {
  position: absolute;
  background: var(--black);
  border-radius: 50%;
  content: "";
  width: 5px;
  height: 5px;
  top: 8px;
  left: clamp(4px, 4px + 7 * (100vw - 768px) / 672, 11px);
}

.content-element ul li:not(:last-child) {
  margin-bottom: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
}

.content-element.body-text-2 p:not(:last-child), .content-element.body-text-2 ul:not(:last-child), .content-element.body-text-2 li:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.headline-3 {
  font-size: clamp(28px, 28px + 36 * (100vw - 768px) / 672, 64px);
  line-height: 1.125;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.headline-4 {
  font-size: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  line-height: 1.1428;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.headline-5 {
  font-size: clamp(28px, 28px + 16 * (100vw - 768px) / 672, 44px);
  line-height: 1.2272;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.headline-6 {
  font-size: clamp(22px, 22px + 14 * (100vw - 768px) / 672, 36px);
  line-height: 1.33;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.body-text-1 {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.body-text-1[data-simplebar-popup] {
  width: calc(100% + 10px);
  padding-right: 10px;
  --mb: clamp(10px, calc(10px + 2.00 * ((100vw - 768px) / 672)), 12px);
  --mb-count: 2;
  --line-count: 10;
  --fz: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + var(--mb) * var(--mb-count));
}

.body-text-2 {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.body-text-2[data-simplebar-popup] {
  width: calc(100% + 10px);
  padding-right: 10px;
  --mb: clamp(10px, calc(10px + 2.00 * ((100vw - 768px) / 672)), 12px);
  --mb-count: 2;
  --line-count: 10;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + var(--mb) * var(--mb-count));
}

.subtitle-1 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
  font-weight: 600;
}

@media (max-width: 360px) {
  .subtitle-1 {
    font-size: 22px;
  }
}
.subtitle-2 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
}

@media (max-width: 360px) {
  .subtitle-2 {
    font-size: 22px;
  }
}
.subtitle-3 {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: 1.4545;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.subtitle-4 {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: 1.4545;
  letter-spacing: 0.02em;
  font-weight: 500;
}

.first-subtitle-4 p:first-child {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: 1.4545;
  letter-spacing: 0.02em;
  font-weight: 500;
}

.first-subtitle-4 p:first-child:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

/*.dark-bg {
  color: var(--white);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--white);
  }

  .content-element {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      a {
        color: var(--white);

        !*not design*!
        &:hover {
          color: var(--rose);
        }
      }
    }
  }
}*/
@media (max-width: 370px) {
  .headline-5, h2 {
    font-size: 22px;
  }
}
.fp-watermark {
  display: none;
}

html {
  font-family: var(--font-main);
  font-size: var(--fz);
  line-height: var(--line-height); /*1.5*/
  letter-spacing: 0.01em;
  scroll-padding-top: 70px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--midnight) rgba(30, 30, 30, 0.2);
}

html::-webkit-scrollbar {
  width: 6px;
}

html::-webkit-scrollbar-track {
  background: rgba(30, 30, 30, 0.2);
  border-radius: 0;
}

html::-webkit-scrollbar-thumb {
  background: var(--midnight);
  border-radius: 0;
}

@media (max-width: 650px) {
  html {
    line-height: 1.333;
  }
}
body {
  min-width: 320px;
  background-color: var(--white);
  color: var(--black-regular);
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  overflow: hidden;
}

#site:not(.safari) .header.sticky .header__btn-box .btn {
  padding: 8px 20px;
}

@media (min-width: 641px) {
  #site.jobs-search-page {
    overflow: visible;
  }
}
.main {
  flex-grow: 1;
}

.cont {
  width: 91.3888888889%;
  margin: 0 auto;
  max-width: 1316px;
  padding: 0 20px;
}

@media (max-width: 1024px) {
  .cont {
    width: 100%;
    padding: 0 24px;
  }
}
[data-simplebar] {
  width: 100%;
}

[data-simplebar] .simplebar-track.simplebar-horizontal {
  display: none;
}

[data-simplebar] .simplebar-track.simplebar-vertical {
  width: 4px;
  right: clamp(-6px, 0px + -6 * (100vw - 768px) / 672, 0px);
}

[data-simplebar] .simplebar-scrollbar::before {
  border-radius: 0;
  opacity: 1 !important;
  top: 0 !important;
  left: 0;
  bottom: 0 !important;
  right: 0;
  background-color: var(--white);
}

[data-simplebar] .simplebar-track {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0;
  transform: translateX(8px);
}

[data-simplebar].simple-dark .simplebar-scrollbar::before {
  background-color: var(--lavender);
}

[data-simplebar].simple-dark .simplebar-track {
  background-color: rgba(128, 0, 179, 0.2);
}

[data-simplebar].scroll-center {
  width: calc(100% + 12px);
  padding: 0 6px;
  max-height: calc(clamp(27px, 27px + 3 * (100vw - 768px) / 672, 30px) * 14);
}

[data-simplebar].scroll {
  padding-right: clamp(0px, 6px + -6 * (100vw - 768px) / 672, 6px);
  max-height: calc(clamp(27px, 27px + 3 * (100vw - 768px) / 672, 30px) * 14);
}

.swiper {
  width: 100%;
  overflow: visible;
}

.swiper-wrapper {
  left: 0 !important;
}

video, svg {
  width: 100%;
  height: 100%;
}

.home-page img {
  width: 100%;
  height: 100%;
}

img, video {
  -o-object-fit: cover;
  object-fit: cover;
}

svg {
  -o-object-fit: contain;
  object-fit: contain;
}

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;
}

#bold-credits:hover {
  transform: scale(1.1);
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

#site.cookie-terms-page .main {
  flex-grow: 1;
  padding: 0;
}

.cookie-terms-section {
  padding: clamp(140px, 140px + 110 * (100vw - 768px) / 672, 250px) 0 clamp(50px, 50px + 100 * (100vw - 768px) / 672, 150px);
}

.cookie-terms-section p {
  padding: 0;
  margin-bottom: 10px;
}

.cookie-terms-section ul, .cookie-terms-section ol {
  padding-left: 20px;
}

.cookie-terms-section ul li p, .cookie-terms-section ol li p {
  margin-bottom: 0;
}

.cookie-terms-section ul {
  list-style: circle;
}

.cookie-terms-section ol {
  list-style: decimal;
}

.cookie-terms-section a {
  display: inline;
  text-decoration: underline;
  transition: 0.2s;
}

.cookie-terms-section a:hover {
  opacity: 0.7;
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.single-page-404 h1, .single-page-404 p {
  margin-bottom: 15px;
  padding: 0;
}

.single-page-404 a {
  margin-top: 20px;
}

@media (min-width: 769px) {
  #site:not(.safari) .header__contact:before {
    transform: translateY(-3px);
  }
}
.swiper-buttons {
  display: flex;
  align-items: center;
  gap: 11px;
  justify-content: center;
}

.section-bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-bg img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.section-bg [data-parallax] {
  position: absolute;
  left: 0;
  top: 0;
  width: 110%;
  height: 110%;
}

.section-bg [data-parallax] [data-depth] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.bg-layer {
  position: absolute;
}

.toggle-btn {
  --icon-size: 12px;
  --icon-color: var(--pink);
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 6px;
  border: 1px solid var(--rose);
  cursor: pointer;
  transition: 0.3s;
}

.toggle-btn::before, .toggle-btn::after {
  content: "";
  display: block;
  width: var(--icon-size);
  height: calc(var(--icon-size) / 6);
  border-radius: 2px;
  background: var(--icon-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.toggle-btn::after {
  height: var(--icon-size);
  width: calc(var(--icon-size) / 6);
}

.toggle-btn:hover {
  background: var(--rose);
  --icon-color: var(--white);
}

.toggle-btn.active {
  border-color: var(--black);
  --icon-color: var(--black);
}

.swiper-controls {
  display: flex;
  align-items: center;
  gap: clamp(25px, 25px + 31 * (100vw - 768px) / 672, 56px);
}

.swiper-controls .swiper-scrollbar-wrapper {
  flex: 1;
  overflow: hidden;
}

.swiper-controls .swiper-scrollbar {
  width: 100%;
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  flex: 1;
}

.swiper-controls .swiper-scrollbar-drag {
  background: var(--lavender);
}

.arrow-btn {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  letter-spacing: 0.04em;
  color: var(--midnight);
  cursor: pointer;
  gap: 6px;
  transition: 0.3s ease;
  line-height: normal;
  font-family: var(--font-second);
}

.arrow-btn span {
  /*margin-top: -5px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.01);
  border: 1px solid var(--rose);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}

.arrow-btn span::before {
  content: "\e905";
  font-family: "icomoon" !important;
  transform: translate(1px);
  font-size: 12px;
}

.arrow-btn--wh {
  color: var(--white);
}

.arrow-btn--wh span {
  border-color: var(--white);
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 5px 29px;
  text-align: center;
  font-weight: 500;
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: var(--font-second);
  color: var(--midnight);
  border: 1px solid var(--rose);
  border-radius: 1000px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  cursor: pointer;
  transition: 0.3s ease;
  min-height: 48px;
  line-height: normal;
}

.btn:hover {
  background-color: var(--rose);
  color: var(--white);
}

.btn--transp {
  border-color: var(--white);
  background: rgba(255, 255, 255, 0.01);
  color: var(--white);
}

.btn--transp:hover {
  background-color: var(--white);
  color: var(--rose);
}

.btn--pink {
  border-color: var(--pink);
  color: var(--white);
  background-color: var(--pink);
}

.btn--pink:hover {
  background-color: var(--white);
  color: var(--pink);
}

.btn--text-purple {
  color: var(--purple);
}

.btn--text-purple:hover {
  color: var(--white);
}

@media not all and (min-width: 640px) {
  .btn {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.btn.btn--svg {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 8px;
  max-height: 48px;
  padding-right: 22px !important;
}

.btn.btn--svg svg {
  width: 24px;
  height: 24px;
}

.btn.btn--svg svg path {
  transition: all 0.3s ease;
  fill: var(--midnight);
}

.btn.btn--svg:hover svg path {
  fill: var(--white);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
  grid-row-gap: 12px;
}

@media (max-width: 490px) {
  .buttons {
    width: 100%;
  }
  .buttons .btn {
    width: 100%;
  }
}
.slider-btn {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2;
  border: 1px solid var(--light-line-i);
  font-size: 12.38px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.08);
  color: var(--white);
}

.slider-btn::before {
  content: "\e904";
  font-family: "icomoon" !important;
  display: inline-block;
  line-height: 1 !important;
  transform: translateY(7%);
}

.slider-btn.next::before {
  content: "\e905";
}

.slider-btn.slider-btn--pink {
  width: 48px;
  height: 48px;
  background-color: var(--white);
  border: 1px solid var(--pink);
  box-shadow: none;
  color: var(--midnight);
}

.slider-btn.slider-btn--pink:hover {
  color: var(--white);
  background: var(--pink);
}

.slider-btn.slider-btn--primary {
  width: clamp(48px, 48px + 16 * (100vw - 768px) / 672, 64px);
  height: clamp(48px, 48px + 16 * (100vw - 768px) / 672, 64px);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  color: var(--midnight);
}

.slider-btn.slider-btn--primary:hover {
  color: var(--white);
  background-color: var(--midnight);
}

.slider-btn.swiper-button-lock {
  display: none !important;
}

.slider-btn.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.slider-btn:hover {
  background-color: var(--midnight);
}

.read-more-btn {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 0;
  cursor: pointer;
  gap: 8px;
  color: var(--midnight);
  font-weight: 600;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  align-items: center;
  transition: 0.3s;
  letter-spacing: 0.04em;
}

.read-more-btn i {
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--rose);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
  color: var(--pink);
  font-size: 13px;
  transition: 0.3s;
  padding-top: 1px;
}

.read-more-btn.read-more-btn-white {
  color: var(--white);
}

.read-more-btn.read-more-btn-white i {
  border-color: var(--white);
  color: var(--white);
}

.read-more-btn:hover {
  color: var(--pink);
}

.read-more-btn:hover i {
  border-color: var(--white);
  color: var(--white);
  background: var(--pink);
}

@media not all and (min-width: 375px) {
  .btn {
    width: 100%;
    justify-content: center;
    padding: 12px 12px 10px;
    font-size: 14px;
  }
}
.toggle-btn {
  --icon-size: 12px;
  --icon-color: var(--pink);
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 6px;
  border: 1px solid var(--rose);
  cursor: pointer;
  transition: 0.3s;
  flex-shrink: 0;
}

.toggle-btn::before, .toggle-btn::after {
  content: "";
  display: block;
  width: var(--icon-size);
  height: calc(var(--icon-size) / 6);
  border-radius: 2px;
  background: var(--icon-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.toggle-btn::after {
  height: var(--icon-size);
  width: calc(var(--icon-size) / 6);
}

.toggle-btn:hover {
  background: var(--rose);
  --icon-color: var(--white) !important;
}

.toggle-btn.active {
  border-color: var(--black);
  --icon-color: var(--black);
}

.toggle-btn.active::after {
  opacity: 0;
}

.btn-show-hide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 8px;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-weight: 600;
  color: var(--midnight);
  cursor: pointer;
  transition: color 0.3s ease;
}

.btn-show-hide__icon {
  border: 1px solid var(--pink);
  width: clamp(32px, 32px + 6 * (100vw - 768px) / 672, 38px);
  height: clamp(32px, 32px + 6 * (100vw - 768px) / 672, 38px);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.btn-show-hide__icon i {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  color: var(--pink);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 0.3s ease;
}

.btn-show-hide__show {
  display: flex;
}

.btn-show-hide__hide {
  display: none;
}

.btn-show-hide:not(.is-visible) {
  opacity: 0;
  display: none;
}

.btn-show-hide:not(.active) .btn-show-hide__icon i.icon-plus-second {
  opacity: 1;
}

.btn-show-hide.active .btn-show-hide__show {
  display: none;
}

.btn-show-hide.active .btn-show-hide__hide {
  display: flex;
}

.btn-show-hide.active .btn-show-hide__icon i.icon-plus-second {
  opacity: 0;
}

.btn-show-hide:hover {
  color: var(--pink);
}

.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.filters-btn__icon {
  width: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  height: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.filters-btn__icon i {
  font-size: 16px;
  color: var(--pink);
  transition: all 0.3s ease;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.filters-btn__icon i.icon-close {
  opacity: 0;
  color: var(--purple);
}

.filters-btn.active .filters-btn__icon i.icon-close {
  opacity: 1;
}

.filters-btn.active .filters-btn__icon i.icon-filters {
  opacity: 0;
}

.filters-btn:not(.active):hover .filters-btn__icon i {
  color: var(--white);
}

.social-buttons {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: center;
}

.social-buttons__wrapper {
  display: flex;
  gap: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
  align-items: center;
  flex-wrap: wrap;
}

.social-btn {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-top: 1px solid var(--white);
  border-bottom: 1px solid var(--white);
  transition: 0.3s;
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
}

.social-btn .icon-mail-button::before {
  display: block;
  font-size: 85%;
  transform: translateY(-1%);
}

.social-btn .icon-linkedin-button::before {
  display: block;
  transform: translateY(-5%);
}

.social-btn:hover {
  color: var(--white);
  background: var(--pink);
}

.upload-btn-wr {
  width: 183px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  gap: 8px;
  justify-content: flex-end;
}

.upload-btn-wr input {
  display: none !important;
}

.upload-btn-wr .upload-btn {
  flex-shrink: 0;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  letter-spacing: 0.04em;
  display: flex;
  justify-content: center;
  gap: 11px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
}

.upload-btn-wr .cv_file_name {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: var(--light-line-i);
  line-height: 1.66;
  text-align: center;
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.upload-btn-wr:hover {
  color: var(--light-line-i);
}

.btn-close {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.btn-close__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--white);
  background: transparent;
  transition: all 0.3s ease;
  border-radius: 50%;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  width: 37px;
  height: 37px;
}

.btn-close__icon i {
  font-size: 16px;
  color: var(--white);
  transition: all 0.3s ease;
}

.btn-close__text {
  margin-top: 2px;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--white);
  transition: all 0.3s ease;
}

.btn-close:hover .btn-close__icon {
  background-color: var(--white);
  border-color: var(--light-line-i);
}

.btn-close:hover .btn-close__icon i {
  color: var(--purple);
}

.btn-close:hover .btn-close__text {
  color: var(--light-line-i);
}

.btn-close.btn-close-midnight .btn-close__icon {
  border-color: var(--midnight);
}

.btn-close.btn-close-midnight .btn-close__icon i {
  color: var(--midnight);
}

.btn-close.btn-close-midnight .btn-close__text {
  color: var(--midnight);
}

.btn-close.btn-close-midnight:hover .btn-close__icon {
  background-color: var(--white);
  border-color: var(--rose);
}

.btn-close.btn-close-midnight:hover .btn-close__icon i {
  color: var(--rose);
}

.btn-close.btn-close-midnight:hover .btn-close__text {
  color: var(--rose);
}

.social-popup-btn {
  --size: clamp(40px, calc(40px + 4.00 * ((100vw - 768px) / 672)), 44px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  border-top: 1px solid var(--pink);
  border-bottom: 1px solid var(--pink);
  transition: all 0.3s ease;
  cursor: pointer;
  background: transparent;
}

.social-popup-btn i {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  transition: all 0.3s ease;
  color: var(--pink);
}

.social-popup-btn i.icon-mail-button {
  font-size: clamp(16px, 16px + 1 * (100vw - 768px) / 672, 17px);
}

.social-popup-btn:hover {
  background-color: var(--pink);
}

.social-popup-btn:hover i {
  color: var(--white);
}

@media (max-width: 370px) {
  .btn {
    padding: 10px 5px;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 100;
  transition: 0.3s ease;
}

.header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  opacity: 1;
  transition: 0.3s ease;
  z-index: -1;
}

.header.sticky {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.header.sticky::before {
  opacity: 0.5;
}

.header.sticky .header__wrapper {
  padding: 10px 0;
}

.header.sticky .header__logo {
  width: 150px;
  height: 40px;
}

.header.sticky .header__btn-box .btn {
  font-size: 14px;
  padding: 12px;
}

.header.active .header__navbar-box {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.header.active .header__simplebar {
  transform: translateX(0);
  opacity: 1;
}

.header.active .header__layout {
  visibility: visible;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.3s linear;
}

.header.active .header__burger {
  border-color: var(--white);
  background-color: var(--rose);
  transition: all 0.3s ease;
}

.header.active .header__burger span {
  background-color: var(--white);
}

.header.active .header__burger span:nth-child(1) {
  transform: translate(0) rotate(45deg);
}

.header.active .header__burger span:nth-child(2) {
  width: 0;
}

.header.active .header__burger span:nth-child(3) {
  transform: translate(0) rotate(-45deg);
}

.header.active .header__burger:hover {
  background-color: var(--midnight);
}

.header__wrapper {
  display: flex;
  align-items: center;
  padding: clamp(22px, 22px + 10 * (100vw - 768px) / 672, 32px) 0;
  transition: 0.3s ease;
}

.header__logo {
  width: 179px;
  height: 56px;
  margin-right: auto;
  flex-shrink: 0;
  z-index: 3;
  transition: 0.3s ease;
}

.header__logo path {
  transition: 0.3s ease;
}

.header__logo:hover path {
  fill: var(--pink);
}

.header__navbar-box {
  position: absolute;
  right: -100%;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 680px;
  height: calc(var(--vh, 1vh) * 100 - 0px);
  padding-top: 37px;
  overflow: hidden;
  transition: 0.3s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 5;
}

.header__top, .header__simplebar, .header__bottom {
  padding-left: clamp(15px, 15px + 41 * (100vw - 320px) / 1120, 56px);
  padding-right: clamp(15px, 15px + 67 * (100vw - 320px) / 1120, 82px);
}

.header__simplebar {
  max-height: calc(100% - 250px);
  width: 100%;
  z-index: 5;
}

.header__simplebar[data-simplebar] .simplebar-track.simplebar-vertical {
  right: 0;
  top: -154px;
  width: 16px;
  height: 100vh;
  background-color: var(--purple);
  border: 4px solid var(--purple);
}

.header__simplebar[data-simplebar] .simplebar-scrollbar:before {
  background-color: var(--white) !important;
  opacity: 0.8 !important;
}

.header__navbar {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.header__top {
  padding-bottom: 43px;
}

.header__top .header__burger {
  transform: translateY(-5px);
}

.header__title {
  font-size: 44px;
  line-height: 56px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.header__link {
  margin-bottom: 21px;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: var(--white);
  transition: 0.3s ease;
}

.header__link:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -13px;
  width: 0%;
  height: 1px;
  background-color: var(--rose);
  transition: 0.3s linear;
}

.header__link:hover {
  color: var(--pink);
}

.header__btn-box .btn {
  box-shadow: none;
}

.header .js-dropdown {
  position: relative;
  margin-top: clamp(0px, 0px + 7 * (100vw - 768px) / 672, 7px);
  z-index: 2;
  transition: 0.3s linear;
}

.header .js-dropdown > .header__link {
  margin-bottom: 28px;
}

.header .js-dropdown > .header__link:before {
  width: 100%;
}

.header .js-dropdown.open > .header__link {
  margin-bottom: 28px;
}

.header .js-dropdown.open > .header__link span {
  transform: translateY(-5px) rotate(0deg);
}

.header .js-dropdown.open .header__dropdown {
  margin-bottom: 28px;
  max-height: 600px;
  opacity: 1;
  visibility: visible;
  overflow: hidden;
}

.header .js-dropdown > .header__link {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.header .js-dropdown > .header__link:hover span {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23c82a7a' d='m26.708 21.293-10-10a1.001 1.001 0 0 0-1.415 0l-10 10a1 1 0 1 0 1.415 1.415l9.293-9.294 9.292 9.294a1 1 0 0 0 1.415-1.415Z'/%3E%3C/svg%3E");
}

@media not all and (min-width: 551px) {
  .header .js-dropdown {
    width: 100%;
  }
  .header .js-dropdown .header__link--btn {
    width: 100%;
  }
  .header .js-dropdown .header__link--btn .header__link-chevrone {
    margin-left: auto;
  }
}
.header__link-chevrone {
  margin-left: 8px;
  height: 32px;
  width: 32px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(-180deg);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23F3F3F3' d='m26.708 21.293-10-10a1.001 1.001 0 0 0-1.415 0l-10 10a1 1 0 1 0 1.415 1.415l9.293-9.294 9.292 9.294a1 1 0 0 0 1.415-1.415Z'/%3E%3C/svg%3E");
  transition: 0.3s ease;
}

.header__dropdown {
  min-width: 178px;
  max-width: calc(100vw - 15px);
  width: auto;
  max-height: 0px;
  position: relative;
  transform: none;
  left: 0;
  bottom: 0px;
  padding-left: 16px;
  overflow: hidden;
  visibility: hidden;
  transition: 0.4s linear;
}

.header__dropdown > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header__dropdown .header__link {
  margin-bottom: 19px;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: clamp(19px, 19px + 1 * (100vw - 768px) / 672, 20px);
  letter-spacing: 0.04em;
}

.header .js-dropdown:first-child, .header__link:first-child {
  margin-top: 0;
}

.header .js-dropdown:last-child, .header__link:last-child {
  margin-bottom: 0;
}

.header__burger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  z-index: 2;
  border: 2px solid var(--rose);
  border-radius: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.header__burger span {
  position: absolute;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--purple);
  flex-shrink: 0;
  transition: 0.3s ease-in-out;
}

.header__burger span:nth-child(1) {
  transform: translateY(-7px);
}

.header__burger span:nth-child(3) {
  transform: translateY(7px);
}

.header__burger:hover {
  background-color: var(--rose);
}

.header__burger:hover span {
  background-color: var(--white);
}

.header__decor {
  z-index: -1;
}

.header__decor img {
  filter: grayscale(1) brightness(1.3);
}

.header__decor::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 88px);
  background-color: var(--midnight);
  opacity: 0.9;
  z-index: 1;
  filter: brightness(1.1);
}

.header__bottom {
  padding-right: clamp(15px, 15px + 41 * (100vw - 320px) / 1120, 56px);
  gap: 34px;
  height: 88px;
  width: 100%;
  background-color: rgba(200, 42, 122, 0.8);
}

.header__contact {
  color: var(--gray-bg);
  transition: 0.3s ease;
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.header__contact::before {
  content: "";
  margin-right: 7px;
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  transition: 0.3s ease;
}

.header__contact:hover {
  color: var(--midnight);
}

.header__contact--tel::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23fff' d='M27.992 22.76A6.041 6.041 0 0 1 22 28 17 17 0 0 1 5 11a6.041 6.041 0 0 1 5.24-5.993 1 1 0 0 1 1.037.6l2.642 5.9a1.001 1.001 0 0 1-.083.941l-2.671 3.177a.99.99 0 0 0-.067.976c1.033 2.116 3.22 4.277 5.343 5.301a.99.99 0 0 0 .979-.074l3.125-2.662a1 1 0 0 1 .949-.086l5.895 2.641a1 1 0 0 1 .604 1.039Z' opacity='.2'/%3E%3Cpath fill='%23fff' d='M19.034 5.741a.998.998 0 0 1 1.225-.707 10.777 10.777 0 0 1 7.707 7.707 1 1 0 1 1-1.932.516 8.794 8.794 0 0 0-6.291-6.291 1 1 0 0 1-.71-1.225Zm-.291 5.225c1.723.46 2.83 1.568 3.29 3.291a1 1 0 1 0 1.933-.516c-.64-2.395-2.312-4.067-4.707-4.707a1 1 0 0 0-.517 1.932Zm10.242 11.919A7.033 7.033 0 0 1 22 29c-9.925 0-18-8.075-18-18a7.032 7.032 0 0 1 6.115-6.985 2 2 0 0 1 2.078 1.19l2.64 5.893v.015a2 2 0 0 1-.16 1.887 1.37 1.37 0 0 1-.07.096L12 16.181c.936 1.902 2.926 3.875 4.854 4.814l3.042-2.589a1 1 0 0 1 .094-.07 2 2 0 0 1 1.896-.175l.017.008 5.888 2.638a2 2 0 0 1 1.194 2.078ZM27 22.635h-.014l-5.875-2.632-3.044 2.59a1.03 1.03 0 0 1-.092.07 2 2 0 0 1-1.969.142c-2.341-1.131-4.675-3.448-5.807-5.764a2 2 0 0 1 .125-1.963.77.77 0 0 1 .071-.096L13 11.894l-2.625-5.875v-.016A5.025 5.025 0 0 0 6 11a16.017 16.017 0 0 0 16 16 5.026 5.026 0 0 0 5-4.366Z'/%3E%3C/svg%3E");
}

.header__contact--tel:hover::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23560b75' d='M27.992 22.76A6.041 6.041 0 0 1 22 28 17 17 0 0 1 5 11a6.041 6.041 0 0 1 5.24-5.993 1 1 0 0 1 1.037.6l2.642 5.9a1.001 1.001 0 0 1-.083.941l-2.671 3.177a.99.99 0 0 0-.067.976c1.033 2.116 3.22 4.277 5.343 5.301a.99.99 0 0 0 .979-.074l3.125-2.662a1 1 0 0 1 .949-.086l5.895 2.641a1 1 0 0 1 .604 1.039Z' opacity='.2'/%3E%3Cpath fill='%23560b75' d='M19.034 5.741a.998.998 0 0 1 1.225-.707 10.777 10.777 0 0 1 7.707 7.707 1 1 0 1 1-1.932.516 8.794 8.794 0 0 0-6.291-6.291 1 1 0 0 1-.71-1.225Zm-.291 5.225c1.723.46 2.83 1.568 3.29 3.291a1 1 0 1 0 1.933-.516c-.64-2.395-2.312-4.067-4.707-4.707a1 1 0 0 0-.517 1.932Zm10.242 11.919A7.033 7.033 0 0 1 22 29c-9.925 0-18-8.075-18-18a7.032 7.032 0 0 1 6.115-6.985 2 2 0 0 1 2.078 1.19l2.64 5.893v.015a2 2 0 0 1-.16 1.887 1.37 1.37 0 0 1-.07.096L12 16.181c.936 1.902 2.926 3.875 4.854 4.814l3.042-2.589a1 1 0 0 1 .094-.07 2 2 0 0 1 1.896-.175l.017.008 5.888 2.638a2 2 0 0 1 1.194 2.078ZM27 22.635h-.014l-5.875-2.632-3.044 2.59a1.03 1.03 0 0 1-.092.07 2 2 0 0 1-1.969.142c-2.341-1.131-4.675-3.448-5.807-5.764a2 2 0 0 1 .125-1.963.77.77 0 0 1 .071-.096L13 11.894l-2.625-5.875v-.016A5.025 5.025 0 0 0 6 11a16.017 16.017 0 0 0 16 16 5.026 5.026 0 0 0 5-4.366Z'/%3E%3C/svg%3E");
}

.header__contact--mail::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23fff' d='m28 7.667-12 11-12-11h24Z' opacity='.2'/%3E%3Cpath fill='%23fff' d='M28 6.667H4a1 1 0 0 0-1 1v17a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2v-17a1 1 0 0 0-1-1ZM16 17.31 6.571 8.667H25.43L16 17.31Zm-3.661-.644L5 23.393V9.94l7.339 6.727Zm1.48 1.356 1.5 1.381a1 1 0 0 0 1.352 0l1.5-1.381 7.25 6.644H6.571l7.248-6.644Zm5.842-1.356L27 9.939v13.455l-7.339-6.727Z'/%3E%3C/svg%3E");
}

.header__contact--mail:hover::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23560b75' d='m28 7.667-12 11-12-11h24Z' opacity='.2'/%3E%3Cpath fill='%23560b75' d='M28 6.667H4a1 1 0 0 0-1 1v17a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2v-17a1 1 0 0 0-1-1ZM16 17.31 6.571 8.667H25.43L16 17.31Zm-3.661-.644L5 23.393V9.94l7.339 6.727Zm1.48 1.356 1.5 1.381a1 1 0 0 0 1.352 0l1.5-1.381 7.25 6.644H6.571l7.248-6.644Zm5.842-1.356L27 9.939v13.455l-7.339-6.727Z'/%3E%3C/svg%3E");
}

@media not all and (min-width: 769px) {
  .header__wrapper {
    padding: 15px 0;
  }
  .header__logo {
    width: 150px;
    height: 48px;
  }
  .header__navbar-box {
    max-width: unset;
    padding-top: 15px;
  }
  .header__top {
    padding-bottom: 30px;
  }
  .header__simplebar {
    max-height: calc(100% - 250px);
  }
  .header__simplebar[data-simplebar] .simplebar-track.simplebar-vertical {
    top: -154px;
    width: 8px;
    border: 0;
  }
  .header__link {
    font-size: clamp(18px, 18px + 4 * (100vw - 768px) / 672, 22px);
    line-height: clamp(21px, 21px + 5 * (100vw - 768px) / 672, 26px);
  }
  .header__link::before {
    bottom: -6px;
  }
  .header__bottom {
    height: 66px;
  }
  .header__contact {
    font-size: 18px;
  }
  .header__decor:before {
    height: calc(100% - 66px);
  }
  .header__simplebar {
    max-height: calc(100% - 180px);
  }
  .header__simplebar[data-simplebar] .simplebar-track.simplebar-vertical {
    top: -112px;
  }
}
@media not all and (min-width: 640px) {
  .header__bottom {
    justify-content: center;
  }
  .header__contact {
    font-size: 0;
    line-height: 0;
    color: transparent;
    padding: 5px;
    border-top: 1px solid var(--white);
    border-bottom: 1px solid var(--white);
  }
  .header__contact::before {
    transform: unset;
    margin-right: 0;
  }
  .header__contact:hover {
    background-color: var(--white);
  }
}
@media not all and (min-width: 551px) {
  .header .header__logo {
    width: 128px;
  }
}
.footer {
  padding: clamp(30px, 30px + 30 * (100vw - 768px) / 672, 60px) 0 clamp(40px, 40px + 20 * (100vw - 768px) / 672, 60px);
  background-color: var(--midnight);
}

.footer__descr {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04em;
}

.footer__logo path {
  transition: 0.3s ease;
}

.footer__logo:hover path {
  fill: var(--pink);
}

.footer__head {
  padding-bottom: 13px;
  margin-bottom: 22px;
  letter-spacing: 0;
  font-weight: 600;
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: clamp(26px, 28px + -2 * (100vw - 768px) / 672, 28px);
  color: var(--gray-bg);
  border-bottom: 1px solid var(--rose);
}

.footer__nav-box, .footer__col {
  margin-top: clamp(26px, 28px + -2 * (100vw - 768px) / 672, 28px);
}

.footer__col .footer__head {
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  line-height: clamp(26px, 28px + -2 * (100vw - 768px) / 672, 28px);
}

.footer__contact-box {
  display: flex;
  flex-direction: column;
  gap: clamp(15px, 15px + 9 * (100vw - 1024px) / 416, 24px) clamp(15px, 15px + 17 * (100vw - 1024px) / 416, 32px);
  grid-template-columns: 1fr;
  align-items: flex-start;
}

.footer__nav {
  gap: 16px clamp(15px, 15px + 17 * (100vw - 1024px) / 416, 32px);
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  justify-items: flex-start;
}

.footer__link {
  font-weight: 500;
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  line-height: clamp(19px, 19px + 5 * (100vw - 768px) / 672, 24px);
  letter-spacing: 0.02em;
  color: var(--gray-bg);
  transition: 0.3s ease;
}

.footer__link:hover {
  color: var(--rose);
}

.footer__wrapper {
  gap: clamp(35px, 35px + 65 * (100vw - 1200px) / 240, 100px);
}

.footer__contact {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--gray-bg);
  letter-spacing: 0;
  transition: 0.3s ease;
}

.footer__contact:hover {
  color: var(--rose);
}

.footer__contact::before {
  content: "";
  margin-right: 7px;
  transform: translateY(-1px);
  width: 24px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

.footer__contact--tel::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none'%3E%3Cpath fill='%23DB509B' d='M20.994 16.521a4.531 4.531 0 0 1-4.494 3.93A12.75 12.75 0 0 1 3.75 7.701a4.53 4.53 0 0 1 3.93-4.494.75.75 0 0 1 .778.45l1.981 4.425a.75.75 0 0 1-.062.706L8.374 11.17a.743.743 0 0 0-.05.732c.774 1.587 2.415 3.208 4.007 3.976a.743.743 0 0 0 .734-.055l2.344-1.997a.75.75 0 0 1 .711-.065l4.422 1.981a.75.75 0 0 1 .452.779Z' opacity='.2'/%3E%3Cpath fill='%23DB509B' d='M14.275 3.757a.75.75 0 0 1 .92-.53 8.083 8.083 0 0 1 5.78 5.78.751.751 0 0 1-1.182.789.75.75 0 0 1-.268-.402 6.595 6.595 0 0 0-4.718-4.718.75.75 0 0 1-.532-.92Zm-.218 3.919c1.293.345 2.123 1.175 2.468 2.468a.75.75 0 1 0 1.45-.387c-.48-1.796-1.735-3.05-3.53-3.53a.75.75 0 0 0-.388 1.449Zm7.682 8.939A5.275 5.275 0 0 1 16.5 21.2c-7.444 0-13.5-6.056-13.5-13.5a5.274 5.274 0 0 1 4.586-5.239 1.5 1.5 0 0 1 1.558.893l1.98 4.42v.011a1.5 1.5 0 0 1-.119 1.415 1.05 1.05 0 0 1-.053.072L9 11.587c.702 1.427 2.195 2.906 3.64 3.61l2.282-1.941a1.5 1.5 0 0 1 1.492-.184l.013.006 4.416 1.979a1.5 1.5 0 0 1 .896 1.558Zm-1.489-.188h-.01l-4.407-1.973-2.282 1.941a.824.824 0 0 1-.07.053 1.5 1.5 0 0 1-1.476.107c-1.756-.849-3.507-2.586-4.356-4.323a1.5 1.5 0 0 1 .094-1.472.573.573 0 0 1 .053-.072L9.75 8.37 7.781 3.965v-.011A3.769 3.769 0 0 0 4.5 7.7a12.013 12.013 0 0 0 12 12 3.77 3.77 0 0 0 3.75-3.275v.001Z'/%3E%3C/svg%3E");
}

.footer__contact--mail::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none'%3E%3Cpath fill='%23DB509B' d='m21 5.45-9 8.25-9-8.25h18Z' opacity='.2'/%3E%3Cpath fill='%23DB509B' d='M21 4.7H3a.75.75 0 0 0-.75.75V18.2a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V5.45A.75.75 0 0 0 21 4.7Zm-9 7.984L4.928 6.2h14.144L12 12.684ZM9.254 12.2 3.75 17.246V7.156l5.504 5.045Zm1.11 1.017 1.125 1.036a.75.75 0 0 0 1.014 0l1.125-1.036 5.438 4.983H4.928l5.436-4.983Zm4.382-1.017 5.504-5.046v10.092L14.746 12.2Z'/%3E%3C/svg%3E");
}

.footer__contact--loc::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none'%3E%3Cpath fill='%23DB509B' d='M12 2.45a7.5 7.5 0 0 0-7.5 7.5c0 6.75 7.5 12 7.5 12s7.5-5.25 7.5-12a7.5 7.5 0 0 0-7.5-7.5Zm0 10.5a3 3 0 1 1 0-5.998 3 3 0 0 1 0 5.999Z' opacity='.2'/%3E%3Cpath fill='%23DB509B' d='M12 6.2a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm0 6a2.25 2.25 0 1 1 0-4.498 2.25 2.25 0 0 1 0 4.499Zm0-10.5a8.26 8.26 0 0 0-8.25 8.25c0 2.945 1.36 6.065 3.938 9.024a23.832 23.832 0 0 0 3.885 3.591.75.75 0 0 0 .861 0 23.838 23.838 0 0 0 3.879-3.59c2.573-2.96 3.937-6.08 3.937-9.024A8.26 8.26 0 0 0 12 1.7Zm0 19.313c-1.55-1.218-6.75-5.695-6.75-11.062a6.75 6.75 0 0 1 13.5 0c0 5.365-5.2 9.844-6.75 11.062Z'/%3E%3C/svg%3E");
}

.footer__btn {
  color: var(--purple);
  border-color: var(--white);
}

.footer__bottom {
  padding-top: 28px;
  border-top: 1px solid var(--rose);
  width: calc(100% + clamp(0px, 0px + 46 * (100vw - 1280px) / 160, 46px));
}

.footer__copy {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  color: #d3ebf2;
}

.footer__link-box a {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  color: #d3ebf2;
  text-decoration: underline;
  transition: 0.3s ease;
}

.footer__link-box a:hover {
  color: var(--rose);
}

.footer__soc {
  display: grid;
  place-items: center;
  width: 44px;
  height: 44px;
  border-top: 1px solid var(--rose);
  border-bottom: 1px solid var(--rose);
  transition: 0.3s ease;
}

.footer__soc:before {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.footer__soc:hover {
  background-color: var(--rose);
}

.footer__soc--in:before {
  width: 23px;
  height: 23px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' fill='none'%3E%3Cpath fill='%23fff' d='M4.857 2.793c0 1.321-1.05 2.391-2.346 2.391-1.297 0-2.347-1.07-2.347-2.39S1.214.401 2.511.401c1.296 0 2.346 1.071 2.346 2.391Zm.02 4.305H.144v15.304h4.731V7.098Zm7.552 0h-4.7v15.304h4.701v-8.034c0-4.467 5.705-4.832 5.705 0v8.034h4.72v-9.69c0-7.538-8.443-7.264-10.426-3.553V7.098Z'/%3E%3C/svg%3E");
}

.footer__soc--f:before {
  width: 13px;
  height: 23px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23' fill='none'%3E%3Cpath fill='%23fff' d='M8.2 22.402v-10.05h3.367l.503-3.906H8.2V5.953c0-1.13.315-1.903 1.937-1.903h2.069V.556A27.685 27.685 0 0 0 9.19.402c-2.982 0-5.024 1.82-5.024 5.165v2.879H.794v3.907h3.373v10.049h4.034Z'/%3E%3C/svg%3E");
}

.footer__soc--inst:before {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M12 15.735a3.833 3.833 0 1 1 0-7.666 3.833 3.833 0 0 1 0 7.666Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M12 .402c-3.123 0-3.514.013-4.74.069C3.082.663.761 2.98.57 7.16.513 8.388.5 8.779.5 11.902s.013 3.515.069 4.742c.192 4.176 2.509 6.497 6.69 6.689 1.227.055 1.618.069 4.741.069 3.123 0 3.515-.014 4.742-.07 4.172-.19 6.5-2.508 6.688-6.688.057-1.227.07-1.619.07-4.742s-.013-3.514-.069-4.741c-.188-4.173-2.508-6.498-6.688-6.69-1.228-.056-1.62-.07-4.743-.07Zm0 5.595a5.905 5.905 0 1 0 0 11.81 5.905 5.905 0 0 0 0-11.81Zm-3.833 5.905a3.833 3.833 0 1 0 7.665 0 3.833 3.833 0 0 0-7.665 0Zm8.591-6.138a1.38 1.38 0 1 1 2.761.001 1.38 1.38 0 0 1-2.76-.001Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.footer__soc--x:before {
  width: 24px;
  height: 23px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23' fill='none'%3E%3Cpath fill='%23fff' d='M9.377 12.536.292.402h7.002l5.956 7.955L20.62.402h2.058l-8.51 9.183 9.596 12.817h-7.002l-6.467-8.638-8.004 8.638H.234l9.143-9.866Z'/%3E%3C/svg%3E");
}

.footer__soc--threds:before {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M18.783.402H5.217A4.73 4.73 0 0 0 .5 5.119v13.566a4.73 4.73 0 0 0 4.717 4.717h13.566a4.73 4.73 0 0 0 4.717-4.717V5.12A4.73 4.73 0 0 0 18.783.402Zm-3.329 10.851c.068.029.135.06.2.09.931.447 1.611 1.123 1.968 1.954.497 1.16.543 3.049-.966 4.556-1.153 1.152-2.553 1.672-4.538 1.686h-.01c-2.233-.015-3.95-.767-5.104-2.234C5.977 16 5.447 14.184 5.43 11.907v-.01c.017-2.277.547-4.093 1.574-5.398 1.153-1.467 2.871-2.219 5.105-2.234h.009c2.238.015 3.974.764 5.162 2.225.586.721 1.017 1.59 1.29 2.599l-1.284.343c-.226-.82-.568-1.523-1.023-2.082-.926-1.14-2.322-1.724-4.15-1.738-1.815.014-3.187.596-4.08 1.73-.835 1.062-1.266 2.596-1.283 4.56.017 1.963.448 3.497 1.284 4.56.892 1.134 2.264 1.716 4.08 1.73 1.635-.013 2.717-.402 3.618-1.302 1.027-1.027 1.008-2.286.68-3.053-.194-.45-.544-.826-1.016-1.11-.115.857-.376 1.537-.787 2.062-.543.692-1.32 1.07-2.312 1.124-.75.041-1.472-.14-2.033-.51a2.368 2.368 0 0 1-1.092-1.887c-.082-1.536 1.136-2.64 3.031-2.75a8.392 8.392 0 0 1 1.884.09c-.077-.472-.234-.846-.467-1.117-.32-.372-.815-.563-1.472-.567h-.018c-.527 0-1.242.147-1.698.84l-1.096-.753c.641-.973 1.67-1.442 2.82-1.435 1.994.013 3.18 1.257 3.298 3.43Zm-4.963 2.19c.042.798.905 1.171 1.735 1.125.814-.045 1.737-.364 1.894-2.33a6.471 6.471 0 0 0-1.382-.14c-.15 0-.304.005-.46.014-1.366.078-1.819.739-1.787 1.331Z' clip-rule='evenodd'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M.5.402h23v23H.5z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

@media (min-width: 1025px) {
  .footer__logo-box {
    flex-shrink: 0;
    max-width: clamp(245px, 245px + 59 * (100vw - 1024px) / 416, 304px);
  }
  .footer__nav-box {
    flex-shrink: 0;
    max-width: clamp(440px, 440px + 82 * (100vw - 1024px) / 416, 522px);
  }
  .footer__link {
    max-width: clamp(155px, 155px + 17 * (100vw - 1024px) / 416, 172px);
  }
}
@media not all and (min-width: 1025px) {
  .footer__col {
    width: 100%;
  }
  .footer__contact-box {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer__wrapper {
    flex-wrap: wrap;
  }
  .footer__logo-box {
    max-width: 250px;
    margin-right: clamp(20px, 20px + 20 * (100vw - 768px) / 256, 40px);
  }
  .footer__nav-box {
    flex: 1;
  }
  .footer__nav {
    width: 100%;
    grid-template-columns: repeat(3, auto);
  }
  .footer__nav .footer__link {
    max-width: clamp(160px, 160px + 30 * (100vw - 768px) / 256, 190px);
  }
}
@media (max-width: 850px) {
  .footer__nav {
    width: 100%;
    grid-template-columns: repeat(2, auto);
  }
}
@media not all and (min-width: 769px) {
  .footer__contact-box {
    flex-wrap: wrap;
  }
  .footer__nav-box {
    width: 100%;
    max-width: unset;
  }
  .footer__nav {
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 16px;
  }
  .footer__soc-box {
    width: 100%;
    justify-content: center;
  }
  .footer__copy {
    margin-right: auto;
  }
  .footer__logo-box {
    margin: 0 auto;
    align-items: center;
  }
  .footer__descr {
    text-align: center;
  }
}
@media not all and (min-width: 640px) {
  .footer .footer__logo-box {
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
  .footer .footer__logo {
    width: 168px;
  }
  .footer .footer__descr {
    margin-left: 0;
    width: 100%;
    max-width: 304px;
    text-align: left;
  }
  .footer .footer__btn {
    width: 100%;
    max-width: 304px;
  }
  .footer .footer__bottom {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .footer .footer__soc-box {
    order: 1;
    justify-content: flex-start;
  }
  .footer .footer__copy {
    order: 3;
  }
  .footer .footer__link-box {
    order: 2;
  }
  .footer .footer__dev {
    order: 4;
    justify-content: flex-start;
  }
  .footer .footer__contact--loc {
    max-width: 210px;
  }
}
@media (max-width: 370px) {
  .footer__nav {
    grid-template-columns: 1fr;
  }
  .footer__nav .footer__link {
    max-width: 100%;
  }
}
:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;
  --pp-black: #000;
  --pp-black-rgb: 0 0 0;
  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;
  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;
  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;
  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;
  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;
  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;
}

.popup.active {
  display: block;
}

.popup__close-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
  transition: all 0.5s ease;
  animation-duration: 0.3s;
  animation-name: opacityPopupBg;
}

.popup__block {
  --pp-default-translate-x-from: -50%;
  --pp-default-translate-x-to: -50%;
  --pp-default-translate-y-from: -50%;
  --pp-default-translate-y-to: -50%;
  --pp-opacity-from: 0;
  --pp-opacity-to: 1;
  background: var(--pp-white);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: clamp(600px, 600px + 250 * (100vw - 768px) / 672, 850px);
  padding: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px) clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  transform: translateY(var(--pp-default-translate-y-to)) translateX(var(--pp-default-translate-x-to));
  transition: all 300ms ease-out;
  animation-duration: 0.6s;
}

.popup__block.without-opacity {
  --pp-opacity-from: 1;
}

.popup__block.pos-l, .popup__block.pos-r {
  --pp-default-translate-x-from: 0;
  --pp-default-translate-x-to: 0;
}

.popup__block.pos-t, .popup__block.pos-b {
  --pp-default-translate-y-from: 0;
  --pp-default-translate-y-to: 0;
}

.popup__block.pos-t {
  top: 0;
  bottom: auto;
  bottom: initial;
}

.popup__block.pos-r {
  right: 0;
  left: auto;
  left: initial;
}

.popup__block.pos-b {
  top: auto;
  top: initial;
  bottom: 0;
}

.popup__block.pos-l {
  right: auto;
  right: initial;
  left: 0;
}

.popup__block.full-height {
  max-height: 100vh;
  height: 100%;
  right: 0;
  left: auto;
  left: initial;
  --pp-default-translate-x-from: 0;
  --pp-default-translate-x-to: 0;
}

.popup__block.full-height .popup__content {
  max-height: calc(var(--vh, 1vh) * 72);
}

.popup__block.slide-l {
  --pp-default-translate-x-from: 100%;
}

.popup__block.slide-r {
  --pp-default-translate-x-from: -100%;
}

.popup__block.slide-t {
  --pp-default-translate-y-from: -100%;
}

.popup__block.slide-b {
  --pp-default-translate-y-from: 100%;
}

.popup__block.slide-t, .popup__block.slide-r, .popup__block.slide-b, .popup__block.slide-l {
  animation-name: popupSlide;
}

.popup__title {
  color: var(--pp-dark-blue);
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  line-height: 1.2;
  font-family: var(--pp-font-main);
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.popup__btn-close {
  position: absolute;
  right: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  top: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  transition: all 300ms ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup__btn-close .close-clip {
  transition: inherit;
  width: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  height: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  background: var(--pp-dark-blue);
  -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.popup__btn-close i {
  transition: inherit;
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  color: var(--pp-dark-blue);
}

.popup__btn-close:hover .close-clip {
  background: var(--pp-blue);
  transform: scale(1.05);
}

.popup__btn-close:hover i {
  color: var(--pp-blue);
  transform: scale(1.05);
}

.popup__content {
  max-height: calc(var(--vh, 1vh) * 66);
}

.popup__content .simplebar-track.simplebar-vertical {
  width: 4px;
  background: var(--pp-light-gray);
  border-radius: 4px;
  transform: translateX(10px);
}

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--pp-dark-blue);
  border-radius: 4px;
}

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.popup__content .popup-form__container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

@media not all and (min-width: 640px) {
  .popup__content .popup-form__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.popup__content .popup-form__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  padding-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  flex-wrap: wrap;
}

.popup__content .popup-form__bottom .popup-btn {
  margin-left: auto;
}

.popup__content .popup-btn {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  font-weight: 700;
  font-family: var(--pp-font-main);
  line-height: 1.2;
  text-transform: uppercase;
  background-color: var(--pp-dark-blue);
  color: var(--pp-white);
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 12px 18px;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  position: relative;
  overflow: hidden;
}

.popup__content .popup-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: skew(45deg);
  pointer-events: none;
}

.popup__content .popup-btn:hover::after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.7) 100%);
  animation: gradientPopupBtn 0.3s linear forwards;
}

.popup__content .popup-btn:active {
  transform: scale(0.9);
  opacity: 0.9;
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from)) translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }
  to {
    transform: translateY(var(--pp-default-translate-y-to)) translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}
@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }
  100% {
    transform: translateX(100%) skew(45deg);
  }
}
input, textarea {
  padding: 12px 19px;
  width: 100%;
  border: 1px solid var(--electric-red);
  background-color: var(--white);
  border-radius: 10px;
  color: var(--midnight);
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--midnight) !important;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}

input, input::placeholder, textarea, textarea::placeholder {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  transition: 0.3s ease;
}

textarea {
  height: 92px;
  resize: none;
  scrollbar-width: thin;
  scrollbar-color: var(--midnight) rgba(30, 30, 30, 0.2);
  overflow-x: hidden;
  overflow-y: auto;
}

textarea::-webkit-scrollbar {
  width: 6px;
}

textarea::-webkit-scrollbar-track {
  background: rgba(30, 30, 30, 0.2);
  border-radius: 0;
}

textarea::-webkit-scrollbar-thumb {
  background: var(--midnight);
  border-radius: 0;
}

.input-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-box p span {
  color: v;
}

.input-box__upload-btn {
  flex-shrink: 0;
  margin-right: 12px;
  padding: 12px 16px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  font-family: var(--font-sec);
  border: 1px solid var(--denim);
  background-color: var(--white);
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s ease;
}

.input-box__upload-btn:hover {
  background-color: var(--denim);
  color: var(--white);
}

.input-box__upload-label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  transition: 0.3s ease;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.meta-input label, .meta-upload-file label {
  font-family: var(--font-main);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: normal;
  font-weight: 400;
  /*color: var(--cl-white);*/
  padding-bottom: clamp(11px, 11px + 4 * (100vw - 768px) / 672, 15px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.meta-input label span, .meta-upload-file label span {
  margin-left: 4px;
  display: inline-block;
  color: var(--purple);
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.meta-input input, .meta-input textarea {
  width: 100%;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  font-family: var(--font-main);
  color: var(--dark-gray);
  border: 1px solid var(--dark-gray);
  border-radius: 74px;
  outline: none;
  transition: box-shadow 0.25s ease, border-color 0.3s ease, caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  padding: 11px 23px;
  height: 48px;
  background: transparent;
}

.meta-input input:-webkit-autofill, .meta-input input:-webkit-autofill:hover, .meta-input input:-webkit-autofill:focus, .meta-input input:-webkit-autofill:active, .meta-input textarea:-webkit-autofill, .meta-input textarea:-webkit-autofill:hover, .meta-input textarea:-webkit-autofill:focus, .meta-input textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--dark-gray) !important;
}

.meta-input input::-moz-placeholder, .meta-input textarea::-moz-placeholder {
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: var(--mid-gray);
  opacity: 1;
}

.meta-input input::placeholder, .meta-input textarea::placeholder {
  transition: all 0.3s ease;
  color: var(--mid-gray);
  opacity: 1;
}

.meta-input input:focus, .meta-input textarea:focus {
  border-color: var(--purple);
  caret-color: var(--purple);
}

.meta-input input.error, .meta-input textarea.error {
  border-color: red;
}

.meta-input textarea {
  padding-top: 11px;
  min-height: clamp(96px, 96px + 4 * (100vw - 768px) / 672, 100px);
  max-height: clamp(96px, 96px + 4 * (100vw - 768px) / 672, 100px);
  overflow-y: auto;
  resize: none;
  -webkit-clip-path: inset(-2px -2px -2px -2px round 74px);
  clip-path: inset(-2px -2px -2px -2px round 74px);
  scrollbar-width: thin;
  scrollbar-color: var(--pink) transparent;
}

.meta-input textarea::-webkit-scrollbar {
  width: 3px;
}

.meta-input textarea::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 2px;
}

.meta-input textarea::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: 2px;
}

.meta-input__help, .meta-input__error {
  margin-top: 5px;
  font-family: var(--font-main);
  font-size: clamp(12px, 12px + 2 * (100vw - 768px) / 672, 14px);
  line-height: 1.2;
  font-weight: 600;
}

.meta-input__error {
  color: red;
}

.is-safari .meta-input textarea::-webkit-scrollbar {
  width: auto;
  width: initial;
}

.is-safari .meta-input textarea::-webkit-scrollbar-track {
  background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
  background: initial;
  border-radius: 0;
  border-radius: initial;
}

.is-safari .meta-input textarea::-webkit-scrollbar-thumb {
  background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
  background: initial;
  border-radius: 0;
  border-radius: initial;
}

.dark-bg .meta-input input, .dark-bg .meta-input textarea {
  border-color: var(--white);
  color: var(--white);
}

.dark-bg .meta-input input:-webkit-autofill, .dark-bg .meta-input input:-webkit-autofill:hover, .dark-bg .meta-input input:-webkit-autofill:focus, .dark-bg .meta-input input:-webkit-autofill:active, .dark-bg .meta-input textarea:-webkit-autofill, .dark-bg .meta-input textarea:-webkit-autofill:hover, .dark-bg .meta-input textarea:-webkit-autofill:focus, .dark-bg .meta-input textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--white) !important;
}

.dark-bg .meta-input input:focus, .dark-bg .meta-input textarea:focus {
  border-color: var(--light-line-i);
  caret-color: var(--light-line-i);
}

.dark-bg .meta-input input::-moz-placeholder, .dark-bg .meta-input textarea::-moz-placeholder {
  color: var(--white);
}

.dark-bg .meta-input input::placeholder, .dark-bg .meta-input textarea::placeholder {
  color: var(--white);
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.meta-checkbox-list__item:not(:last-child) {
  margin-bottom: 8px;
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  min-height: clamp(30px, 30px + 6 * (100vw - 768px) / 672, 36px);
}

.meta-checkbox__box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(20px, 20px + 5 * (100vw - 768px) / 672, 25px);
  min-width: clamp(20px, 20px + 5 * (100vw - 768px) / 672, 25px);
  height: clamp(20px, 20px + 5 * (100vw - 768px) / 672, 25px);
  border: 1px solid var(--pink);
  margin-right: clamp(8px, 8px + 1 * (100vw - 768px) / 672, 9px);
  margin-left: 1px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background: transparent;
}

.meta-checkbox__box:hover {
  border-color: var(--purple);
}

.meta-checkbox__box .check-clip, .meta-checkbox__box .circle-clip {
  width: 85%;
  height: 85%;
  background-color: var(--black);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.meta-checkbox__box .check-clip {
  -webkit-clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
  clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
}

.meta-checkbox__box .circle-clip {
  -webkit-clip-path: circle(50%);
  clip-path: circle(50%);
}

.meta-checkbox__box i {
  opacity: 0;
  transition: opacity 0.3s ease;
  color: var(--black);
  font-size: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.meta-checkbox input {
  display: none;
}

.meta-checkbox input[type=checkbox] + .meta-checkbox__box {
  border-radius: 4px;
}

.meta-checkbox input[type=radio] + .meta-checkbox__box {
  border-radius: 50%;
}

.meta-checkbox__text {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: clamp(24px, 24px + 3 * (100vw - 768px) / 672, 27px);
  font-family: var(--font-second);
  font-weight: 400;
}

.meta-checkbox__text a {
  font: inherit;
  color: var(--pink);
  transition: all 0.25s ease;
}

.meta-checkbox__text a:hover {
  color: var(--purple);
}

.meta-checkbox input:checked ~ .meta-checkbox__box {
  /*+ .meta-checkbox__text {
    .meta-checkbox__text-count {
      color: var(--cl-yellow);
    }
  }*/
}

.meta-checkbox input:checked ~ .meta-checkbox__box .check-clip, .meta-checkbox input:checked ~ .meta-checkbox__box .circle-clip, .meta-checkbox input:checked ~ .meta-checkbox__box i {
  opacity: 1;
}

.meta-checkbox input:checked ~ .meta-checkbox__box:hover {
  border-color: var(--black);
}

.meta-checkbox.meta-checkbox-light .meta-checkbox__box {
  border-color: var(--light-line-i);
  background: var(--lavender);
}

.meta-checkbox.meta-checkbox-light .meta-checkbox__box i {
  color: var(--white);
}

.meta-checkbox.meta-checkbox-light .meta-checkbox__text {
  color: var(--light-line-i);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2142;
  letter-spacing: 0.02em;
}

.meta-checkbox.meta-checkbox-light .meta-checkbox__text a {
  display: inline;
  color: var(--white);
  text-decoration: underline;
}

.meta-checkbox.meta-checkbox-light .meta-checkbox__text a:hover {
  text-decoration: none;
}

.meta-checkbox.meta-checkbox-black .meta-checkbox__box {
  background: var(--black-regular);
  border-color: var(--black-regular);
}

.default-custom-select {
  display: inline-flex;
  align-items: center;
  height: 48px;
  border: 1px solid var(--white);
  border-radius: 74px;
  background: transparent;
  padding: 11px 35px 11px 23px;
  transition: all 0.25s ease;
  position: relative;
  cursor: pointer;
  z-index: 20;
}

.default-custom-select__placeholder {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  font-family: var(--font-main);
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.default-custom-select__list {
  position: absolute;
  bottom: -8px;
  left: -1px;
  right: -1px;
  transform: translateY(100%);
  border: 2px solid var(--purple);
  background: var(--gray-bg);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: 100;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: default;
  padding: 5px;
  border-radius: 8px;
}

.default-custom-select__list .meta-checkbox:not(:last-child) {
  margin-bottom: 8px;
}

.default-custom-select.active .default-custom-select__list {
  display: flex;
}

.default-custom-select:before {
  border: none;
  border-bottom: 1px solid var(--white);
  border-right: 1px solid var(--white);
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 26px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg) scale(2);
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.default-custom-select.active:before {
  transform: rotate(-135deg) scale(2);
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /*&__list {
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 8px;

    &.is-visible {
      margin-top: 10px;
      display: flex;
    }
  }

  &__list-item {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    padding-right: 14px;
    cursor: default;
    color: var(--black);

    &:after {
      content: '';
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      transition: background-color 0.25s ease, opacity 0.25s ease, transform 0.25s ease;
      opacity: 0.5;
      background: var(--pink);
      clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translateY(-50%) scale(1.15);
      }
    }
  }
  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--black);
  }

  &__error {
    color: red;
  }*/
}

.meta-select label {
  font-family: var(--font-main);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: normal;
  font-weight: 400;
  /*color: var(--cl-white);*/
  padding-bottom: clamp(11px, 11px + 4 * (100vw - 768px) / 672, 15px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.meta-select label span {
  margin-left: 4px;
  display: inline-block;
  color: var(--purple);
}

.meta-select select {
  opacity: 0;
  height: 48px;
}

.meta-select.sort-type {
  position: relative;
}

.meta-select.sort-type label {
  position: absolute;
  padding-bottom: 0;
  top: 16px;
  left: 24px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  font-family: var(--font-main);
  color: var(--dark-gray);
  z-index: 2;
}

.meta-select.sort-type .choices__inner {
  padding-left: 92px !important;
  padding-top: 15px !important;
  background: var(--white);
}

.meta-select.sort-type .choices__list.choices__list--single .choices__item.choices__item--selectable {
  color: var(--midnight) !important;
}

.meta-select .choices {
  width: 100%;
  margin-bottom: 0;
  overflow: visible;
}

.meta-select .choices:not(.is-open) {
  overflow: hidden;
}

.meta-select .choices__inner {
  border: 1px solid var(--light-line-i);
  border-radius: 74px !important;
  outline: none;
  transition: box-shadow 0.3s ease, border-color 0.3s ease, border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
  padding: 11px 23px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  font-family: var(--font-main);
  color: var(--dark-gray);
  height: 48px;
  background: var(--white);
}

/*&:hover {
        border-color: var(--cl-orange);
      }*/
.meta-select .choices__inner.error {
  border-color: var(--purple);
}

.meta-select .choices.is-open:not(.is-flipped) .choices__list--dropdown, .meta-select .choices.is-open:not(.is-flipped) .choices__list[aria-expanded] {
  margin-top: 8px;
}

.meta-select .choices.is-open.is-flipped .choices__list--dropdown, .meta-select .choices.is-open.is-flipped .choices__list[aria-expanded] {
  margin-bottom: 8px;
}

.meta-select .choices__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meta-select .choices__placeholder {
  color: var(--black);
  opacity: 1;
  background: transparent;
}

.meta-select .choices__list {
  padding: 0;
  background-color: var(--gray-bg);
}

.meta-select .choices__list .choices__item {
  padding-right: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  font-family: var(--font-main);
  color: var(--dark-gray);
  transition: all 0.3s ease;
  text-decoration: underline;
  text-decoration-color: transparent;
}

.meta-select .choices__list .choices__item.is-selected {
  color: var(--purple);
}

.meta-select .choices__list--single {
  background-color: transparent !important;
}

.meta-select .choices__list--single .choices__item {
  color: var(--dark-gray);
}

.meta-select .choices__list--multiple {
  color: var(--dark-gray);
}

.meta-select .choices__list--multiple .choices__item--selectable {
  display: none;
}

.meta-select .choices__list--multiple + .choices__input {
  display: none;
}

.meta-select .choices__list--dropdown .choices__item {
  color: var(--dark-gray);
  text-decoration: none !important;
}

.meta-select .choices__list--dropdown .choices__item:hover:not(.is-selected) {
  color: var(--pink) !important;
  text-decoration: none !important;
}

.meta-select .choices[data-type*=select-one], .meta-select .choices[data-type*=select-multiple] {
  cursor: pointer;
}

.meta-select .choices[data-type*=select-one]:after, .meta-select .choices[data-type*=select-multiple]:after {
  border: none;
  border-bottom: 1px solid var(--midnight);
  border-right: 1px solid var(--midnight);
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: clamp(18px, 18px + 8 * (100vw - 768px) / 672, 26px);
  top: 25px;
  transform-origin: 66% 66%;
  transform: rotate(45deg) scale(2);
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.meta-select .choices.is-open:after {
  transform: rotate(-135deg) scale(2);
}

.meta-select .choices__list--dropdown .choices__item--selectable.is-highlighted, .meta-select .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: transparent;
}

.meta-select .choices__list--dropdown .choices__item--selectable.is-highlighted:not(.is-selected), .meta-select .choices__list[aria-expanded] .choices__item--selectable.is-highlighted:not(.is-selected) {
  color: var(--purple);
}

.meta-select .choices__list--dropdown, .meta-select .choices .choices__list[aria-expanded] {
  border: 2px solid var(--purple);
  z-index: 20;
  border-radius: 8px;
}

.meta-select .choices[data-type*=select-one] .choices__input {
  margin: 2px;
  max-width: calc(100% - 4px);
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  background: transparent;
  color: var(--black);
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  font-family: var(--font-main);
}

.meta-select .choices[data-type*=select-multiple] .choices__inner {
  cursor: pointer;
}

.input-wr {
  display: flex;
  gap: 9px;
  flex-direction: column;
  width: 100%;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
}

.input-wr span {
  padding-left: 24px;
}

.input-wr input, .input-wr textarea {
  font-weight: 400;
  font-size: 16px;
}

.input-wr input::-moz-placeholder, .input-wr textarea::-moz-placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.input-wr input::placeholder, .input-wr textarea::placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.input-wr input {
  height: 48px;
  border-radius: 74px;
  background: none;
  border: 1px solid var(--light-line-i);
  font-size: 16px;
  color: var(--white);
  opacity: 1;
  letter-spacing: 0.01em;
  padding: 12px 24px;
}

.input-wr input::-moz-placeholder {
  opacity: 1;
  color: inherit;
}

.input-wr input::placeholder {
  opacity: 1;
  color: inherit;
}

.input-wr textarea {
  width: 100%;
  background: none;
  resize: none;
  height: 37px;
  padding: 2px 24px 5px;
}

.input-wr textarea::-moz-placeholder {
  opacity: 1;
  color: var(--white);
}

.input-wr textarea::placeholder {
  opacity: 1;
  color: var(--white);
}

.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  min-width: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  /*&.pagination-next {
    margin-left: ac(16px, 8px);
  }

  &.pagination-prev {
    margin-right: ac(16px, 8px);
  }*/
}

.pagination-btn i {
  font-size: 16px;
  color: var(--midnight);
  transition: color 0.3s ease;
}

.pagination-btn.pagination-btn-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-btn:hover:not(.pagination-btn-disabled) {
  border-color: var(--midnight);
}

.pagination-num {
  text-align: center;
  font-size: clamp(15px, 15px + 1 * (100vw - 768px) / 672, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  height: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  min-width: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-num span {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(1px);
  color: var(--dark-gray);
  transition: all 0.3s ease;
}

.pagination-num.active {
  cursor: not-allowed;
  border-color: var(--pink);
}

.pagination-num.active span {
  color: var(--midnight);
}

.pagination-num.pointer-events-none {
  pointer-events: none;
  min-width: 28px;
  width: 28px;
}

.pagination-num:hover:not(.active) {
  color: var(--pink);
}

.pagination {
  margin-top: clamp(35px, 35px + 5 * (100vw - 768px) / 672, 40px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: clamp(4px, 4px + 4 * (100vw - 768px) / 672, 8px);
}

.single-range {
  padding-right: 10px;
  margin-bottom: 0;
  height: clamp(4px, 4px + 4 * (100vw - 768px) / 672, 8px);
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: grey;
}

.single-range .noUi-connect {
  background: var(--white);
  border-radius: 0;
}

.single-range .noUi-tooltip {
  transform: translate(-50%, 100%);
  left: 50%;
  bottom: clamp(-23px, -19px + -4 * (100vw - 768px) / 672, -19px);
  background: var(--purple);
  font-size: clamp(12px, 12px + 6 * (100vw - 768px) / 672, 18px);
  font-weight: 600;
  font-family: var(--font-main);
  color: black;
  line-height: 1.2;
  padding: 2px 4px;
  border: none;
  border-radius: 4px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  min-width: clamp(36px, 36px + 20 * (100vw - 768px) / 672, 56px);
  leading-trim: both;
  text-edge: cap;
  box-shadow: 0px 0px 15px 0px rgba(141, 232, 252, 0.4);
}

.single-range .noUi-tooltip:after {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-100%) translateX(-50%) translateZ(0);
  content: "";
  background: var(--pink);
  opacity: 0.3;
  width: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  height: clamp(7px, 7px + 3 * (100vw - 768px) / 672, 10px);
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.single-range .noUi-handle {
  top: clamp(-13px, -8.5px + -4.5 * (100vw - 768px) / 672, -8.5px);
  background: var(--pink);
  width: clamp(22px, 22px + 12 * (100vw - 768px) / 672, 34px);
  height: clamp(22px, 22px + 12 * (100vw - 768px) / 672, 34px);
  border: 2px solid var(--white);
  cursor: pointer;
  border-radius: 50%;
  box-shadow: none;
}

.single-range .noUi-handle:before, .single-range .noUi-handle:after {
  content: none;
}

.single-range-info {
  font-family: var(--font-main);
  font-size: clamp(12px, 12px + 2 * (100vw - 768px) / 672, 14px);
  font-weight: 500;
  color: white;
  margin-right: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
}

.single-range-container {
  display: flex;
  align-items: center;
  position: relative;
}

.single-range-container .single-range {
  flex: 1;
  order: 2;
}

.single-range-container .single-range-info {
  order: 1;
  display: none;
}

.single-range-container .single-range-minmax {
  display: block;
  width: 2px;
  height: clamp(10px, 10px + 10 * (100vw - 768px) / 672, 20px);
  position: relative;
  background: var(--white);
}

.single-range-container .single-range-minmax:first-child {
  position: absolute;
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
}

.single-range-container .single-range-minmax:last-child {
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-50%);
}

.single-range-container .single-range-minmax span {
  position: absolute;
  bottom: clamp(-12px, -8px + -4 * (100vw - 768px) / 672, -8px);
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  line-height: 1;
  font-family: var(--font-main);
  font-weight: 600;
  font-size: clamp(12px, 12px + 2 * (100vw - 768px) / 672, 14px);
}

/*double-range*/
.double-range {
  padding-right: 15px;
  margin-bottom: 80px;
  height: 6px;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  background: lightgrey;
}

.double-range .noUi-tooltip {
  transform: translate(-50%, 100%);
  left: 50%;
  bottom: -10px;
  display: block !important;
  background: var(--white);
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--black);
  line-height: 1.2;
  padding: 11px 12px;
  border: 1px solid var(--lavender);
  border-radius: 4px;
}

.double-range .noUi-tooltip.hidden {
  display: none !important;
}

.double-range .noUi-connect {
  background: var(--lavender);
  margin-right: -4px;
}

.double-range .noUi-handle {
  background: var(--white);
  width: 18px;
  height: 18px;
  border: 2px solid var(--lavender);
  cursor: pointer;
  border-radius: 50%;
  box-shadow: none;
}

.double-range .noUi-handle:before, .double-range .noUi-handle:after {
  content: none;
}

.agp-popup {
  padding: 0;
  --px: clamp(20px, calc(20px + 44.00 * ((100vw - 768px) / 672)), 64px);
  --main-radius: clamp(24px, calc(24px + 24.00 * ((100vw - 768px) / 672)), 48px);
  --scroll-color: var(--rose);
  width: 96%;
  max-width: clamp(740px, 740px + 460 * (100vw - 768px) / 672, 1200px);
  overflow: hidden;
  border-radius: var(--main-radius);
  -webkit-clip-path: inset(1px 1px 1px 1px round clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px));
  clip-path: inset(1px 1px 1px 1px round clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px));
}

.agp-popup.therapist-type .agp-popup__hero-bg:after {
  background-color: var(--midnight);
}

.agp-popup.therapist-type .agp-popup__list-item {
  background-color: var(--midnight);
}

.agp-popup.therapist-type .agp-popup__list-item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--pink);
}

.agp-popup.therapist-type .social-popup-btn {
  border-color: var(--purple);
}

.agp-popup.therapist-type .social-popup-btn i {
  color: var(--purple);
}

.agp-popup.therapist-type .social-popup-btn:hover {
  background-color: var(--purple);
}

.agp-popup.therapist-type .social-popup-btn:hover i {
  color: var(--white);
}

.agp-popup.for-schools-type .agp-popup__hero {
  color: var(--black-regular);
}

.agp-popup.for-schools-type .agp-popup__hero-bg:after {
  background-color: var(--gray-bg);
}

.agp-popup.for-schools-type .agp-popup__hero-content .agp-popup__title {
  border-color: var(--rose);
}

.agp-popup .simplebar-track.simplebar-vertical {
  width: clamp(6px, 6px + 4 * (100vw - 768px) / 672, 10px);
  background: var(--light-line-i);
  border-radius: 0;
  transform: none;
  right: 0;
  z-index: 50;
}

.agp-popup .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--scroll-color);
  border-radius: 0;
}

.agp-popup .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.agp-popup__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-height: calc(var(--dvh, 1vh) * 90);
}

.agp-popup--hero, .agp-popup__main-content, .agp-popup__bottom {
  padding-left: var(--px);
  padding-right: var(--px);
}

.agp-popup__hero {
  padding-top: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
  padding-bottom: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  width: 100%;
  color: var(--white);
  position: relative;
  z-index: 1;
}

.agp-popup__hero:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 18 * (100vw - 768px) / 672, 48px);
}

.agp-popup__hero .agp-popup__text + .agp-popup__buttons {
  padding-top: 0;
}

.agp-popup__close {
  position: absolute;
  width: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  height: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--pink);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  right: 0;
  top: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
  transition: all 0.3s ease;
  background: var(--white);
}

.agp-popup__close i {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  color: var(--purple);
  transition: all 0.3s ease;
}

.agp-popup__close:hover {
  background-color: var(--gray-bg);
}

.agp-popup__close:hover i {
  color: var(--pink);
}

.agp-popup__hero-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(var(--px) * -1);
  right: calc(var(--px) * -1);
  z-index: -1;
  border-radius: var(--main-radius);
  overflow: hidden;
}

.agp-popup__hero-bg:after {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background: var(--pink);
}

.agp-popup__hero-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
}

.agp-popup__hero-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.agp-popup__hero-content .agp-popup__title {
  width: 100%;
  padding-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  padding-right: 56px;
  border-bottom: 1px solid var(--white);
}

.agp-popup__hero-content .agp-popup__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.agp-popup__hero-content .agp-popup__subtitle {
  opacity: 0.8;
}

.agp-popup__hero-content .agp-popup__subtitle:not(:last-child) {
  margin-bottom: clamp(8px, 10px + -2 * (100vw - 768px) / 672, 10px);
}

.agp-popup__hero-content .agp-popup__second-subtitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.agp-popup__hero-content .agp-popup__buttons {
  padding-top: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.agp-popup__title.subtitle-1:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.agp-popup__title.headline-6:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.agp-popup__text:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.agp-popup__buttons.bottom-type {
  width: 100%;
  justify-content: flex-end;
  padding-bottom: clamp(30px, 30px + 34 * (100vw - 768px) / 672, 64px);
  max-width: 1036px;
  margin-left: auto;
  margin-right: auto;
}

.agp-popup__main-content, .agp-popup__components, .agp-popup__overview, .agp-popup__list, .agp-popup__list-item, .agp-popup__programme, .agp-popup__bottom, .agp-popup__bottom-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.agp-popup__main-content + .agp-popup__main-content {
  margin-top: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

.agp-popup__overview:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.agp-popup__list {
  gap: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
}

.agp-popup__list:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.agp-popup__list-item {
  background: var(--pink);
  color: var(--white);
  padding: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px) clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  border-radius: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.agp-popup__list-item-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
}

.agp-popup__list-item-top:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.agp-popup__list-item-count {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  height: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  opacity: 0.5;
}

.agp-popup__list-item-title {
  flex: 1;
}

.agp-popup__list-item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical {
  width: clamp(3px, 3px + 1 * (100vw - 768px) / 672, 4px);
  background: var(--light-line-i);
}

.agp-popup__list-item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--midnight);
}

.agp-popup__components:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

.agp-popup__main-content.bg-gray-bg {
  padding-top: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
  padding-bottom: clamp(30px, 30px + 34 * (100vw - 768px) / 672, 64px);
}

.agp-popup__programme {
  padding-top: clamp(5px, 5px + 11 * (100vw - 768px) / 672, 16px);
}

.agp-popup__programme .agp-popup__title.headline-6:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.agp-popup__programme:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.agp-popup__programme-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
}

@media (max-width: 640px) {
  .agp-popup__programme-list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.agp-popup__programme-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  color: var(--white);
  padding-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  padding-bottom: clamp(20px, 20px + 8 * (100vw - 768px) / 672, 28px);
  padding-left: clamp(15px, 15px + 17 * (100vw - 768px) / 672, 32px);
  padding-right: clamp(15px, 15px + 17 * (100vw - 768px) / 672, 32px);
  border: 1px solid var(--light-line-i);
  min-height: clamp(200px, 200px + 67 * (100vw - 768px) / 672, 267px);
}

.agp-popup__programme-item-bg {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.agp-popup__programme-item-bg::before {
  content: "";
  display: block;
  padding-bottom: max(min(77.6960784314%, 9999px), 0px);
}

.agp-popup__programme-item-bg img, .agp-popup__programme-item-bg svg, .agp-popup__programme-item-bg video, .agp-popup__programme-item-bg iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.agp-popup__programme-item-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 634px;
  min-width: 100%;
  z-index: -1;
  pointer-events: none;
}

.agp-popup__programme-item-bg:after {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background: var(--midnight);
}

.agp-popup__programme-item-bg img {
  mix-blend-mode: luminosity;
}

.agp-popup__programme-item-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

.agp-popup__programme-item-top:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.agp-popup__programme-item-icon {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.agp-popup__programme-item-icon::before {
  content: "";
  display: block;
  padding-bottom: max(min(100%, 9999px), 0px);
}

.agp-popup__programme-item-icon img, .agp-popup__programme-item-icon svg, .agp-popup__programme-item-icon video, .agp-popup__programme-item-icon iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.agp-popup__programme-item-icon {
  width: clamp(50px, 50px + 14 * (100vw - 768px) / 672, 64px);
  min-width: clamp(50px, 50px + 14 * (100vw - 768px) / 672, 64px);
}

.agp-popup__programme-item-title {
  flex: 1;
}

.agp-popup__programme-item-text {
  width: 100%;
}

.agp-popup__programme-item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical {
  width: clamp(3px, 3px + 1 * (100vw - 768px) / 672, 4px);
  background: var(--light-line-i);
}

.agp-popup__programme-item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--scroll-color);
}

.agp-popup__opportunities {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.agp-popup__opportunities:last-child {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.agp-popup__opportunities + .agp-popup__cost {
  padding-top: clamp(20px, 20px + 52 * (100vw - 768px) / 672, 72px);
}

@media (max-width: 640px) {
  .agp-popup__opportunities {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 20px;
  }
}
.agp-popup__opportunities-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 47.76%;
}

@media (max-width: 640px) {
  .agp-popup__opportunities-content {
    width: 100%;
  }
}
.agp-popup__opportunities-content .agp-popup__title:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

.agp-popup__opportunities-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 47.76%;
  border-radius: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  background: var(--midnight);
  padding: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px) clamp(15px, 15px + 17 * (100vw - 768px) / 672, 32px) clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px) clamp(15px, 15px + 11 * (100vw - 768px) / 672, 26px);
  color: white;
}

.agp-popup__opportunities-list ul, .agp-popup__opportunities-list ol {
  display: flex;
  flex-direction: column;
  gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

.agp-popup__opportunities-list ul li, .agp-popup__opportunities-list ol li {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.4;
  letter-spacing: 0.01em;
  font-weight: 500;
  position: relative;
}

.agp-popup__opportunities-list ul li {
  padding-left: clamp(26px, 26px + 10 * (100vw - 768px) / 672, 36px);
  margin-bottom: 0 !important;
}

.agp-popup__opportunities-list ul li::before {
  content: "";
  border-radius: 0;
  display: block;
  width: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  height: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  background: url("../../assets/images/list-marker-2.svg") no-repeat center/contain;
  left: 4px;
  top: 50%;
  opacity: 0.7;
  transform: translateY(-50%);
  position: absolute;
}

@media (max-width: 640px) {
  .agp-popup__opportunities-list {
    width: 100%;
  }
}
.agp-popup__cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}

.agp-popup__cost:last-child {
  padding-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

@media (max-width: 640px) {
  .agp-popup__cost {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 20px;
  }
}
.agp-popup__cost-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 43.28%;
}

@media (max-width: 640px) {
  .agp-popup__cost-content {
    width: 100%;
  }
}
.agp-popup__cost-content .agp-popup__title:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.agp-popup__cost-accordion.faq-section__accordion {
  width: 52.24%;
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-title {
  color: var(--black);
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-top {
  margin-top: 0;
  margin-bottom: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-top:after {
  opacity: 1;
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-btn {
  border-color: var(--black);
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item {
  padding-top: clamp(18px, 18px + 1 * (100vw - 768px) / 672, 19px);
  padding-bottom: clamp(18px, 18px + 1 * (100vw - 768px) / 672, 19px);
  padding-left: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  padding-right: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  border-radius: 12px;
  overflow: hidden;
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item-top {
  border-bottom: none;
  position: relative;
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item-top:after {
  position: absolute;
  bottom: 0;
  left: clamp(-25px, -15px + -10 * (100vw - 768px) / 672, -15px);
  right: clamp(-25px, -15px + -10 * (100vw - 768px) / 672, -15px);
  height: 1px;
  background: var(--light-line-ii);
  transition: all 0.3s ease;
  opacity: 0;
  content: "";
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item-text {
  margin-bottom: clamp(0px, 0px + 4 * (100vw - 768px) / 672, 4px);
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item-text[data-simplebar-popup] {
  --line-count: 12;
  width: 100%;
  padding-right: 8px;
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical {
  width: clamp(3px, 3px + 1 * (100vw - 768px) / 672, 4px);
  background: var(--light-line-i);
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--midnight);
}

.agp-popup__cost-accordion.faq-section__accordion .faq-section__item-btn {
  transition: all 0.3s ease;
}

@media (max-width: 640px) {
  .agp-popup__cost-accordion.faq-section__accordion {
    width: 100%;
  }
}
.agp-popup__faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.agp-popup__faq .agp-popup__title {
  max-width: clamp(500px, 500px + 216 * (100vw - 768px) / 672, 716px);
  text-align: center;
}

.agp-popup__faq .agp-popup__text {
  max-width: clamp(500px, 500px + 216 * (100vw - 768px) / 672, 716px);
  text-align: center;
}

.agp-popup__faq:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.agp-popup__faq-accordion.faq-section__accordion {
  max-width: 1036px;
  margin-left: auto;
  margin-right: auto;
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item.active {
  background-color: var(--pink);
  color: var(--white);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-title {
  color: var(--white);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-top {
  margin-top: 0;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-top:after {
  opacity: 1;
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-btn {
  border-color: var(--white);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item.active .faq-section__item-btn i {
  color: var(--white);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item {
  padding-top: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  padding-bottom: 16px;
  padding-left: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  padding-right: clamp(15px, 15px + 4 * (100vw - 768px) / 672, 19px);
  border-radius: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
  background: var(--white);
  overflow: hidden;
  border-color: var(--light-line-i);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-top {
  border-bottom: none;
  position: relative;
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-top:after {
  position: absolute;
  bottom: 0;
  left: clamp(-25px, -15px + -10 * (100vw - 768px) / 672, -15px);
  right: clamp(-25px, -15px + -10 * (100vw - 768px) / 672, -15px);
  height: 1px;
  background: var(--light-line-i);
  transition: all 0.3s ease;
  opacity: 0;
  content: "";
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-text {
  color: var(--white);
  margin-bottom: clamp(0px, 0px + 4 * (100vw - 768px) / 672, 4px);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-text[data-simplebar-popup] {
  --line-count: 12;
  width: 100%;
  padding-right: 8px;
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical {
  width: clamp(3px, 3px + 1 * (100vw - 768px) / 672, 4px);
  background: var(--light-line-i);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-text[data-simplebar-popup] .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--midnight);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-btn {
  transition: all 0.3s ease;
  border-color: var(--pink);
}

.agp-popup__faq-accordion.faq-section__accordion .faq-section__item-btn i {
  color: var(--pink);
}

.agp-popup__share {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
  grid-row-gap: 10px;
}

.agp-popup__share-socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.agp-popup__bottom {
  position: relative;
  z-index: 1;
  padding-top: clamp(32px, 32px + 27 * (100vw - 768px) / 672, 59px);
  padding-bottom: clamp(32px, 32px + 27 * (100vw - 768px) / 672, 59px);
  color: var(--white);
  min-height: clamp(200px, 200px + 400 * (100vw - 768px) / 672, 600px);
}

.agp-popup__bottom + .agp-popup__main-content {
  padding-top: clamp(24px, 24px + 40 * (100vw - 768px) / 672, 64px);
}

@media (min-width: 769px) {
  .agp-popup__bottom {
    justify-content: center;
  }
}
.agp-popup__bottom-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
}

.agp-popup__bottom-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
}

.agp-popup__bottom-content {
  max-width: 55.97%;
}

@media (max-width: 768px) {
  .agp-popup__bottom-content {
    max-width: 100%;
  }
}
.agp-popup__bottom-title:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.agp-popup__bottom-subtitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.agp-popup__bottom-text:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.agp-popup__bottom-buttons {
  width: 100%;
  grid-column-gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

.agp-popup__bottom-buttons .agp-popup__share {
  color: var(--white);
  margin-right: auto;
}

.agp-popup__bottom-buttons .agp-popup__share .social-popup-btn {
  border-color: var(--white);
}

.agp-popup__bottom-buttons .agp-popup__share .social-popup-btn i {
  color: var(--white);
}

.agp-popup__bottom-buttons .agp-popup__share .social-popup-btn:hover {
  border-color: var(--purple);
  background-color: var(--purple);
}

.agp-popup__bottom-buttons .agp-popup__share .social-popup-btn:hover i {
  color: var(--white);
}

@media (max-width: 640px) {
  .agp-popup__bottom-buttons .agp-popup__share:not(:last-child) {
    margin-bottom: 10px;
  }
}
.agp-popup__bottom-image {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;
}

.agp-popup__bottom-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
}

@media (max-width: 768px) {
  .agp-popup__bottom-image {
    /*position: static;
    inset: auto;
    width: calc(100% + (var(--px) * 2));
    margin-left: calc(var(--px) * -1);*/
    display: none;
  }
}
.team-popup {
  padding: 0;
  --px: clamp(20px, calc(20px + 36.00 * ((100vw - 768px) / 672)), 56px);
  --main-radius: clamp(24px, calc(24px + 24.00 * ((100vw - 768px) / 672)), 48px);
  --scroll-color: var(--rose);
  width: 96%;
  max-width: clamp(740px, 740px + 460 * (100vw - 768px) / 672, 1200px);
  overflow: hidden;
  border-radius: var(--main-radius);
  -webkit-clip-path: inset(1px 1px 1px 1px round clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px));
  clip-path: inset(1px 1px 1px 1px round clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px));
}

.team-popup .simplebar-track.simplebar-vertical {
  width: clamp(6px, 6px + 4 * (100vw - 768px) / 672, 10px);
  background: var(--light-line-i);
  border-radius: 0;
  transform: none;
  right: 0;
  z-index: 50;
}

.team-popup .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--scroll-color);
  border-radius: 0;
}

.team-popup .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.team-popup__content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  max-height: calc(var(--dvh, 1vh) * 73.78);
  position: relative;
}

@media (max-width: 1024px) and (min-height: 910px) {
  .team-popup__content {
    max-height: calc(var(--dvh, 1vh) * 55);
  }
}
@media (max-width: 640px) {
  .team-popup__content {
    max-height: calc(var(--dvh, 1vh) * 88);
  }
}
.team-popup__container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.team-popup__container:after {
  bottom: 0;
  right: 0;
  height: clamp(30px, 30px + 46 * (100vw - 768px) / 672, 76px);
  content: "";
  position: fixed;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 90.72%);
  width: 56%;
  pointer-events: none;
}

@media (max-width: 640px) {
  .team-popup__container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .team-popup__container:after {
    width: 100%;
  }
}
.team-popup__close {
  position: absolute;
  width: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  height: clamp(32px, 32px + 16 * (100vw - 768px) / 672, 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--pink);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  right: var(--px);
  top: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
  transition: all 0.3s ease;
  background: var(--white);
  z-index: 10;
}

.team-popup__close i {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  color: var(--purple);
  transition: all 0.3s ease;
}

.team-popup__close:hover {
  background-color: var(--white);
  border-color: var(--rose);
}

.team-popup__close:hover i {
  color: var(--rose);
}

.team-popup__card.teammate-card {
  width: 40%;
  border-radius: 0;
  background: var(--midnight);
}

@media (min-width: 641px) {
  .team-popup__card.teammate-card {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
}
@media (max-width: 640px) {
  .team-popup__card.teammate-card {
    width: 100%;
  }
}
.team-popup__card.teammate-card .teammate-card__text {
  background: var(--midnight);
  color: var(--white);
  padding-top: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  padding-left: var(--px);
  padding-right: var(--px);
}

.team-popup__card.teammate-card .teammate-card__name {
  margin-bottom: clamp(8px, 8px + 6 * (100vw - 768px) / 672, 14px);
}

.team-popup__card.teammate-card .teammate-card__position {
  color: var(--white);
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: 1.4545;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-bottom: clamp(35px, 35px + 19 * (100vw - 768px) / 672, 54px);
}

.team-popup__card.teammate-card .teammate-card-socials__link {
  color: var(--white);
}

.team-popup__card.teammate-card .teammate-card-thumbnail {
  border-bottom: clamp(4px, 4px + 2 * (100vw - 768px) / 672, 6px) solid var(--lavender);
}

.team-popup__main-content {
  position: relative;
  padding-right: var(--px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 56%;
  padding-bottom: clamp(30px, 30px + 46 * (100vw - 768px) / 672, 76px);
  padding-top: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
  margin-left: auto;
}

@media (max-width: 640px) {
  .team-popup__main-content {
    width: 100%;
    padding-left: var(--px);
  }
}
.team-popup__subtitle:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.team-popup__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.team-popup__text:not(:last-child) {
  margin-bottom: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.team-popup__buttons {
  width: 100%;
}

.team-popup__buttons.bottom-type {
  padding-top: clamp(15px, 15px + 5 * (100vw - 768px) / 672, 20px);
  justify-content: flex-end;
}

@media (max-width: 451px) {
  .team-popup__buttons.bottom-type {
    justify-content: flex-start;
  }
}
.apply-popup {
  padding: 0;
  --px: clamp(20px, calc(20px + 20.00 * ((100vw - 768px) / 672)), 40px);
  --main-radius: clamp(24px, calc(24px + 8.00 * ((100vw - 768px) / 672)), 32px);
  --scroll-color: var(--purple);
  width: 96%;
  max-width: 596px;
  overflow: hidden;
  border-radius: var(--main-radius);
  -webkit-clip-path: inset(1px 1px 1px 1px round clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px));
  clip-path: inset(1px 1px 1px 1px round clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px));
  background: var(--pink);
  /*&__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(20px, 18px);
    }
  }*/
  /*&__buttons {
    width: 100%;

    &.bottom-type {
      padding-top: ac(20px, 15px);
      justify-content: flex-end;

      @media (max-width: 451px) {
        justify-content: flex-start;
      }
    }
  }*/
}

.apply-popup .simplebar-track.simplebar-vertical {
  width: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
  background: var(--light-line-i);
  border-radius: 0;
  transform: none;
  right: 0;
  z-index: 50;
}

.apply-popup .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--scroll-color);
  border-radius: 0;
}

.apply-popup .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.apply-popup__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: calc(var(--dvh, 1vh) * 88);
  position: relative;
}

.apply-popup__content:after {
  bottom: 0;
  right: 0;
  left: 0;
  height: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  content: "";
  position: fixed;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--pink) 90.72%);
  pointer-events: none;
}

.apply-popup__close {
  position: absolute;
  width: clamp(32px, 32px + 12 * (100vw - 768px) / 672, 44px);
  height: clamp(32px, 32px + 12 * (100vw - 768px) / 672, 44px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--white);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  right: var(--px);
  top: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  transition: all 0.3s ease;
  background: transparent;
  z-index: 10;
}

.apply-popup__close i {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  color: var(--white);
  transition: all 0.3s ease;
}

.apply-popup__close:hover {
  border-color: var(--purple);
}

.apply-popup__close:hover i {
  color: var(--purple);
}

.apply-popup__main-content {
  position: relative;
  padding-right: var(--px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  padding-top: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  padding-left: var(--px);
}

.apply-popup__main-content .hero-contact-form {
  width: 100%;
  background: transparent;
  padding: 0;
  border: none;
}

.apply-popup__main-content .btn img {
  width: 24px;
  height: 24px;
}

.hero {
  padding-top: var(--header-height);
  min-height: calc(100 * var(--vh, 1vh));
  display: flex;
}

.hero__subtitle {
  max-width: clamp(370px, 370px + 268 * (100vw - 768px) / 672, 638px);
}

.hero__title {
  margin-bottom: 8px;
}

.hero__btn {
  color: var(--purple);
}

.hero__btn-box {
  margin-top: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  gap: 17px;
}

.hero__img img {
  -o-object-position: right;
  object-position: right;
}

@media (min-width: 2000px) {
  .hero__img img {
    -o-object-position: left center;
    object-position: left center;
  }
}
.hero__img--mob {
  display: none;
}

@media not all and (min-width: 640px) {
  .hero__img {
    display: none;
  }
  .hero__img--mob {
    display: block;
  }
  .hero__img:after {
    position: absolute;
    inset: 0;
    background: #000;
    opacity: 0.2;
    content: "";
  }
}
.hero__card-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background: var(--midnight);
}

.hero__card-bg img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
  opacity: 0.6;
}

.hero__card-bg picture:nth-of-type(2) img {
  opacity: 0.25;
}

.hero__card-bg div {
  content: "";
  display: block;
  position: absolute;
  background: var(--midnight);
  inset: 0;
  opacity: 0.5;
}

.hero__card-bg-mob {
  display: none;
}

@media not all and (min-width: 640px) {
  .hero__card-bg {
    display: none;
  }
  .hero__card-bg-mob {
    display: block;
  }
}
.hero .swiper-slide:nth-of-type(1) .hero__card-bg {
  background: var(--midnight);
}

.hero .swiper-slide:nth-of-type(1) .hero__card-bg-mob div {
  z-index: 1;
}

.hero .swiper-slide:nth-of-type(1) .hero__card-bg-mob picture:nth-of-type(1) img {
  z-index: 0;
  opacity: 0.6;
}

.hero .swiper-slide:nth-of-type(1) .hero__card-bg-mob picture:nth-of-type(2) img {
  z-index: 2;
  opacity: 0.25;
}

.hero .swiper-slide:nth-of-type(2) .hero__card-bg-mob div {
  z-index: 2;
}

.hero .swiper-slide:nth-of-type(2) .hero__card-bg-mob picture:nth-of-type(1) img {
  z-index: 0;
}

.hero .swiper-slide:nth-of-type(2) .hero__card-bg-mob picture:nth-of-type(2) img {
  z-index: 1;
}

.hero .swiper-slide:nth-of-type(3) .hero__card-bg-mob div {
  z-index: 2;
}

.hero .swiper-slide:nth-of-type(3) .hero__card-bg-mob picture:nth-of-type(1) img {
  z-index: 0;
}

.hero .swiper-slide:nth-of-type(3) .hero__card-bg-mob picture:nth-of-type(2) img {
  z-index: 1;
}

@media not all and (min-width: 640px) {
  .hero .hero__nav {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    bottom: 32px;
    gap: 12px;
  }
  .hero .hero__title {
    font-size: 44px;
    line-height: 1.28;
    letter-spacing: 0.02em;
    margin-bottom: 14px;
  }
  .hero .hero__title span {
    font-size: 36px;
    line-height: 1.2;
    display: block;
    margin: 0;
  }
  .hero .hero__subtitle {
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.01em;
    margin-top: 0;
    margin-bottom: 24px;
  }
  .hero .hero__card {
    padding-bottom: 104px;
    justify-content: stretch;
    min-height: 100%;
  }
  .hero .hero__card .cont {
    height: 100%;
  }
  .hero .hero__col {
    height: 100%;
    justify-content: flex-end;
  }
  .hero .hero__btn-box {
    gap: 12px;
    margin-top: 0;
  }
}
.about-us {
  margin-top: clamp(40px, 40px + 44 * (100vw - 768px) / 672, 84px);
}

.about-us__img {
  border: 1px solid var(--gray-bg);
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: clamp(328px, 328px + 314 * (100vw - 768px) / 672, 642px);
  max-width: 100%;
  flex-shrink: 0;
  border-radius: clamp(92px, 92px + 70 * (100vw - 768px) / 672, 162px) 40px 50px clamp(92px, 92px + 70 * (100vw - 768px) / 672, 162px);
  margin-top: clamp(0px, 0px + 5 * (100vw - 768px) / 672, 5px);
}

.about-us__img::before {
  content: "";
  display: block;
  padding-top: 75.7%;
}

.about-us__img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.about-us__img-figure {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50.4672897196%;
}

.about-us__col {
  min-width: 0;
}

@media (min-width: 769px) {
  .about-us__img {
    max-width: clamp(350px, 350px + 290 * (100vw - 768px) / 672, 640px);
  }
}
@media (max-width: 768px) {
  .about-us__img {
    margin: 0 auto;
  }
}
.pathways {
  margin-top: clamp(32px, 32px + 52 * (100vw - 768px) / 672, 84px);
  padding: clamp(40px, 40px + 91 * (100vw - 768px) / 672, 131px) 0 clamp(40px, 40px + 60 * (100vw - 768px) / 672, 100px);
}

.pathways__scroll {
  max-height: calc(clamp(28px, 28px + 2 * (100vw - 768px) / 672, 30px) * 16 + clamp(10px, 10px + 5 * (100vw - 768px) / 672, 15px));
}

.pathways__descr > *:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 5 * (100vw - 768px) / 672, 15px);
}

.pathways__wrapper {
  align-items: center;
}

.pathways__col {
  margin-top: clamp(-10px, 0px + -10 * (100vw - 768px) / 672, 0px);
}

.pathways__title {
  padding-right: clamp(0px, 0px + 50 * (100vw - 768px) / 672, 50px);
  margin-bottom: 6px;
}

.pathways__img {
  position: relative;
  overflow: hidden;
  border-radius: 40px;
  width: clamp(320px, 320px + 320 * (100vw - 768px) / 672, 640px);
  margin-top: clamp(-9px, 0px + -9 * (100vw - 768px) / 672, 0px);
}

.pathways__img::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.pathways__img-decor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.pathways__img-decor img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.pathways__img-thumbnail {
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.pathways__img-thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

@media (min-width: 769px) {
  .pathways__col {
    max-width: clamp(350px, 350px + 226 * (100vw - 768px) / 672, 576px);
  }
}
@media not all and (min-width: 769px) {
  .pathways .pathways__wrapper {
    flex-direction: column-reverse;
  }
  .pathways .pathways__img {
    border-radius: 24px;
    width: 375px;
    max-width: 100%;
    margin: 0 auto;
  }
}
@media not all and (min-width: 640px) {
  .pathways .pathways__scroll {
    width: calc(100% + 6px);
    max-width: calc(100% + 6px);
  }
  .pathways .pathways__descr {
    font-size: 16px;
    line-height: 24px;
  }
}
.aspirational {
  padding-bottom: clamp(60px, 60px + 6 * (100vw - 768px) / 672, 66px);
}

.aspirational::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 46%;
  background-color: var(--pink);
  z-index: -1;
}

.aspirational__title {
  padding-right: clamp(0px, 0px + 20 * (100vw - 768px) / 672, 20px);
}

.aspirational__wrapper {
  border-radius: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  gap: 5px clamp(30px, 30px + 34 * (100vw - 768px) / 672, 64px);
}

.aspirational__scroll {
  padding-right: clamp(0px, 6px + -6 * (100vw - 768px) / 672, 6px);
  --line-count:10;
  --fz: 18px;
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  margin-top: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
}

.aspirational .pathways__btn-box {
  margin-top: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.aspirational__col {
  min-width: 0;
  padding: clamp(20px, 20px + 13 * (100vw - 768px) / 672, 33px) clamp(15px, 15px + 47 * (100vw - 768px) / 672, 62px) clamp(20px, 20px + 15 * (100vw - 768px) / 672, 35px) clamp(0px, 15px + -15 * (100vw - 768px) / 1, 15px);
}

.aspirational__img {
  border-radius: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px) 0 0 clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  overflow: hidden;
}

.aspirational-thumbnail {
  position: relative;
  width: clamp(350px, 350px + 301 * (100vw - 768px) / 672, 651px);
  max-width: 100%;
  flex-shrink: 0;
  min-height: 100%;
  overflow: hidden;
}

.aspirational-thumbnail::before {
  content: "";
  display: block;
  padding-top: 90.015%;
}

.aspirational-thumbnail__wrapper, .aspirational-thumbnail__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 3;
}

.aspirational-thumbnail__wrapper img, .aspirational-thumbnail__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.aspirational-thumbnail__bg {
  z-index: 0;
}

.aspirational-thumbnail__decor {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% + 2px);
  height: 100%;
  z-index: 1;
  overflow: hidden;
  margin-left: -1px;
}

.aspirational-thumbnail__decor img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

@media (min-width: 769px) {
  .aspirational__img {
    max-width: clamp(350px, 350px + 301 * (100vw - 768px) / 672, 651px);
  }
}
@media not all and (min-width: 769px) {
  .aspirational .aspirational-thumbnail {
    width: 100% !important;
  }
  .aspirational .aspirational__col {
    padding-top: 20px !important;
  }
  .aspirational__img {
    border-bottom-left-radius: 0;
  }
}
@media not all and (min-width: 640px) {
  .aspirational .aspirational__title {
    width: calc(100% + 5px);
    font-size: 26px;
    line-height: 31px;
  }
  .aspirational .aspirational__descr {
    font-size: 16px;
    line-height: 24px;
  }
}
.sectors {
  padding-top: clamp(32px, 32px + 54 * (100vw - 768px) / 672, 86px);
  padding-bottom: clamp(40px, 40px + 46 * (100vw - 768px) / 672, 86px);
}

.sectors__sup {
  margin-bottom: 18px;
}

.sectors__decor {
  width: 1568px;
  height: calc(100% - 26px);
  transform: translateX(calc(-50% - 43px));
}

.sectors .btn--pink {
  transition: 0.3s ease !important;
}

.sectors__slider-box {
  margin-top: clamp(40px, 40px + 18 * (100vw - 768px) / 672, 58px);
}

.sectors__main-slider {
  margin: 0;
  margin-right: 32px;
  max-width: clamp(320px, 320px + 133 * (100vw - 768px) / 672, 453px);
  flex-shrink: 0;
  overflow: hidden;
}

@media (min-width: 551px) {
  .sectors__main-slider .swiper-slide {
    opacity: 0 !important;
  }
  .sectors__main-slider .swiper-slide .sectors__card {
    transform: scale(0.6);
  }
  .sectors__main-slider .swiper-slide-active {
    opacity: 1 !important;
  }
  .sectors__main-slider .swiper-slide-active .sectors__card {
    transform: scale(1);
  }
}
@media not all and (min-width: 640px) {
  .sectors__main-slider .swiper-slide {
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
}
.sectors__main-slider .sectors__card {
  border: 1px solid var(--light-line-i);
  border-radius: clamp(40px, 40px + 20 * (100vw - 768px) / 672, 60px) clamp(40px, 40px + 20 * (100vw - 768px) / 672, 60px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  overflow: hidden;
  transition: border-color 0.3s ease, transform 0.5s ease;
}

.sectors__main-slider .sectors__card:hover {
  border-color: var(--pink);
}

.sectors__main-slider .sectors__card:hover .arrow-btn {
  color: var(--pink);
}

.sectors__main-slider .sectors__card:hover .arrow-btn span {
  background-color: var(--pink);
  color: var(--white);
}

@media not all and (min-width: 640px) {
  .sectors__main-slider .sectors__card {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.sectors__main-slider .sectors__img {
  height: clamp(184px, 184px + 118 * (100vw - 320px) / 1120, 302px);
}

.sectors__main-slider .sectors__name {
  font-size: clamp(22px, 22px + 14 * (100vw - 768px) / 672, 36px);
  line-height: clamp(26px, 26px + 22 * (100vw - 768px) / 672, 48px);
  letter-spacing: 0.02em;
  font-weight: 600;
}

.sectors__main-slider .sectors__bottom {
  padding: clamp(15px, 15px + 14 * (100vw - 768px) / 672, 29px) clamp(15px, 15px + 24 * (100vw - 768px) / 672, 39px) clamp(15px, 15px + 23 * (100vw - 768px) / 672, 38px);
}

.sectors__main-slider .sectors__btn {
  margin-top: auto;
  padding-top: clamp(32px, 32px + 15 * (100vw - 768px) / 672, 47px);
}

.sectors__slider {
  left: clamp(100px, 115px + -15 * (100vw - 768px) / 672, 115px);
  margin: 0;
  max-width: clamp(250px, 250px + 119 * (100vw - 768px) / 672, 369px);
  display: grid;
  transform: translateY(-13px);
}

.sectors__slider .swiper-slide {
  opacity: 0 !important;
}

.sectors__slider .swiper-slide-active + .swiper-slide, .sectors__slider .swiper-slide-active + .swiper-slide + .swiper-slide, .sectors__slider .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
  opacity: 1 !important;
}

.sectors__slider .sectors__img {
  height: clamp(150px, 150px + 125 * (100vw - 320px) / 1120, 275px);
}

.sectors__slider .sectors__card {
  border: 1px solid var(--light-line-i);
  border-radius: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  overflow: hidden;
  transition: 0.3s ease;
}

.sectors__slider .sectors__card:hover {
  border-color: var(--pink);
}

.sectors__slider .sectors__card:hover .arrow-btn {
  color: var(--pink);
}

.sectors__slider .sectors__card:hover .arrow-btn span {
  background-color: var(--pink);
  color: var(--white);
}

.sectors__slider .sectors__bottom {
  padding: clamp(15px, 15px + 20 * (100vw - 768px) / 672, 35px) clamp(15px, 15px + 16 * (100vw - 768px) / 672, 31px) clamp(15px, 15px + 15 * (100vw - 768px) / 672, 30px);
}

.sectors__slider .sectors__name {
  font-weight: 600;
  font-size: clamp(20px, 20px + 8 * (100vw - 768px) / 672, 28px);
  line-height: clamp(24px, 24px + 12 * (100vw - 768px) / 672, 36px);
  letter-spacing: 0.02em;
  color: var(--midnight);
}

.sectors__slider .sectors__btn {
  margin-top: auto;
  padding-top: 37px;
}

.sectors__scrollbar {
  position: relative !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  right: 0 !important;
  background: var(--light-line-i);
  height: 4px !important;
  border-radius: 0 !important;
}

.sectors__scrollbar .swiper-scrollbar-drag {
  top: 0px;
  background: var(--lavender);
  border-radius: 0px !important;
  height: 4px;
}

.sectors__scroll {
  margin-top: 18px;
  padding-right: clamp(0px, 6px + -6 * (100vw - 768px) / 672, 6px);
  max-height: calc(clamp(22px, 22px + 5 * (100vw - 768px) / 672, 27px) * 5);
}

@media not all and (min-width: 640px) {
  .sectors__scroll {
    max-height: calc(clamp(22px, 22px + 5 * (100vw - 768px) / 672, 27px) * 8);
  }
}
.sectors__descr {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: clamp(22px, 22px + 5 * (100vw - 768px) / 672, 27px);
}

.sectors__nav {
  margin-top: clamp(18px, 18px + 11 * (100vw - 768px) / 672, 29px);
  gap: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
}

.sectors .btn {
  margin: 0 0 clamp(-2px, 0px + -2 * (100vw - 768px) / 672, 0px);
}

@media not all and (min-width: 640px) {
  .sectors {
    overflow: hidden;
  }
  .sectors .sectors__decor {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    transform: none;
    left: 0;
    right: 0;
  }
  .sectors .sectors__decor img {
    -o-object-position: left;
    object-position: left;
  }
  .sectors .sectors__nav {
    justify-content: center;
  }
  .sectors .sectors__scrollbar {
    justify-content: center;
    display: none;
  }
}
@media not all and (min-width: 451px) {
  .sectors .sectors__main-slider {
    overflow: visible;
  }
  .sectors .sectors__main-slider .sectors__img {
    height: 213px;
  }
  .sectors .sectors__main-slider .sectors__name {
    font-size: 26px;
    line-height: 32px;
  }
  .sectors .sectors__bottom {
    padding: 27px 24px 24px;
  }
  .sectors .sectors__scroll {
    margin-top: 16px;
  }
  .sectors .sectors__descr {
    font-size: 16px;
    line-height: 24px;
  }
  .sectors .sectors__slider {
    display: none;
  }
}
.clients {
  padding-top: clamp(40px, 40px + 46 * (100vw - 768px) / 672, 86px);
  padding-bottom: clamp(30px, 30px + 62 * (100vw - 768px) / 672, 92px);
  background-color: #fafafa;
}

.clients__marquee {
  margin-bottom: clamp(15px, 15px + 13 * (100vw - 768px) / 672, 28px);
}

.clients .js-marquee {
  gap: 12px;
  display: flex;
}

.clients__suptitle {
  margin-bottom: 17px;
}

.clients__title {
  margin-bottom: clamp(35px, 35px + 24 * (100vw - 768px) / 672, 59px);
}

.clients__logo {
  display: grid;
  place-items: center;
  padding: 39px;
  background-color: var(--white);
  width: 244px;
  height: 140px;
  border: 1px solid var(--light-line-i);
  border-radius: 12px;
  overflow: hidden;
}

.clients__logo img {
  width: auto;
  height: auto;
}

@media (min-width: 1441px) {
  .clients .cont {
    max-width: 100%;
    padding: 0;
  }
}
@media not all and (min-width: 769px) {
  .clients__logo {
    padding: 20px;
    width: 200px;
    height: 116px;
  }
}
@media not all and (min-width: 640px) {
  .clients .clients__wrapper {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .clients .clients__suptitle {
    margin-left: 0;
    order: 1;
  }
  .clients .clients__title {
    margin-left: 0;
    order: 2;
  }
  .clients .clients__marquee {
    order: 3;
  }
  .clients .btn {
    margin-top: 28px;
    order: 4;
  }
}
.jobs {
  padding: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px) 0 clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

.jobs__bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.jobs__suptitle {
  margin-bottom: clamp(15px, 15px + 2 * (100vw - 768px) / 672, 17px);
}

.jobs__nav {
  margin-bottom: clamp(-4px, 0px + -4 * (100vw - 768px) / 672, 0px);
  gap: clamp(15px, 15px + 18 * (100vw - 768px) / 672, 33px);
}

.jobs__card {
  padding: clamp(24px, 24px + 10 * (100vw - 768px) / 672, 34px) clamp(24px, 24px + 7 * (100vw - 768px) / 672, 31px) clamp(24px, 24px + 7 * (100vw - 768px) / 672, 31px);
  border: 1px solid var(--light-line-i);
  transition: 0.3s ease;
}

.jobs__card .arrow-btn span {
  width: clamp(34px, 34px + 4 * (100vw - 768px) / 672, 38px);
  height: clamp(34px, 34px + 4 * (100vw - 768px) / 672, 38px);
}

.jobs__card:hover {
  border-color: var(--pink);
}

.jobs__card:hover .arrow-btn {
  color: var(--pink);
}

.jobs__card:hover .arrow-btn span {
  background-color: var(--pink);
  color: var(--white);
}

.jobs__tag {
  font-size: clamp(16px, 16px + 6 * (100vw - 768px) / 672, 22px);
  line-height: clamp(19px, 19px + 13 * (100vw - 768px) / 672, 32px);
  font-weight: 600;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}

.jobs__name {
  font-weight: 600;
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: clamp(32px, 32px + 4 * (100vw - 768px) / 672, 36px);
  letter-spacing: 0.02em;
}

.jobs__info {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  line-height: clamp(17px, 17px + 2 * (100vw - 768px) / 672, 19px);
  font-weight: 600;
  letter-spacing: 0;
}

.jobs__info-box {
  gap: 12px 13px;
}

.jobs__info span {
  width: 24px;
  height: 24px;
  margin-right: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23B34CE3' d='M12 2.25a7.5 7.5 0 0 0-7.5 7.5c0 6.75 7.5 12 7.5 12s7.5-5.25 7.5-12a7.5 7.5 0 0 0-7.5-7.5Zm0 10.5a3 3 0 1 1 0-5.999 3 3 0 0 1 0 5.999Z'/%3E%3Cpath fill='%238000B3' d='M12 6a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 6Zm0 6a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-10.5a8.26 8.26 0 0 0-8.25 8.25c0 2.944 1.36 6.064 3.938 9.023a23.837 23.837 0 0 0 3.885 3.591.75.75 0 0 0 .861 0 23.835 23.835 0 0 0 3.879-3.59c2.573-2.96 3.937-6.08 3.937-9.024A8.26 8.26 0 0 0 12 1.5Zm0 19.313c-1.55-1.22-6.75-5.696-6.75-11.063a6.75 6.75 0 0 1 13.5 0c0 5.365-5.2 9.844-6.75 11.063Z'/%3E%3C/svg%3E");
}

.jobs__info--perm span {
  margin-right: 9px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23B34CE3' d='M21 11.092v7.658a.75.75 0 0 1-.75.75H3.75a.75.75 0 0 1-.75-.75v-7.658a17.92 17.92 0 0 0 9 2.408c3.16.005 6.265-.826 9-2.408Z'/%3E%3Cpath fill='%238000B3' d='M9.75 10.5a.75.75 0 0 1 .75-.75h3a.75.75 0 1 1 0 1.5h-3a.75.75 0 0 1-.75-.75Zm12-3.75v12a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5v-12a1.5 1.5 0 0 1 1.5-1.5H7.5V4.5a2.25 2.25 0 0 1 2.25-2.25h4.5A2.25 2.25 0 0 1 16.5 4.5v.75h3.75a1.5 1.5 0 0 1 1.5 1.5ZM9 5.25h6V4.5a.75.75 0 0 0-.75-.75h-4.5A.75.75 0 0 0 9 4.5v.75Zm-5.25 1.5v3.902a17.257 17.257 0 0 0 16.5-.001V6.75H3.75Zm16.5 12v-6.41a18.772 18.772 0 0 1-16.5.001v6.409h16.5Z'/%3E%3C/svg%3E");
}

.jobs__descr {
  letter-spacing: 0;
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
}

@media not all and (min-width: 640px) {
  .jobs__btn--desk {
    display: none;
  }
}
.jobs__btn--mob {
  margin-top: 20px;
}

@media (min-width: 640px) {
  .jobs__btn--mob {
    display: none;
  }
}
.jobs__slider {
  margin-top: clamp(20px, 20px + 38 * (100vw - 768px) / 672, 58px);
}

.jobs .swiper-slide {
  width: clamp(375px, 375px + 37 * (100vw - 768px) / 672, 412px);
  max-width: 100%;
}

@media not all and (min-width: 640px) {
  .jobs .jobs__title-box {
    width: 100%;
    order: 0;
  }
  .jobs .jobs__slider {
    margin-top: 40px;
  }
}
@media (max-width: 370px) {
  .jobs__name {
    font-size: 20px;
  }
  .jobs__tag {
    font-size: 14px;
  }
}
.contact-us {
  padding: clamp(40px, 40px + 10 * (100vw - 768px) / 672, 50px) 0;
  box-shadow: 0 -20px 48px 0 rgba(0, 0, 0, 0.25), 0 -8px 16px 0 rgba(0, 0, 0, 0.12);
}

.contact-us__bg::before, .contact-us__bg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.contact-us__bg::before {
  background: var(--rose);
  mix-blend-mode: color;
}

.contact-us__bg::after {
  background: var(--black);
  opacity: 0.6;
}

.contact-us__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.contact-us__wrapper {
  gap: clamp(25px, 25px + 103 * (100vw - 768px) / 672, 128px);
}

.contact-us .sup {
  margin-bottom: 17px;
}

.contact-us__title {
  margin-bottom: 23px;
}

.contact-us__subtitle {
  font-size: clamp(20px, 20px + 8 * (100vw - 768px) / 672, 28px);
  line-height: clamp(26px, 26px + 10 * (100vw - 768px) / 672, 36px);
  letter-spacing: 0.02em;
  margin-bottom: 17px;
}

.contact-us__img {
  width: clamp(450px, 450px + 152 * (100vw - 568px) / 872, 602px);
  min-height: 100%;
  position: relative;
  overflow: hidden;
  align-self: stretch;
}

.contact-us__img::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.contact-us__img img {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

@media (min-width: 769px) {
  .contact-us {
    padding: 0;
  }
  .contact-us__title-box {
    padding: clamp(25px, 25px + 25 * (100vw - 768px) / 672, 50px) 0;
    max-width: clamp(350px, 350px + 278 * (100vw - 768px) / 672, 628px);
  }
}
@media not all and (min-width: 640px) {
  .contact-us {
    padding-bottom: 0;
    overflow: hidden;
  }
  .contact-us .contact-us__img {
    margin-left: -24px !important;
    width: calc(100% + 48px) !important;
    max-width: revert;
  }
}
.contact-us.agp-page, .contact-us.jobs-search-page {
  box-shadow: none;
  background: var(--pink);
}

.contact-us.agp-page .contact-us__bg:after, .contact-us.agp-page .contact-us__bg:before, .contact-us.jobs-search-page .contact-us__bg:after, .contact-us.jobs-search-page .contact-us__bg:before {
  content: none;
}

@media (min-width: 1441px) {
  .contact-us.agp-page .contact-us__bg, .contact-us.jobs-search-page .contact-us__bg {
    right: 30vw;
  }
}
@media (min-width: 769px) {
  .contact-us.agp-page .contact-us__title-box, .contact-us.jobs-search-page .contact-us__title-box {
    max-width: clamp(350px, 350px + 278 * (100vw - 768px) / 672, 628px);
  }
}
.contact-us.agp-page .contact-us__suptitle:not(:last-child), .contact-us.jobs-search-page .contact-us__suptitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

.contact-us.agp-page .contact-us__title:not(:last-child), .contact-us.jobs-search-page .contact-us__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

@media (min-width: 1441px) {
  .contact-us.agp-page .contact-us__img, .contact-us.jobs-search-page .contact-us__img {
    width: 50%;
  }
}
.contact-us.contact-us-interior {
  position: relative;
  z-index: 1;
  align-items: revert;
  background: var(--pink);
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);
  border-top: 1px solid var(--gray-bg);
}

.contact-us.contact-us-interior .sup {
  margin-bottom: 18px;
  color: var(--gray-bg);
}

.contact-us.contact-us-interior .contact-us__bg {
  width: 60.0694444444%;
}

.contact-us.contact-us-interior .contact-us__bg::before {
  display: none;
}

.contact-us.contact-us-interior .contact-us__bg::after {
  display: none;
}

.contact-us.contact-us-interior .contact-us__bg img {
  height: 100%;
}

@media (max-width: 768px) {
  .contact-us.contact-us-interior .contact-us__bg {
    width: 100%;
  }
}
.contact-us.contact-us-interior .contact-us__title {
  max-width: 100%;
  margin-bottom: 22px;
}

.contact-us.contact-us-interior .contact-us__subtitle {
  letter-spacing: 0.01em;
  line-height: 1.5;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
}

.contact-us.contact-us-interior .contact-us__subtitle p {
  margin-bottom: 14px;
}

.contact-us.contact-us-interior .contact-us__wrapper {
  gap: clamp(25px, 25px + 89 * (100vw - 768px) / 672, 114px);
}

.contact-us.contact-us-interior .contact-us__img {
  width: clamp(450px, 450px + 166 * (100vw - 568px) / 872, 616px);
}

.contact-us.contact-us-interior .contact-us__btn-box {
  margin-top: 40px;
  margin-bottom: 2px;
  gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

@media (min-width: 1400px) {
  .home-page .contact-us__img {
    min-height: 616px;
  }
}
@media (max-width: 650px) {
  .contact-us__title-box {
    padding: 0 !important;
  }
}
/*New*/
.text-image-section {
  padding-top: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.text-image-section__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.text-image-section__image {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.text-image-section__image::before {
  content: "";
  display: block;
  padding-bottom: max(min(66.7197452229%, 9999px), 0px);
}

.text-image-section__image img, .text-image-section__image svg, .text-image-section__image video, .text-image-section__image iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.text-image-section__image {
  width: 49.22%;
  border-radius: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  border: 1px solid var(--light-line-i);
  overflow: hidden;
}

.text-image-section__image-container {
  width: 49.22%;
  border-radius: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  border: 1px solid var(--light-line-i);
  overflow: hidden;
  position: relative;
}

.text-image-section__image-container .text-image-section__image {
  width: 100%;
  z-index: 1;
  border: none;
}

.text-image-section__bg {
  position: absolute;
  inset: 0;
}

.text-image-section__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.text-image-section__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 46.39%;
}

.text-image-section__subtitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 9 * (100vw - 768px) / 672, 23px);
}

.text-image-section__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.text-image-section__additional {
  padding: 24px clamp(20px, 20px + 11 * (100vw - 768px) / 672, 31px);
  border: 1px solid var(--light-line-ii);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: clamp(12px, 12px + 8 * (100vw - 768px) / 672, 20px);
}

.text-image-section__additional:not(.pink-bg) {
  padding-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.text-image-section__additional.pink-bg {
  background: var(--pink);
  color: var(--white);
}

.text-image-section__additional:not(:last-child) {
  margin-bottom: 8px;
}

.text-image-section__additional-title:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.text-image-section__additional-list {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  width: 100%;
}

.text-image-section__additional-item {
  width: 100%;
  padding-left: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  position: relative;
  color: var(--black);
}

.text-image-section__additional-item:before {
  left: 0;
  width: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  height: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  top: 50%;
  transform: translateY(-60%);
  content: "";
  border: 1px solid var(--rose);
  border-radius: 50%;
  display: block;
  position: absolute;
}

.text-image-section__additional-item:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

@media not all and (min-width: 640px) {
  .text-image-section .text-image-section__wrap {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .text-image-section .text-image-section__content {
    width: 100% !important;
  }
  .text-image-section .text-image-section__content:not(:last-child) {
    margin-bottom: 24px;
  }
  .text-image-section .text-image-section__image, .text-image-section .text-image-section__image-container {
    width: 100% !important;
  }
}
@media (min-width: 640px) {
  .text-image-section.not-reverse-type .text-image-section__wrap {
    flex-direction: row;
  }
}
.text-image-section.not-reverse-type.agp-page {
  background: var(--gray-bg);
  padding-bottom: clamp(50px, 50px + 6 * (100vw - 768px) / 672, 56px);
}

.text-image-section.not-reverse-type.agp-page .text-image-section__image-container {
  border-radius: 0;
}

.text-image-section.not-reverse-type.agp-page .text-image-section__image {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.text-image-section.not-reverse-type.agp-page .text-image-section__image::before {
  content: "";
  display: block;
  padding-bottom: max(min(65%, 9999px), 0px);
}

.text-image-section.not-reverse-type.agp-page .text-image-section__image img, .text-image-section.not-reverse-type.agp-page .text-image-section__image svg, .text-image-section.not-reverse-type.agp-page .text-image-section__image video, .text-image-section.not-reverse-type.agp-page .text-image-section__image iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

@media (min-width: 640px) {
  .text-image-section.not-reverse-type.agp-page .text-image-section__content {
    width: 49.22%;
  }
  .text-image-section.not-reverse-type.agp-page .text-image-section__image-container {
    width: 47.02%;
  }
}
.text-image-section.agp-benefits-type {
  padding-top: clamp(40px, 40px + 42 * (100vw - 768px) / 672, 82px);
  padding-bottom: clamp(40px, 40px + 42 * (100vw - 768px) / 672, 82px);
}

.text-image-section.agp-benefits-type .text-image-section__image {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.text-image-section.agp-benefits-type .text-image-section__image::before {
  content: "";
  display: block;
  padding-bottom: max(min(77.7089783282%, 9999px), 0px);
}

.text-image-section.agp-benefits-type .text-image-section__image img, .text-image-section.agp-benefits-type .text-image-section__image svg, .text-image-section.agp-benefits-type .text-image-section__image video, .text-image-section.agp-benefits-type .text-image-section__image iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

@media (min-width: 640px) {
  .text-image-section.agp-benefits-type .text-image-section__content {
    width: 44.98%;
  }
  .text-image-section.agp-benefits-type .text-image-section__image {
    width: 50.63%;
    /*border-radius: 0;*/
    border: none;
  }
}
.text-image-section.about-page {
  background: var(--gray-bg);
}

.stats-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background: var(--gray-bg);
  border-top: 1px solid var(--light-line-i);
}

@media (min-width: 768px) {
  .stats-section {
    border-left: 1px solid var(--light-line-i);
    border-right: 1px solid var(--light-line-i);
  }
}
.stats-section:after {
  position: absolute;
  inset: 0;
  content: "";
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}

.stats-section__wrap {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.stats-section__slider {
  width: 100%;
  max-width: clamp(320px, 320px + 105 * (100vw - 768px) / 672, 425px);
}

.stats-section__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  transition: border-color 0.3s ease;
  padding: clamp(40px, 40px + 12 * (100vw - 768px) / 672, 52px) clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  padding-top: clamp(40px, 40px + 20 * (100vw - 768px) / 672, 60px);
}

.stats-section__item.swiper-slide-active {
  border-color: var(--light-line-i);
}

.stats-section__item-count {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.stats-section__item-count .count-up {
  font-weight: 400;
  font-size: clamp(36px, 36px + 36 * (100vw - 768px) / 672, 72px);
  line-height: clamp(43px, 43px + 37 * (100vw - 768px) / 672, 80px);
  letter-spacing: 0.02em;
}

.stats-section__item-count .suffix {
  font-size: clamp(24px, 24px + 12 * (100vw - 768px) / 672, 36px);
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.stats-section__item-count:not(:last-child):not(:last-child) {
  margin-bottom: clamp(0px, 4px + -4 * (100vw - 768px) / 672, 4px);
}

.stats-section__item-text {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: var(--black);
}

.our-mission-section {
  padding-top: clamp(40px, 40px + 50 * (100vw - 768px) / 672, 90px);
  padding-bottom: clamp(40px, 40px + 46 * (100vw - 768px) / 672, 86px);
  position: relative;
  overflow: hidden;
  z-index: 10;
  background: var(--pink);
  color: var(--white);
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

.our-mission-section__bg {
  inset: 0;
  position: fixed;
  z-index: 1;
}

.our-mission-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  max-width: clamp(950px, 950px + 150 * (100vw - 768px) / 672, 1100px);
  width: 100%;
  position: relative;
  z-index: 10;
}

.our-mission-section__title:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

.our-mission-section__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 10px;
}

.our-mission-section__content:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 6 * (100vw - 768px) / 672, 36px);
}

.our-mission-section__icon {
  --size: clamp(48px, calc(48px + 24.00 * ((100vw - 768px) / 672)), 72px);
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-mission-section__icon i {
  font-size: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
  color: var(--rose);
}

.our-mission-section__icon:first-child {
  margin-bottom: auto;
}

.our-mission-section__icon:last-child {
  margin-top: auto;
}

.our-mission-section__text {
  flex: 1;
  text-align: center;
}

@media (max-width: 640px) {
  .our-mission-section {
    margin-bottom: 0;
  }
  .our-mission-section .our-mission-section__content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .our-mission-section .our-mission-section__icon:first-child {
    margin-right: auto;
  }
  .our-mission-section .our-mission-section__icon:last-child {
    margin-left: auto;
  }
}
.programme-section {
  padding-top: clamp(30px, 30px + 90 * (100vw - 768px) / 672, 120px);
  padding-bottom: 0;
  overflow: hidden;
  border-bottom: 1px solid var(--light-line-i);
  background: var(--gray-bg);
}

.programme-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.programme-section__subtitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

.programme-section__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
}

.programme-section__text {
  width: 100%;
  max-width: clamp(500px, 500px + 128 * (100vw - 768px) / 672, 628px);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.programme-section__text:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 22 * (100vw - 768px) / 672, 52px);
}

.programme-section__list {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.programme-section__item {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding-top: clamp(24px, 24px + 36 * (100vw - 768px) / 672, 60px);
  padding-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
  z-index: 1;
  color: var(--white);
  height: auto;
}

@media (min-width: 769px) {
  .programme-section__item:first-child {
    padding-right: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
  }
  .programme-section__item:first-child .programme-section__item-bg {
    left: clamp(-82px, -50px + -32 * (100vw - 768px) / 672, -50px);
    border-radius: 0 var(--radius) var(--radius) 0;
  }
}
@media (min-width: 769px) {
  .programme-section__item:last-child {
    padding-left: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
  }
  .programme-section__item:last-child .programme-section__item-bg {
    right: clamp(-82px, -50px + -32 * (100vw - 768px) / 672, -50px);
    border-radius: var(--radius) 0 0 var(--radius);
  }
}
.programme-section__item-bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  --radius: clamp(30px, calc(30px + 18.00 * ((100vw - 768px) / 672)), 48px);
  overflow: hidden;
}

.programme-section__item-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.programme-section__item-title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.programme-section__item-text {
  letter-spacing: 0.009em;
}

.programme-section__item-text:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 15 * (100vw - 768px) / 672, 45px);
}

.programme-section__item-buttons {
  margin-top: auto;
}

@media (max-width: 768px) {
  .programme-section .programme-section__list {
    flex-direction: column;
    justify-content: flex-start;
  }
  .programme-section .programme-section__item {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .programme-section .programme-section__item:first-child .programme-section__item-bg {
    border-radius: var(--radius) var(--radius) 0 0;
  }
  .programme-section .programme-section__item:last-child .programme-section__item-bg {
    border-radius: 0 0 var(--radius) var(--radius);
  }
  .programme-section .programme-section__item-bg {
    left: -20px;
    right: -20px;
  }
}
.application-section {
  padding-top: clamp(30px, 30px + 52 * (100vw - 768px) / 672, 82px);
  padding-bottom: clamp(30px, 30px + 52 * (100vw - 768px) / 672, 82px);
  background: var(--gray-bg);
}

.application-section__container {
  background: var(--white);
  overflow: hidden;
  border-radius: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border: 1px solid var(--light-line-ii);
}

.application-section__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 48.98%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: clamp(20px, 20px + 44 * (100vw - 768px) / 672, 64px);
  padding-right: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
}

.application-section__subtitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

.application-section__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.application-section__text:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

.application-section__list {
  width: 51.02%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: clamp(20px, 20px + 44 * (100vw - 768px) / 672, 64px);
  position: relative;
  overflow: hidden;
  background: var(--pink);
}

.application-section__list-bg {
  position: absolute;
  inset: 0;
  mix-blend-mode: luminosity;
}

.application-section__list-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.application-section__list-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--black);
  padding: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px) clamp(15px, 15px + 25 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 70 * (100vw - 768px) / 672, 90px);
  position: relative;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  border-radius: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
}

.application-section__list-title {
  width: 100%;
  padding-bottom: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  border-bottom: 1px solid var(--pink);
}

.application-section__list-title:not(:last-child) {
  margin-bottom: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
}

.application-section__list-ol {
  list-style-type: none;
  counter-reset: num;
  display: grid;
}

.application-section__list-ol li {
  position: relative;
  padding-left: clamp(24px, 24px + 28 * (100vw - 768px) / 672, 52px);
}

.application-section__list-ol li:before {
  position: absolute;
  color: var(--pink);
  top: clamp(2px, 4px + -2 * (100vw - 768px) / 672, 4px);
  left: clamp(-2px, -2px + 6 * (100vw - 768px) / 672, 4px);
  font-size: clamp(16px, 16px + 12 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
  font-weight: 400;
  content: "0" counter(num);
  counter-increment: num;
}

.application-section__list-ol li:nth-child(n+10):before {
  content: counter(num);
}

.application-section__list-ol li:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

@media (max-width: 768px) {
  .application-section .application-section__container {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
  .application-section .application-section__content {
    width: 100%;
  }
  .application-section .application-section__list {
    width: 100%;
  }
}
.faq-section {
  padding-top: clamp(40px, 40px + 42 * (100vw - 768px) / 672, 82px);
  padding-bottom: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  overflow: hidden;
}

.faq-section__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.faq-section__subtitle:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

.faq-section__title {
  width: 100%;
  max-width: clamp(400px, 400px + 228 * (100vw - 768px) / 672, 628px);
}

.faq-section__title:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
}

.faq-section__accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  grid-row-gap: clamp(8px, 8px + 8 * (100vw - 768px) / 672, 16px);
  transition: min-height 0.4s ease;
}

.faq-section__item {
  border-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  border: 1px solid var(--light-line-ii);
  padding: clamp(19px, 19px + 4 * (100vw - 768px) / 672, 23px) 19px clamp(19px, 19px + 4 * (100vw - 768px) / 672, 23px) clamp(19px, 19px + 12 * (100vw - 768px) / 672, 31px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--gray-bg);
  transition: background-color 0.4s ease, box-shadow 0.3s ease, padding 0.4s ease;
}

.faq-section__item.active .faq-section__item-top {
  padding-bottom: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  margin-bottom: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  margin-top: clamp(0px, 0px + 8 * (100vw - 768px) / 672, 8px);
}

.faq-section__item.active .faq-section__item-top:before {
  opacity: 1;
}

.faq-section__item.active .faq-section__item-btn {
  border-color: var(--black);
}

.faq-section__item.active .faq-section__item-btn i {
  color: var(--black);
}

.faq-section__item.active .faq-section__item-btn i.icon-plus {
  opacity: 0;
}

.faq-section__item.active .faq-section__item-title {
  color: var(--purple);
}

.faq-section__item.active .faq-section__item-content {
  opacity: 1;
}

.faq-section__item:not(.active) {
  cursor: pointer;
}

.faq-section__item:not(.active):hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.faq-section__item:not(.active) .faq-section__item-content {
  max-height: 0 !important;
}

.faq-section__item:not(.active) .faq-section__item-btn i.icon-minus {
  opacity: 0;
}

.faq-section__item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding-bottom 0.4s ease, margin 0.4s ease;
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
}

.faq-section__item-top:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--light-line-i);
  opacity: 0;
  content: "";
  transition: opacity 0.4s ease;
}

.faq-section__item-btn {
  --size: clamp(32px, calc(32px + 8.00 * ((100vw - 768px) / 672)), 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  border: 1px solid var(--rose);
  transition: all 0.4s ease;
  border-radius: 6px;
  position: relative;
}

.faq-section__item-btn i {
  font-size: 10px;
  transition: all 0.4s ease;
  color: var(--rose);
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(1);
  z-index: 1;
}

.faq-section__item-btn:hover {
  border-color: var(--purple);
}

.faq-section__item-btn:hover i {
  color: var(--purple);
  transform: translateX(-50%) translateY(-50%) scale(1.2);
}

.faq-section__item-title {
  max-width: calc(100% - 50px);
  /*font-size: ac(22px, 20px);
  line-height: 145.45%;
  font-family: var(--font-main);
  font-weight: 600;*/
  transition: all 0.3s ease;
  color: var(--black);
}

.faq-section__item-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /*max-height: 0;*/
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.4s ease;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.faq-section__item-text {
  width: 100%;
  margin-bottom: 8px;
}

.faq-section__item-text a {
  display: inline-block;
  color: var(--midnight);
  transition: all 0.3s ease;
}

.faq-section__item-text a:hover {
  color: var(--pink);
}

.agp-hero {
  padding-top: var(--header-height);
  position: relative;
  overflow: hidden;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

.agp-hero__bg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.agp-hero__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.agp-hero__bg:after {
  position: absolute;
  inset: 0;
  z-index: 1;
  content: "";
  background: rgba(86, 11, 117, 0.51);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.agp-hero__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}

.agp-hero__content {
  padding-top: clamp(0px, 0px + 4 * (100vw - 768px) / 672, 4px);
  width: 54.23%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: var(--white);
}

.agp-hero__title {
  text-transform: none !important;
}

.agp-hero__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
}

@media (max-width: 451px) {
  .agp-hero__title br {
    display: none;
  }
}
.agp-hero__text {
  width: 100%;
  max-width: clamp(400px, 400px + 174 * (100vw - 768px) / 672, 574px);
}

.agp-hero__text:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
}

.agp-hero__images {
  aspect-ratio: 1/1;
  position: relative;
  width: 42.63%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  grid-row-gap: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

.agp-hero__image {
  --radius: clamp(20px, calc(20px + 10.00 * ((100vw - 768px) / 672)), 30px);
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.agp-hero__image::before {
  content: "";
  display: block;
  padding-bottom: max(min(100%, 9999px), 0px);
}

.agp-hero__image img, .agp-hero__image svg, .agp-hero__image video, .agp-hero__image iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.agp-hero__image {
  overflow: hidden;
}

.agp-hero__image:nth-child(1) {
  border-radius: 50% 0 var(--radius) 0;
}

.agp-hero__image:nth-child(2) {
  border-radius: 50%;
}

.agp-hero__image:nth-child(3) {
  border-radius: 0 0 0 50%;
}

.agp-hero__image:nth-child(4) {
  border-radius: 0 0 0 50%;
}

.agp-hero__image:nth-child(5) {
  border-radius: 0 50% 0 0;
}

.agp-hero__image:nth-child(6) {
  border-radius: 50%;
}

.agp-hero__image:nth-child(7) {
  border-radius: 50% 0 0 0;
}

.agp-hero__image:nth-child(8) {
  border-radius: 50% 0 0 0;
}

.agp-hero__image:nth-child(9) {
  border-radius: 0 0 50% 0;
}

@media (max-width: 768px) {
  .agp-hero .agp-hero__wrap {
    padding-top: 64px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .agp-hero .agp-hero__content {
    width: 100%;
  }
  .agp-hero .agp-hero__content:not(:last-child) {
    margin-bottom: 40px;
  }
  .agp-hero .agp-hero__images {
    width: 100%;
  }
}
@media (max-width: 410px) {
  .agp-hero .agp-hero__buttons .btn {
    font-size: 12px;
    min-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 340px) {
  .agp-hero .agp-hero__buttons .btn {
    letter-spacing: 0;
  }
  .agp-hero .agp-hero__images {
    gap: 8px;
  }
}
.job-search-section {
  padding-top: var(--header-height);
  padding-bottom: clamp(40px, 40px + 46 * (100vw - 768px) / 672, 86px);
  overflow: visible;
  position: relative;
  z-index: 20;
}

.job-search-section__hero {
  position: relative;
  z-index: 5;
  color: var(--white);
}

.job-search-section__hero:not(:last-child) {
  margin-bottom: clamp(40px, 40px + 52 * (100vw - 768px) / 672, 92px);
}

.job-search-section__hero-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: clamp(64px, 64px + 64 * (100vw - 768px) / 672, 128px);
  padding-bottom: clamp(48px, 48px + 72 * (100vw - 768px) / 672, 120px);
  position: relative;
  z-index: 1;
}

.job-search-section__hero-wrap .job-search-section__title {
  width: 100%;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
}

.job-search-section__hero-wrap .job-search-section__title:not(:last-child) {
  margin-bottom: 16px;
}

.job-search-section__hero-wrap .default-custom-select .meta-checkbox__text {
  transition: color;
  color: var(--black);
}

.job-search-section__hero-wrap .default-custom-select .meta-checkbox__text:hover {
  color: var(--purple);
}

.job-search-section__hero-bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  background: linear-gradient(90deg, #560b75 0%, #8000b3 100%);
}

.job-search-section__hero-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.job-search-section__subtitle {
  width: 100%;
  text-align: center;
}

.job-search-section__subtitle:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

@media (max-width: 640px) {
  .job-search-section__subtitle br {
    display: none;
  }
}
.job-search-section__title {
  width: 73.75%;
  margin-right: auto;
}

.job-search-section__title:not(:last-child) {
  margin-bottom: clamp(34px, 34px + 20 * (100vw - 768px) / 672, 54px);
}

@media not all and (min-width: 1025px) {
  .job-search-section__title {
    width: 100%;
  }
}
.job-search-section__form {
  width: 100%;
  max-width: 956px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  grid-row-gap: 24px;
  position: relative;
  z-index: 20;
}

.job-search-section__form:not(:last-child) {
  margin-bottom: clamp(50px, 50px + 54 * (100vw - 768px) / 672, 104px);
}

@media not all and (min-width: 1025px) {
  .job-search-section__form {
    width: 100%;
  }
}
@media not all and (min-width: 451px) {
  .job-search-section__form .btn {
    width: 100%;
  }
}
.job-search-section__form-grid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: 16px;
  grid-row-gap: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.job-search-section__form-grid .meta-input {
  width: 100%;
  max-width: 367px;
}

@media (min-width: 1100px) {
  .job-search-section__form-grid .meta-input:first-child {
    max-width: 468px;
  }
}
@media (max-width: 640px) {
  .job-search-section__form-grid .meta-input {
    width: 100%;
    max-width: 100%;
  }
}
.job-search-section__form-grid .default-custom-select {
  width: 100%;
  max-width: 228px;
}

@media (min-width: 641px) {
  .job-search-section__form-grid .default-custom-select.location-type {
    max-width: 367px;
  }
}
@media (max-width: 640px) {
  .job-search-section__form-grid .default-custom-select {
    width: 100%;
    max-width: 100%;
  }
}
.job-search-section__form-grid .single-range {
  padding-right: 15px;
  margin-bottom: 0;
  height: 2px;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: var(--lavender);
}

.job-search-section__form-grid .single-range .noUi-connect {
  background: var(--white);
  margin-left: -1px;
}

.job-search-section__form-grid .single-range .noUi-handle {
  background: var(--lavender);
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid var(--white);
  box-shadow: none;
  top: -8px;
  transition: all 0.25s ease;
}

.job-search-section__form-grid .single-range .noUi-handle:before, .job-search-section__form-grid .single-range .noUi-handle:after {
  content: none;
}

.job-search-section__form-grid .single-range .noUi-handle:hover {
  border-color: var(--light-line-i);
}

.job-search-section__form-grid .single-range-info {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 72px;
}

.job-search-section__form-grid .single-range-container {
  padding-left: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
  padding-right: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
  display: flex;
  align-items: center;
  flex: 1;
}

.job-search-section__form-grid .single-range-container .single-range {
  flex: 1;
  order: 2;
}

.job-search-section__form-grid .single-range-container .single-range-info {
  order: 1;
  display: flex;
}

@media (max-width: 901px) {
  .job-search-section__form-grid .single-range-container {
    width: 50%;
    flex: initial;
  }
}
@media (max-width: 640px) {
  .job-search-section__form-grid .single-range-container {
    width: 100%;
    max-width: 100%;
    min-height: 16px;
  }
}
.job-search-section__form-grid .btn {
  border-color: var(--white);
}

.job-search-section__form-grid .btn:hover {
  border-color: var(--pink);
}

@media not all and (min-width: 851px) {
  .job-search-section__form-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media not all and (min-width: 451px) {
  .job-search-section__form-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.job-search-section__wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.job-search-section__title-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 14px;
}

.job-search-section__title-block .job-search-section__title {
  width: auto;
  margin-right: 0;
  margin-right: initial;
}

.job-search-section__title-block .job-search-section__title:not(:last-child) {
  margin-bottom: 0;
}

.job-search-section__title-block:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.job-search-section__title-block .meta-select {
  min-width: 216px;
}

.job-search-section__sort-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  grid-row-gap: 10px;
}

@media (min-width: 1280px) {
  .job-search-section__sort-container .meta-select:nth-child(2):last-child {
    min-width: 254px;
  }
}
.job-search-section__selected-filters {
  width: 100%;
  border-radius: 100000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--light-line-i);
  background: var(--gray-bg);
  padding: clamp(20px, 20px + 3 * (100vw - 768px) / 672, 23px);
  padding-left: clamp(20px, 20px + 11 * (100vw - 768px) / 672, 31px);
}

.job-search-section__selected-filters:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

@media (max-width: 951px) {
  .job-search-section__selected-filters {
    border-radius: 30px;
  }
}
@media (max-width: 790px) {
  .job-search-section__selected-filters {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 20px;
  }
}
.job-search-section__selected-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
  max-width: calc(100% - 200px);
}

@media (max-width: 790px) {
  .job-search-section__selected-list {
    max-width: 100%;
  }
}
.job-search-section__selected-item {
  cursor: pointer;
  background: var(--white);
  border-radius: 4px;
  border: 1px solid var(--light-line-i);
  min-height: clamp(20px, 20px + 1 * (100vw - 768px) / 672, 21px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px clamp(7px, 7px + 4 * (100vw - 768px) / 672, 11px);
  transition: all 0.3s ease;
  grid-column-gap: clamp(8px, 8px + 3 * (100vw - 768px) / 672, 11px);
}

.job-search-section__selected-item span {
  font-size: 14px;
  font-weight: 500;
  color: #49454f;
  transform: translateY(1px);
}

.job-search-section__selected-item i {
  font-size: 11px;
  color: var(--pink);
  transition: all 0.3s ease;
}

.job-search-section__selected-item:hover {
  border-color: var(--rose);
}

.job-search-section__selected-item:hover i {
  color: #49454f;
}

.job-search-section__container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  flex-direction: row-reverse;
}

.job-search-section__sidebar {
  flex: 1;
  max-width: 27.43%;
}

@media not all and (min-width: 1300px) {
  .job-search-section__sidebar {
    width: 26%;
  }
}
.job-search-section__sidebar-filters-title {
  padding-top: clamp(40px, 40px + 51 * (100vw - 768px) / 672, 91px);
  width: 100%;
  margin-bottom: clamp(24px, 24px + 18 * (100vw - 768px) / 672, 42px);
  position: relative;
}

@media (max-width: 640px) {
  .job-search-section__sidebar-filters-title {
    padding-top: 0;
    padding-right: 45px;
  }
}
.job-search-section__sidebar-filters-title .btn-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 5;
}

@media (min-width: 641px) {
  .job-search-section__sidebar-filters-title .btn-close {
    display: none !important;
  }
}
.job-search-section__sidebar-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.job-search-section__sidebar-item .btn-show-hide {
  margin-top: clamp(12px, 12px + 3 * (100vw - 768px) / 672, 15px);
}

.job-search-section__sidebar-item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding-bottom: 15px;
}

.job-search-section__sidebar-item-content {
  /*padding-top: 6px;*/
  transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  /*grid-column-gap: 10px;
  grid-row-gap: 9px;*/
  overflow: hidden;
}

.job-search-section__sidebar-item-content .double-range-container {
  width: 100%;
  max-width: 251px;
}

.job-search-section__sidebar-item-content.with-inputs {
  grid-row-gap: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  overflow: visible;
}

.job-search-section__sidebar-item-content.with-inputs .meta-select, .job-search-section__sidebar-item-content.with-inputs .meta-input {
  width: 100%;
}

.job-search-section__sidebar-item-content .meta-checkbox:not(:last-child) {
  margin-bottom: 4px;
}

.job-search-section__sidebar-item-content .meta-checkbox.is-hidden {
  display: none;
}

.job-search-section__sidebar-item-subtitle {
  width: 100%;
  font-size: clamp(17px, 17px + 1 * (100vw - 768px) / 672, 18px);
  line-height: normal;
  font-weight: 500;
}

.job-search-section__sidebar-item-subtitle:not(:last-child) {
  padding-bottom: 5px;
}

.job-search-section__sidebar-item-additional {
  padding-top: 19px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: 21px;
  grid-row-gap: 9px;
  margin-bottom: -2px;
}

.job-search-section__sidebar-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  grid-row-gap: 8px;
  transition: min-height 0.4s ease;
}

.job-search-section__sidebar-accordion-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transition: all 0.4s ease;
}

.job-search-section__sidebar-accordion-item.active .job-search-section__sidebar-accordion-item-top {
  margin-bottom: 9px;
}

.job-search-section__sidebar-accordion-item.active .job-search-section__sidebar-accordion-item-btn {
  transform: scaleY(-1);
}

.job-search-section__sidebar-accordion-item.active .job-search-section__sidebar-accordion-item-content {
  opacity: 1;
}

.job-search-section__sidebar-accordion-item:not(.active) {
  cursor: pointer;
}

.job-search-section__sidebar-accordion-item:not(.active) .job-search-section__sidebar-accordion-item-content {
  max-height: 0 !important;
}

.job-search-section__sidebar-accordion-item:not(.active) .job-search-section__sidebar-accordion-item-btn {
  transform: scaleY(1);
}

.job-search-section__sidebar-accordion-item:not(:last-child) {
  margin-bottom: 5px;
}

.job-search-section__sidebar-accordion-item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding-bottom 0.4s ease, margin 0.4s ease;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 6px;
}

.job-search-section__sidebar-accordion-item-top:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--light-line-i);
  content: "";
}

.job-search-section__sidebar-accordion-item-title {
  max-width: calc(100% - 40px);
  color: var(--midnight);
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: clamp(24px, 24px + 3 * (100vw - 768px) / 672, 27px);
  letter-spacing: 0.01em;
  font-weight: 600;
}

.job-search-section__sidebar-accordion-item-btn {
  --size: clamp(34px, calc(34px + 2.00 * ((100vw - 768px) / 672)), 36px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
}

.job-search-section__sidebar-accordion-item-btn i {
  font-size: 8px;
  transition: all 0.4s ease;
  color: var(--midnight);
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.job-search-section__sidebar-accordion-item-btn:hover i {
  color: var(--rose);
  transform: translateX(-50%) translateY(-50%);
}

.job-search-section__sidebar-accordion-item-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /*max-height: 0;*/
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.4s ease;
  width: 100%;
}

.job-search-section__sidebar-accordion-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-row-gap: 4px;
  width: 100%;
}

.job-search-section__sidebar-accordion-item-list .meta-checkbox__text {
  transform: translateY(2px);
  color: #141d24;
}

.job-search-section__sidebar-buttons {
  padding-top: clamp(0px, 0px + 8 * (100vw - 768px) / 672, 8px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.job-search-section__sidebar-buttons .btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-search-section__sidebar-buttons .btn:not(.btn--pink) {
  border-color: var(--white);
}

.job-search-section__sidebar-buttons .btn:not(.btn--pink):hover {
  border-color: var(--rose);
}

.job-search-section__result {
  width: 66.14%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

@media not all and (min-width: 1300px) {
  .job-search-section__result {
    width: 67%;
  }
}
@media (max-width: 640px) {
  .job-search-section__result {
    position: relative;
  }
}
.job-search-section__result-bg {
  display: none;
}

@media (max-width: 640px) {
  .job-search-section__result-bg {
    display: block;
    position: absolute;
    inset: 0;
    z-index: 10;
    pointer-events: none;
  }
}
.job-search-section__result-grid {
  padding-top: clamp(0px, 0px + 16 * (100vw - 768px) / 672, 16px);
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  grid-row-gap: 20px;
}

@media not all and (min-width: 901px) {
  .job-search-section__result-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 640px) {
  .job-search-section__filters-btn {
    display: none !important;
  }
}
@media not all and (min-width: 1280px) {
  .job-search-section__sidebar {
    max-width: 30%;
  }
}
@media not all and (min-width: 769px) {
  .job-search-section__sidebar {
    max-width: 40%;
  }
  .job-search-section__result {
    width: 55%;
  }
}
@media not all and (min-width: 640px) {
  .job-search-section {
    overflow: hidden;
  }
  .job-search-section__container {
    flex-direction: row;
  }
  .job-search-section__sidebar {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    opacity: 0;
    max-width: 280px;
    z-index: 2;
    transition: all 0.4s ease;
  }
  @media not all and (min-width: 374px) {
    .job-search-section__sidebar {
      max-width: 100%;
    }
  }
  .job-search-section__result {
    width: 100%;
    transition: all 0.3s ease;
    transform: translateX(0);
    opacity: 1;
    position: relative;
  }
  .job-search-section__filters-btn {
    display: flex;
    margin-right: auto;
    margin-bottom: 24px;
  }
  .job-search-section__container.active .job-search-section__sidebar {
    position: relative;
    opacity: 1;
    transform: translateX(0);
  }
  .job-search-section__container.active .job-search-section__result {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(305px);
    opacity: 0.4;
  }
  .job-search-section__container.active .job-search-section__result > * {
    pointer-events: none;
  }
  @media not all and (min-width: 374px) {
    .job-search-section__container.active .job-search-section__result {
      transform: translateX(110%);
    }
  }
  .job-search-section__container.active .job-search-section__result-bg {
    pointer-events: auto;
  }
}
.hero-about {
  display: flex;
}

.hero-about__wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 50px 0;
}

@media (max-width: 1024px) and (min-height: 820px) {
  .hero-about {
    min-height: 800px;
    height: auto;
  }
}
.hero-about__thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--midnight);
}

.hero-about__thumbnail__wrapper, .hero-about__thumbnail__bg {
  width: 81.25%;
  max-width: 1170px;
  position: absolute;
  left: 57%;
  top: 0;
  height: 100%;
  z-index: 2;
}

.hero-about__thumbnail__wrapper img, .hero-about__thumbnail__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 26% bottom;
  object-position: 26% bottom;
  transition: 0.3s;
}

.hero-about__thumbnail__bg {
  z-index: 0;
}

.hero-about__thumbnail__figure {
  position: absolute;
  left: 0;
  top: 0;
  width: 85.7638888889%;
  max-width: 2140px;
  height: 100%;
  z-index: 1;
}

.hero-about__thumbnail__figure img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: right center;
  object-position: right center;
  transition: 0.3s;
}

@media (max-width: 1920px) {
  .hero-about__thumbnail__wrapper, .hero-about__thumbnail__bg {
    left: 50%;
  }
}
@media (max-width: 1700px) {
  .hero-about__thumbnail__wrapper, .hero-about__thumbnail__bg {
    left: 43%;
  }
  .hero-about__thumbnail__figure {
    left: clamp(0px, 0px + 90 * (100vw - 1440px) / 160, 90px);
  }
}
@media (max-width: 1500px) {
  .hero-about__thumbnail__wrapper, .hero-about__thumbnail__bg {
    left: 46%;
  }
  .hero-about__thumbnail__wrapper img, .hero-about__thumbnail__bg img {
    -o-object-position: left center;
    object-position: left center;
  }
}
.hero-about__description {
  letter-spacing: 0.01em;
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.5;
}

.hero-about__description p:first-of-type {
  font-size: clamp(22px, 22px + 6 * (100vw - 768px) / 672, 28px);
  line-height: 1.2858;
  margin-bottom: 26px;
  letter-spacing: 0.02em;
}

.hero-about .hero__title {
  margin-bottom: 24px;
  line-height: 104%;
}

.hero__caption {
  width: 100%;
  max-width: clamp(442px, 442px + 130 * (100vw - 992px) / 448, 572px);
  position: relative;
  z-index: 4;
}

.aspirational.aspirational-about {
  padding-bottom: clamp(45px, 45px + 40 * (100vw - 768px) / 672, 85px);
}

.aspirational.aspirational-about::before {
  height: 46.7%;
}

.aspirational.aspirational-about .aspirational__wrapper {
  gap: clamp(20px, 20px + 36 * (100vw - 768px) / 672, 56px);
  border: 1px solid #E7E8E9;
}

.aspirational.aspirational-about .aspirational-thumbnail {
  background: var(--pink);
}

.aspirational.aspirational-about .aspirational .sup {
  margin-top: -3px;
}

.aspirational.aspirational-about .aspirational__col {
  justify-content: center;
  padding-top: clamp(20px, 20px + 15 * (100vw - 768px) / 672, 35px);
}

.aspirational.aspirational-about .aspirational__title {
  padding-right: 0;
}

.aspirational.aspirational-about .aspirational__scroll {
  --fz: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  margin-top: 25px;
}

.vision {
  padding: clamp(75px, 75px + 15 * (100vw - 375px) / 1065, 90px) 0 50px;
  position: relative;
  transform: translateY(0);
  background: var(--white);
}

.vision::before {
  content: "";
  display: block;
  width: 100%;
  height: 58%;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--gray-bg);
  z-index: -1;
  pointer-events: none;
}

.vision-swiper-wr .swiper-slide {
  display: flex;
  align-items: center;
  height: auto;
  min-height: 242px;
}

.vision .slider-btn {
  margin-top: 45px;
}

.vision-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  align-items: center;
  background: var(--white);
  border: 1px solid var(--light-line-ii);
  border-radius: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  padding: 24px 20px;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  color: var(--black);
}

.vision-card__icon {
  position: relative;
  overflow: hidden;
  width: 78px;
  margin: 0 auto;
  transition: margin-top 0.3s;
}

.vision-card__icon::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.vision-card__icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.vision-card__title {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.vision-card__description {
  --line-count: 0;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.48;
  padding-top: 0;
  text-align: center;
  width: 100%;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  transition: 0.2s;
  letter-spacing: 0.01em;
  color: var(--white);
}

.vision-card__description .simplebar-scrollable-y {
  padding-right: 5px;
}

.vision-card__description .simplebar-track.simplebar-vertical {
  opacity: 0;
  transform: translateX(10px);
}

.vision-card:hover {
  background: var(--midnight);
  color: var(--white);
  gap: 9.5px;
  padding-bottom: 32px;
  padding-top: 0;
}

.vision-card:hover .vision-card__icon {
  margin-top: -42px;
}

.vision-card:hover .vision-card__description {
  --line-count: 7;
  transition: 0.3s 0.1s;
}

.vision-card:hover .simplebar-scrollable-y .simplebar-track.simplebar-vertical {
  transition: 0.3s 0.5s !important;
  opacity: 1;
}

.ethos {
  padding: clamp(50px, 50px + 57 * (100vw - 768px) / 672, 107px) 0 clamp(50px, 50px + 70 * (100vw - 768px) / 672, 120px);
  background: var(--white);
}

.ethos .sup {
  margin-bottom: 17px;
}

.ethos .title {
  margin-bottom: 26px;
}

.ethos__motto {
  max-width: 652px;
  margin: 0 auto 48px;
  text-align: center;
}

.ethos-swiper-wr .swiper-slide {
  display: flex;
  height: auto;
}

@media (max-width: 992px) {
  .ethos-swiper-wr .swiper-slide {
    width: 350px;
    max-width: 100%;
  }
}
.ethos-card {
  width: 100%;
  --mt: 48px;
  margin-top: var(--mt);
  border: 1px solid var(--light-line-i);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px;
  color: var(--black);
}

.ethos-card__icon {
  width: 96px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background: var(--pink);
  border: 2px solid var(--rose);
  margin-bottom: 16px;
  margin-top: calc(var(--mt) * -1);
}

.ethos-card__icon::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.ethos-card__icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 69.57%;
  height: auto;
  max-height: 80%;
  -o-object-fit: contain;
  object-fit: contain;
  transition: 0.3s;
}

.ethos-card__title {
  margin-bottom: 12px;
  text-align: center;
}

.ethos-card__description {
  --line-count: 8;
  --line-height: 1.5;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  text-align: center;
  letter-spacing: 0.01em;
  font-weight: 400;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

.ethos .slider-btn {
  margin-top: 40px;
}

.values {
  padding: clamp(40px, 40px + 46 * (100vw - 768px) / 672, 86px) 0 56px;
  background: var(--pink);
  color: var(--white);
}

.values .sup {
  margin-bottom: clamp(14px, 14px + 3 * (100vw - 768px) / 672, 17px);
  color: var(--gray-bg);
}

.values .title {
  margin-bottom: clamp(40px, 40px + 17 * (100vw - 768px) / 672, 57px);
}

.values-swiper-wr .swiper-slide {
  display: flex;
  height: auto;
}

.values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  overflow: hidden;
  gap: 0 20px;
}

.values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
  border-left: 1px solid var(--rose);
}

.values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 1px;
  background: var(--rose);
}

.values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-of-type(3n) {
  border-right: 1px solid var(--rose);
}

.values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-last-of-type(1)::after, .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-last-of-type(2)::after, .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-last-of-type(3)::after {
  display: none;
}

.values-swiper-wr .swiper:not(.swiper-initialized) + .swiper-buttons {
  display: none;
}

.values-swiper-wr .swiper.swiper-initialized .swiper-slide {
  border: 1px solid var(--rose);
}

.values-swiper-wr .swiper-buttons {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.values-swiper-wr .swiper-buttons .slider-btn {
  margin-top: 45px;
}

.values-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px) clamp(15px, 15px + 11 * (100vw - 768px) / 672, 26px) clamp(25px, 25px + 15 * (100vw - 768px) / 672, 40px) clamp(15px, 15px + 17 * (100vw - 768px) / 672, 32px);
}

.values-card__header {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 17px;
}

.values-card__icon {
  width: 72px;
  height: 72px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.values-card__icon img {
  max-width: 95%;
  max-height: 95%;
}

.values-card__description {
  width: 100%;
  --line-count: 7;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

.values.jobs-search-page {
  padding-bottom: clamp(40px, 40px + 46 * (100vw - 768px) / 672, 86px);
}

.values.jobs-search-page .sup {
  margin-bottom: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

.values.jobs-search-page .title {
  text-align: center;
  max-width: clamp(400px, 400px + 228 * (100vw - 768px) / 672, 628px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(40px, 40px + 13 * (100vw - 768px) / 672, 53px);
}

/*@media (min-width: 1400px) {
          .values-card {
            padding-top: 37px;
            padding-left: 30px;
          }
        }*/
.values.jobs-search-page .values-card__icon {
  position: relative;
  width: clamp(72px, 72px + 24 * (100vw - 768px) / 672, 96px);
  height: clamp(72px, 72px + 24 * (100vw - 768px) / 672, 96px);
}

.values.jobs-search-page .values-card__icon:after {
  inset: 0;
  border-radius: 50%;
  background: var(--rose);
  content: "";
  position: absolute;
}

.values.jobs-search-page .values-card__icon img {
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  position: relative;
}

.values.jobs-search-page .values-card__header {
  margin-bottom: 15px;
}

@media (min-width: 1360px) {
  .values.jobs-search-page .values-card__description {
    --fz: 20px ;
  }
}
.values.jobs-search-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  overflow: hidden;
}

.values.jobs-search-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
  border-right: 1px solid var(--rose);
  border-left: 1px solid var(--rose);
}

.values.jobs-search-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-child(2n+1) {
  border-right: none;
}

.values.jobs-search-page .values-swiper-wr .swiper:not(.swiper-initialized) + .swiper-buttons {
  display: none;
}

.values.agp-page {
  background-color: var(--gray-bg);
  padding-bottom: 0;
  position: relative;
  color: var(--black);
}

.values.agp-page .sup {
  margin-bottom: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
}

@media (min-width: 1400px) {
  .values.agp-page .values-card {
    padding-top: 37px;
    padding-left: 30px;
  }
}
@media (min-width: 1400px) {
  .values.agp-page .values-swiper-wr .swiper-slide:nth-child(5):last-child .values-card {
    padding-bottom: clamp(60px, 60px + 57 * (100vw - 768px) / 672, 117px);
  }
}
.values.agp-page .values-swiper-wr .swiper-slide:nth-child(1), .values.agp-page .values-swiper-wr .swiper-slide:nth-child(4) {
  border-left: none;
}

.values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 0 clamp(15px, 15px + 5 * (100vw - 768px) / 672, 20px);
}

.values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
  width: calc(33% - 10px);
  border-color: var(--light-line-i);
}

.values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide::after {
  background: var(--light-line-i);
}

.values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-of-type(3n) {
  border-right: var(--light-line-i);
}

.values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-last-of-type(1)::after, .values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-last-of-type(2)::after, .values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide:nth-last-of-type(3)::after {
  display: none;
}

.values.agp-page .values-swiper-wr .swiper:not(.swiper-initialized) + .swiper-buttons {
  display: none;
}

.values.agp-page .values-swiper-wr .swiper.swiper-initialized .swiper-slide {
  border-color: var(--light-line-i);
}

.values.agp-page .values-swiper-wr .swiper-buttons {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.values.agp-page .values-swiper-wr .swiper-buttons .slider-btn {
  margin-top: 45px;
}

.values.agp-page .values__decor {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.values.agp-page .values__decor::before {
  content: "";
  display: block;
  padding-bottom: max(min(100%, 9999px), 0px);
}

.values.agp-page .values__decor img, .values.agp-page .values__decor svg, .values.agp-page .values__decor video, .values.agp-page .values__decor iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.values.agp-page .values__decor {
  position: absolute;
  left: 0;
  bottom: 0;
  width: clamp(80px, 80px + 84 * (100vw - 768px) / 672, 164px);
  opacity: 0.5;
}

.values.agp-page .values__decor:after {
  inset: 0;
  z-index: 1;
  content: "";
  background: #fbfbfb;
  mix-blend-mode: color;
  position: absolute;
}

.values.agp-page .values__decor:last-child {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}

@media (max-width: 1023px) {
  .values.agp-page {
    padding-bottom: 40px;
  }
}
.journey {
  padding: clamp(50px, 50px + 67 * (100vw - 768px) / 672, 117px) 0 clamp(60px, 60px + 55 * (100vw - 768px) / 672, 115px);
  /*transform: translate(0);*/
  position: relative;
  color: var(--white);
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

.journey .section-bg {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  transform: none;
  z-index: -1;
}

.journey .title-wr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
  align-items: flex-end;
  padding-bottom: clamp(40px, 40px + 18 * (100vw - 768px) / 672, 58px);
}

.journey-swiper-mask {
  -webkit-mask-image: linear-gradient(90deg, #d9d9d9 91.38%, rgba(115, 115, 115, 0) 100%);
  mask-image: linear-gradient(90deg, #d9d9d9 91.38%, rgba(115, 115, 115, 0) 100%);
  width: 100%;
}

.journey-swiper-wr {
  max-width: 100%;
}

.journey-swiper-wr .swiper {
  margin: 0;
}

.journey-swiper-wr .swiper.swiper-logo {
  padding-bottom: 29px;
  width: clamp(500px, 500px + 149 * (100vw - 768px) / 672, 649px);
}

.journey-swiper-wr .swiper.swiper-logo .swiper-slide {
  display: flex;
  height: auto;
  align-items: center;
}

.journey-swiper-wr .swiper.swiper-logo .swiper-slide:not(.swiper-slide-active) .journey-logo-card img {
  width: 28px;
}

.journey-swiper-wr .swiper.swiper-logo .swiper-slide:not(.swiper-slide-active) .journey-logo-card::after {
  height: 2px;
}

.journey-swiper-wr .swiper.swiper-logo .swiper-slide.swiper-slide-next .journey-logo-card img {
  width: 44px;
}

.journey-swiper-wr .swiper.swiper-logo .swiper-slide.swiper-slide-next .journey-logo-card::after {
  height: 2px;
}

.journey-swiper-wr .swiper.swiper-logo .swiper-slide:last-of-type .journey-logo-card:after {
  flex: 1 0 100vw;
}

.journey-swiper-wr .swiper.swiper-text {
  width: clamp(480px, 480px + 150 * (100vw - 768px) / 672, 630px);
  margin: 0;
}

@media (max-width: 850px) {
  .journey-swiper-wr .swiper {
    width: 100% !important;
  }
}
.journey-logo-card {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 88px;
}

.journey-logo-card::after {
  content: "";
  display: block;
  flex: 1;
  height: 4px;
  background: var(--white);
  transition: 0.3s;
}

.journey-logo-card img {
  width: clamp(79px, 79px + 10 * (100vw - 768px) / 672, 89px);
  height: auto;
  transition: 0.3s;
}

.journey-text-card {
  padding-right: clamp(15px, 15px + 18 * (100vw - 768px) / 672, 33px);
}

.journey-text-card__date {
  font-size: clamp(40px, 40px + 32 * (100vw - 768px) / 672, 72px);
  line-height: 1.111;
  margin-bottom: 22px;
  letter-spacing: 0.02em;
}

.journey-text-card__title {
  padding-right: 50px;
  margin-bottom: 16px;
}

.journey-text-card__description {
  --mb: 15px;
  --mb-count: 2;
  --line-count: 11;
  --fz: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  --line-height: 1.5;
  letter-spacing: 0.01em;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + var(--mb) * var(--mb-count));
}

.journey-text-card__description p {
  margin-bottom: var(--mb);
}

.journey-text-card__description p:last-of-type {
  margin-bottom: 0;
}

.journey-text-card__description .simplebar-track.simplebar-vertical {
  transform: translateX(10px);
}

.team {
  padding: clamp(45px, 45px + 75 * (100vw - 768px) / 672, 120px) 0 clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
  transform: translate(0);
  overflow: hidden;
  background: var(--gray-bg);
}

.team .bg-layer {
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.team .sup {
  margin-bottom: 17px;
}

.team .title {
  margin-bottom: 23px;
}

.team-caption {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 56px;
}

.team-caption__text {
  max-width: 572px;
}

.team-caption .swiper-buttons {
  margin-left: auto;
}

.team-swiper-wr .swiper-slide {
  width: clamp(375px, 375px + 37 * (100vw - 768px) / 672, 412px);
  max-width: 100%;
  display: flex;
  height: auto;
}

.teammate-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
  border: 1px solid var(--light-line-ii);
}

.teammate-card-thumbnail {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid var(--light-line-ii);
}

.teammate-card-thumbnail::before {
  content: "";
  display: block;
  padding-top: 80.097%;
}

.teammate-card-thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.teammate-card__text {
  width: 100%;
  padding: 24px clamp(20px, 20px + 9 * (100vw - 768px) / 672, 29px) 32px clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  background: var(--white);
  flex: 1;
  transition: 0.3s;
}

.teammate-card__name {
  margin-bottom: 8px;
  color: var(--black);
}

.teammate-card__position {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-weight: 600;
  line-height: 1.222;
  color: var(--pink);
  letter-spacing: 0.04em;
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: clamp(35px, 35px + 12 * (100vw - 768px) / 672, 47px);
}

.teammate-card__footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.teammate-card-socials {
  display: flex;
  gap: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.teammate-card-socials__link {
  font-size: 21px;
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  color: var(--purple);
  border-top: 1px solid var(--rose);
  border-bottom: 1px solid var(--rose);
  transition: 0.3s;
}

.teammate-card-socials__link:hover {
  color: var(--white);
  background: var(--pink);
}

.teammate-card-socials__link .icon-phone-button::before {
  font-size: 86%;
  transform: translateY(-1%);
  display: block;
}

.teammate-card-socials__link .icon-mail-button::before {
  font-size: 86%;
}

.teammate-card:hover:not(.team-popup__card) .teammate-card-thumbnail {
  border-color: var(--lavender);
}

.teammate-card:hover:not(.team-popup__card) .teammate-card__name {
  color: var(--white);
}

.teammate-card:hover:not(.team-popup__card) .teammate-card__text {
  background: var(--midnight);
  color: var(--white);
}

.teammate-card:hover:not(.team-popup__card) .teammate-card__position {
  color: var(--white);
}

.teammate-card:hover:not(.team-popup__card) .teammate-card-socials__link {
  color: var(--white);
}

.teammate-card:hover:not(.team-popup__card) .read-more-btn {
  color: var(--white);
}

.teammate-card:hover:not(.team-popup__card) .read-more-btn i {
  border-color: var(--white);
  color: var(--white);
}

.text-image-section.about-page {
  padding-bottom: 25px;
}

.text-image-section.about-page-say {
  padding: clamp(50px, 50px + 30 * (100vw - 768px) / 672, 80px) 0 clamp(32px, 32px + 50 * (100vw - 768px) / 672, 82px);
  background: var(--gray-bg);
}

@media (min-width: 640px) {
  .text-image-section.about-page-say .text-image-section__image {
    width: 646px;
    max-width: 51%;
  }
}
.text-image-section.about-page-say .text-image-section__image::before {
  padding-bottom: 77.7851736%;
}

@media (min-width: 1200px) {
  .text-image-section.about-page-say .text-image-section__content {
    width: 44.29%;
  }
}
.candidate-ts {
  padding: clamp(50px, 50px + 30 * (100vw - 768px) / 672, 80px) 0 80px;
  background: var(--white);
}

.candidate-ts .sup {
  margin-bottom: 20px;
}

.candidate-ts .title {
  margin-bottom: 34px;
}

.candidate-ts .swiper-buttons {
  padding-bottom: 56px;
}

.candidate-ts-swiper-wr .swiper-slide {
  display: flex;
  height: auto;
  width: clamp(370px, 370px + 258 * (100vw - 768px) / 672, 628px);
  max-width: 100%;
}

.candidate-ts-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-line-i);
  border-radius: clamp(12px, 12px + 20 * (100vw - 768px) / 672, 32px) clamp(12px, 12px + 20 * (100vw - 768px) / 672, 32px) 0 clamp(12px, 12px + 20 * (100vw - 768px) / 672, 32px);
  overflow: hidden;
  padding: 33px clamp(15px, 15px + 25 * (100vw - 768px) / 672, 40px) clamp(25px, 25px + 15 * (100vw - 768px) / 672, 40px);
  transform: translate(0);
  width: 100%;
}

.candidate-ts-card i {
  color: var(--lavender);
  display: block;
  margin-bottom: 17.5px;
  font-size: 28px;
  margin-left: 5px;
}

.candidate-ts-card__description {
  --line-count: 7;
  --fz: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  --line-height: 1.5;
  width: 100%;
  font-weight: 400;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  margin-bottom: 34px;
  color: var(--black);
}

.candidate-ts-card__decor {
  width: clamp(50px, 50px + 86 * (100vw - 1024px) / 416, 136px);
  position: absolute;
  right: 0;
  bottom: -1px;
  z-index: -1;
  opacity: 0.12;
}

.candidate-ts-card-footer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: auto;
  z-index: -1;
}

.candidate-ts-card__thumbnail {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 96px;
  border: 6px solid rgba(219, 80, 155, 0.5);
  flex-shrink: 0;
}

.candidate-ts-card__thumbnail::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.candidate-ts-card__thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.candidate-ts-card__name {
  margin-bottom: 4px;
}

.candidate-ts-card__position {
  color: var(--pink);
}

.clients-ts {
  padding: clamp(40px, 40px + 42 * (100vw - 768px) / 672, 82px) 0;
  background: var(--gray-bg);
}

.clients-ts .sup {
  margin-bottom: 20px;
}

.clients-ts .title {
  width: 100%;
  padding-bottom: 28px;
  border-bottom: 1px solid var(--light-line-i);
  margin-bottom: clamp(25px, 25px + 20 * (100vw - 768px) / 672, 45px);
}

.clients-ts-swiper-wr {
  padding-bottom: 28px;
  border-bottom: 1px solid var(--light-line-i);
}

.clients-ts-swiper-controls {
  display: flex;
  justify-content: space-between;
  gap: clamp(15px, 15px + 41 * (100vw - 768px) / 672, 56px);
  align-items: center;
  width: clamp(390px, 390px + 519 * (100vw - 651px) / 789, 909px);
  max-width: 100%;
  margin-left: auto;
}

.clients-ts-swiper-controls .swiper-scrollbar-wrapper {
  flex: 1;
}

.clients-ts-swiper-controls .swiper-scrollbar {
  width: 100%;
  flex: 1;
  position: relative;
  bottom: auto;
  left: auto;
  top: auto;
  right: auto;
  height: 4px;
}

.clients-ts-swiper-controls .swiper-scrollbar .swiper-scrollbar-drag {
  background: var(--lavender);
  cursor: pointer;
}

.clients-ts-swiper-controls .swiper-buttons {
  gap: 11px;
}

.clients-ts-swiper-controls .swiper-pagination {
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  min-width: 41px;
  display: flex;
  justify-content: space-between;
  gap: 2px;
  align-items: center;
  color: var(--light-line-i);
}

.clients-ts-swiper-controls .swiper-pagination .swiper-pagination-current {
  color: var(--black);
}

.clients-ts-swiper-controls .swiper-pagination span {
  color: var(--dark-gray);
}

.client-ts-card {
  display: flex;
  gap: clamp(25px, 25px + 39 * (100vw - 768px) / 672, 64px);
  padding-bottom: 56px;
  align-items: center;
}

.client-ts-card-intro {
  flex-shrink: 0;
  width: clamp(200px, 200px + 104 * (100vw - 768px) / 672, 304px);
  padding-top: clamp(20px, 20px + 51 * (100vw - 768px) / 672, 71px);
}

.client-ts-card__logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px;
  background: var(--white);
  border: 1px solid var(--light-line-ii);
  border-radius: clamp(10px, 10px + 10 * (100vw - 768px) / 672, 20px);
  margin-bottom: 20px;
  height: clamp(120px, 120px + 50 * (100vw - 768px) / 672, 170px);
}

.client-ts-card__logo img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.client-ts-card__name {
  margin-bottom: 8px;
  text-align: center;
  color: var(--black);
}

.client-ts-card__position {
  text-align: center;
}

.client-ts-card-text {
  flex: 1;
}

.client-ts-card i {
  font-size: clamp(25px, 25px + 15 * (100vw - 768px) / 672, 40px);
  margin-left: 9px;
  display: block;
  margin-bottom: clamp(20px, 20px + 18 * (100vw - 768px) / 672, 38px);
  color: var(--rose);
}

.client-ts-card__description {
  --line-count: 10;
  --fz: clamp(18px, calc(18px + 10.00 * ((100vw - 768px) / 672)), 28px);
  --line-height: 1.2857;
  letter-spacing: 0.02em;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

@media (max-width: 1200px) {
  .hero-about__thumbnail__wrapper, .hero-about__thumbnail__bg {
    left: 38%;
  }
}
@media (max-width: 992px) {
  .hero-about {
    min-height: calc(100 * var(--vh, 1vh));
    height: auto;
  }
  .hero-about__thumbnail {
    background: var(--purple);
  }
  .hero-about__thumbnail__wrapper, .hero-about__thumbnail__bg {
    left: 33%;
  }
  .hero-about__thumbnail__figure {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .hero-about__thumbnail {
    background: var(--purple);
  }
  .hero-about__thumbnail::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    background: var(--midnight);
    opacity: 0.5;
  }
  .hero-about__thumbnail__wrapper, .hero-about__thumbnail__bg {
    display: none;
  }
  .journey-swiper-mask {
    -webkit-mask-image: linear-gradient(90deg, #d9d9d9 98.38%, rgba(115, 115, 115, 0) 100%);
    mask-image: linear-gradient(90deg, #d9d9d9 98.38%, rgba(115, 115, 115, 0) 100%);
  }
}
@media (max-width: 650px) {
  .clients-ts-swiper-controls {
    width: 100%;
  }
  .client-ts-card {
    flex-direction: column;
  }
  .client-ts-card-intro {
    width: 375px;
    max-width: 100%;
  }
  .client-ts-card__logo {
    width: 200px;
    margin: 0 auto 20px;
  }
  .client-ts-card-text {
    width: 100%;
  }
}
.candidates-page .contact-us__title-box {
  padding-top: clamp(40px, 40px + 36 * (100vw - 768px) / 672, 76px);
  padding-bottom: clamp(40px, 40px + 38 * (100vw - 768px) / 672, 78px);
}

.hero-candidates {
  position: relative;
  height: calc(100 * var(--dvh, 1vh));
  display: flex;
}

.hero-candidates__wrapper {
  width: 100%;
  position: relative;
  align-items: center;
  display: flex;
  z-index: 0;
  padding: 30px 0 clamp(150px, 150px + 49 * (100vw - 375px) / 1065, 199px);
}

.hero-candidates .hero__title {
  margin-bottom: 20px;
}

.hero-candidates .hero__caption {
  max-width: clamp(420px, 420px + 157 * (100vw - 768px) / 672, 577px);
  z-index: 5;
}

.hero-candidates .hero__description {
  font-size: clamp(18px, 18px + 18 * (100vw - 768px) / 672, 36px);
  line-height: 1.333;
}

.hero-candidates .hero__btn-box {
  margin-top: clamp(20px, 20px + 29 * (100vw - 768px) / 672, 49px);
}

.hero-candidates__bg, .hero-candidates__thumbnail-bottom, .hero-candidates__thumbnail-front {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.hero-candidates__bg img, .hero-candidates__thumbnail-bottom img, .hero-candidates__thumbnail-front img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero-candidates__thumbnail-bottom {
  z-index: 1;
  opacity: 0.2;
}

.hero-candidates__thumbnail-front {
  z-index: 2;
}

.hero + .ects-banner-wrapper {
  padding-top: 1px;
}

.hero + .ects-banner-wrapper .banner {
  margin-top: clamp(-144px, -100px + -44 * (100vw - 768px) / 672, -100px);
}

.ects-banner-wrapper {
  background: var(--gray-bg);
}

.banner {
  z-index: 1;
}

.banner-content {
  width: 100%;
  border-radius: clamp(25px, 25px + 23 * (100vw - 768px) / 672, 48px);
  padding: clamp(30px, 30px + 34 * (100vw - 375px) / 1065, 64px) clamp(20px, 20px + 62 * (100vw - 375px) / 1065, 82px);
  font-size: clamp(22px, 22px + 6 * (100vw - 768px) / 672, 28px);
  position: relative;
  color: var(--white);
  overflow: hidden;
  transform: translateX(0);
}

.banner-content p {
  margin-bottom: 17px;
}

.banner-content p:last-of-type {
  margin-bottom: 2px;
}

.ects {
  padding: clamp(64px, 64px + 54 * (100vw - 768px) / 672, 118px) 0;
}

.ects .sup {
  margin-bottom: 17px;
}

.ects .title {
  margin-bottom: clamp(20px, 20px + 7 * (100vw - 768px) / 672, 27px);
}

.ects-wrapper {
  display: flex;
  gap: 60px clamp(25px, 25px + 31 * (100vw - 768px) / 672, 56px);
}

.ects-options {
  flex: 1 0 auto;
  flex-wrap: wrap;
  max-width: clamp(325px, 325px + 303 * (100vw - 650px) / 790, 628px);
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.ects-options-item {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background: var(--white);
  border: 1px solid var(--light-line-ii);
}

.ects-options-item__header {
  padding: clamp(21px, 21px + 7 * (100vw - 768px) / 672, 28px) clamp(15px, 15px + 7 * (100vw - 768px) / 672, 22px) clamp(20px, 20px + 7 * (100vw - 768px) / 672, 27px) clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: 0.3s;
  color: var(--black);
  cursor: pointer;
}

.ects-options-item__header.active {
  padding: 21px clamp(15px, 15px + 7 * (100vw - 768px) / 672, 22px) 20px clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.ects-options-item__header:hover {
  color: var(--pink);
}

.ects-options-item__content {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: 0.3s;
  padding: 0 clamp(20px, 20px + 10 * (100vw - 768px) / 672, 30px);
  border-top: 2px solid transparent;
}

.ects-options-item__content [data-simplebar] {
  --line-count: 4;
  --fz: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  opacity: 0;
  transition: none;
}

.ects-options-item__content.active {
  max-height: 200px;
  padding: 29px 30px 32px;
  border-color: var(--light-line-ii);
}

.ects-options-item__content.active [data-simplebar] {
  opacity: 1;
  transition: opacity 0.3s 0.2s;
}

.ects-text__content {
  --line-count: 7;
  --fz: 18px;
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  /* max-height: calc(var(--fz) * var(--line-height) * var(--line-count));*/
}

.ects-text p {
  margin-bottom: 12px;
}

.ects-text p:last-of-type {
  margin-bottom: 0;
}

.aspirational.aspirational-candidate {
  padding-bottom: clamp(32px, 32px + 34 * (100vw - 768px) / 672, 66px);
}

.aspirational.aspirational-candidate::before {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 55.8%;
  background: var(--white);
}

.aspirational.aspirational-candidate .aspirational-thumbnail {
  width: clamp(358px, 358px + 225 * (100vw - 768px) / 672, 583px);
}

.aspirational.aspirational-candidate .aspirational-thumbnail::before {
  padding-top: 89.315%;
}

.aspirational.aspirational-candidate .aspirational__wrapper {
  gap: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
  border: 1px solid var(--light-line-i);
}

.aspirational.aspirational-candidate .aspirational__col {
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.text-image-section.text-image-section-candidate {
  background: var(--gray-bg);
  padding-top: clamp(32px, 32px + 21 * (100vw - 768px) / 672, 53px);
  background: var(--white);
}

.text-image-section.text-image-section-candidate .text-image-section__content {
  width: 40.99%;
}

.text-image-section.text-image-section-candidate .text-image-section__image {
  width: 54.72%;
  flex-shrink: 0;
}

.text-image-section.text-image-section-candidate .text-image-section__image::before {
  padding-bottom: 75%;
}

.text-image-section.text-image-section-candidate .btn {
  margin-top: 32px;
  color: var(--purple);
}

.text-image-section.text-image-section-candidate .btn:hover {
  color: var(--white);
}

.text-image-section.text-image-section-candidate.text-image-section-candidate-apg {
  padding: clamp(32px, 32px + 88 * (100vw - 768px) / 672, 120px) 0 clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
}

.text-image-section.text-image-section-candidate.text-image-section-candidate-apg .text-image-section__content {
  width: 44.4%;
  padding-top: 11px;
}

.text-image-section.text-image-section-candidate.text-image-section-candidate-apg .text-image-section__content .sup {
  margin-bottom: 20px;
}

.text-image-section.text-image-section-candidate.text-image-section-candidate-apg .text-image-section__content p {
  margin-bottom: 12px;
}

.text-image-section.text-image-section-candidate.text-image-section-candidate-apg .text-image-section__image {
  width: 50.72%;
}

.text-image-section.text-image-section-candidate.text-image-section-candidate-apg .text-image-section__image::before {
  padding-bottom: 78%;
}

.programme {
  padding: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px) 0 clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
  color: var(--white);
  position: relative;
}

.programme-caption {
  width: 100%;
  display: flex;
  gap: clamp(25px, 25px + 31 * (100vw - 768px) / 672, 56px);
  align-items: center;
}

.programme-caption__text {
  width: clamp(370px, 370px + 150 * (100vw - 768px) / 672, 520px);
  max-width: 100%;
  flex-shrink: 0;
}

.programme-caption .sup {
  margin-bottom: 17px;
}

.programme-caption-card {
  flex: 1;
  border: 1px solid var(--light-line-i);
  border-radius: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  padding: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  transform: translateX(0);
  overflow: hidden;
}

.programme-caption-card__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border-radius: inherit;
}

.programme-caption-card__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.programme-caption-card__text {
  width: 100%;
  --line-count: 8;
  --fz: clamp(22px, calc(22px + 6.00 * ((100vw - 768px) / 672)), 28px);
  --line-height: 1.2857;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  letter-spacing: 0.02em;
}

.programme-caption-card .btn {
  color: var(--purple);
}

.programme-caption-card .btn:hover {
  color: var(--white);
}

.programme-offer {
  padding-top: 82px;
  display: flex;
  gap: clamp(25px, 25px + 31 * (100vw - 768px) / 672, 56px);
  align-items: center;
}

.programme-offer .sup {
  margin-bottom: 17px;
}

.programme-offer__title {
  margin-bottom: 26px;
}

.programme-offer-list {
  flex-shrink: 0;
  width: clamp(500px, 500px + 200 * (100vw - 768px) / 672, 700px);
  display: flex;
}

.programme-offer-card {
  width: 50%;
  transform: translateX(0);
  border-radius: clamp(12px, 12px + 20 * (100vw - 768px) / 672, 32px);
  overflow: hidden;
  padding: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 9 * (100vw - 768px) / 672, 29px) clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  display: flex;
  flex-direction: column;
}

.programme-offer-card__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border-radius: inherit;
}

.programme-offer-card__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.programme-offer-card__title {
  font-size: clamp(22px, 22px + 6 * (100vw - 768px) / 672, 28px);
  font-weight: 600;
  line-height: 1.2857;
  margin-bottom: 16px;
  letter-spacing: 0.02em;
}

.programme-offer-card__description {
  --mb: 12px;
  --line-count: 14;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + var(--mb));
  letter-spacing: 0.01em;
  margin-bottom: 22px;
}

.programme-offer-card__description p {
  margin-bottom: 12px;
}

.programme-offer-card__description p:last-of-type {
  margin-bottom: 0;
}

.programme-offer-card .read-more-btn {
  margin-top: auto;
}

.programme-offer-card:first-of-type .read-more-btn:hover {
  color: var(--midnight);
}

.programme-offer-card:first-of-type .read-more-btn:hover i {
  background: var(--midnight);
}

.programme-offer-card:nth-of-type(2) {
  padding: clamp(30px, 30px + 8 * (100vw - 768px) / 672, 38px) clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px) clamp(30px, 30px + 23 * (100vw - 768px) / 672, 53px);
}

.resources {
  padding: clamp(32px, 32px + 88 * (100vw - 768px) / 672, 120px) 0 clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
}

.resources .cont {
  display: flex;
  gap: clamp(25px, 25px + 31 * (100vw - 768px) / 672, 56px);
}

.resources__text {
  flex-shrink: 0;
  width: clamp(245px, 245px + 240 * (100vw - 768px) / 672, 485px);
  max-width: 100%;
}

.resources .sup {
  margin-bottom: 19px;
}

.resources .title {
  margin-bottom: 24px;
  padding-right: clamp(0px, 0px + 90 * (100vw - 768px) / 672, 90px);
}

.resources__cards {
  flex: 1;
}

.resources__cards__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px 6px;
}

.resources-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid var(--light-line-ii);
  background: var(--gray-bg);
  border-radius: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
  padding: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.resources-card__content {
  display: flex;
  gap: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  align-items: center;
}

.resources-card__icon {
  flex-shrink: 0;
  width: clamp(78px, 78px + 10 * (100vw - 768px) / 672, 88px);
  height: auto;
}

.resources-card__text {
  --line-count:5;
  --fz: clamp(18px, calc(18px + 4.00 * ((100vw - 768px) / 672)), 22px);
  --line-height: 1.44;
  flex: 1;
  overflow: hidden;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

.resources-card .read-more-btn {
  margin-right: clamp(-2px, 0px + -2 * (100vw - 768px) / 672, 0px);
}

.partner-section {
  --mb: 270px;
  position: relative;
  padding-top: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  padding-bottom: var(--mb);
  margin-bottom: calc(var(--mb) * -1);
  color: var(--white);
}

.partner-section__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.partner-section__wrapper {
  padding-bottom: clamp(44px, 44px + 21 * (100vw - 768px) / 672, 65px);
  display: flex;
  gap: clamp(25px, 25px + 31 * (100vw - 768px) / 672, 56px);
}

.partner-section .title {
  width: clamp(240px, 240px + 172 * (100vw - 768px) / 672, 412px);
  max-width: 100%;
  flex-shrink: 0;
}

.partner-section-item {
  border-bottom: 1px solid var(--rose);
  padding-bottom: 16px;
}

.partner-section-item:last-of-type {
  border-bottom: 0;
}

.partner-section-item__text, .partner-section-item__title {
  padding-left: clamp(38px, 38px + 10 * (100vw - 768px) / 672, 48px);
}

.partner-section-item__title {
  position: relative;
  margin-bottom: 8px;
}

.partner-section-item__title i {
  content: "";
  display: block;
  position: absolute;
  left: 6px;
  top: 0;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  transform: translateY(25%);
}

.aspirational.aspirational-candidate-contact {
  transform: translate(0);
  padding-bottom: clamp(30px, 32px + -2 * (100vw - 768px) / 672, 32px);
}

.aspirational.aspirational-candidate-contact::before {
  content: "";
  display: block;
  width: 100%;
  height: calc(50% + 19px);
  position: absolute;
  left: 0;
  bottom: 0;
  top: auto;
  z-index: -1;
  background: var(--gray-bg);
}

.aspirational.aspirational-candidate-contact .aspirational__wrapper {
  border: 1px solid var(--light-line-i);
}

.aspirational.aspirational-candidate-contact .aspirational__col {
  padding-top: clamp(35px, 35px + 47 * (100vw - 768px) / 672, 82px);
  padding-bottom: clamp(35px, 35px + 47 * (100vw - 768px) / 672, 82px);
}

.aspirational.aspirational-candidate-contact .aspirational-thumbnail {
  width: clamp(320px, 320px + 262 * (100vw - 768px) / 672, 582px);
}

.aspirational.aspirational-candidate-contact .aspirational-thumbnail::before {
  padding-top: 87.29%;
}

.work-section {
  padding: clamp(32px, 32px + 58 * (100vw - 768px) / 672, 90px) 0 clamp(32px, 32px + 88 * (100vw - 768px) / 672, 120px);
  background: var(--gray-bg);
}

.work-section .sup {
  margin-bottom: 20px;
}

.work-section__intro {
  flex-shrink: 0;
  width: clamp(375px, 375px + 193 * (100vw - 768px) / 672, 568px);
  max-width: 100%;
}

.work-section__caption {
  display: flex;
  gap: clamp(25px, 25px + 55 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  align-items: flex-end;
}

.work-swiper-wr .swiper {
  padding-bottom: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
}

.work-swiper-wr .swiper-slide {
  width: clamp(375px, 375px + 37 * (100vw - 768px) / 672, 412px);
  max-width: 100%;
  display: flex;
  height: auto;
}

.work-swiper-wr .swiper-controls {
  display: flex;
  align-items: center;
}

.work-swiper-wr .swiper-controls .swiper-scrollbar {
  width: 100%;
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}

.work-card {
  border-radius: 24px 0 24px 24px;
  border: 1px solid var(--light-line-ii);
  padding: clamp(20px, 20px + 11 * (100vw - 768px) / 672, 31px);
  background: var(--white);
  transform: translate(0);
  width: 100%;
  overflow: hidden;
}

.work-card__bg-layer {
  position: absolute;
  width: clamp(40px, 40px + 48 * (100vw - 1100px) / 340, 88px);
  max-width: 88px;
  z-index: -1;
  right: 0;
  top: 0;
  opacity: 0.12;
}

.work-card__bg-layer img {
  width: 100%;
}

@media (max-width: 420px) {
  .work-card__bg-layer {
    width: 38px;
  }
}
.work-card__header {
  display: flex;
  align-items: center;
  gap: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  padding-bottom: clamp(15px, 15px + 11 * (100vw - 768px) / 672, 26px);
  border-bottom: 1px solid var(--light-line-ii);
  margin-bottom: clamp(15px, 15px + 9 * (100vw - 768px) / 672, 24px);
  min-height: clamp(80px, 80px + 12 * (100vw - 768px) / 672, 92px);
  padding-right: clamp(0px, 0px + 20 * (100vw - 1100px) / 340, 20px);
  color: var(--black);
}

.work-card__header span {
  display: flex;
  min-width: 48px;
  justify-content: center;
}

.work-card__description {
  --line-count: 9;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.5;
  line-height: var(--line-height);
  letter-spacing: 0.01em;
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

.ed-jobs-section {
  --swiper-padding: 275px;
  padding: clamp(40px, 40px + 44 * (100vw - 768px) / 672, 84px) 0 clamp(32px, 32px + 88 * (100vw - 768px) / 672, 120px);
}

.ed-jobs-section .sup {
  margin-bottom: 20px;
}

.ed-jobs-section__wrapper {
  display: flex;
  justify-content: space-between;
  gap: clamp(25px, 25px + 31 * (100vw - 768px) / 672, 56px);
}

.ed-jobs-section__header {
  width: clamp(375px, 375px + 170 * (100vw - 768px) / 672, 545px);
  max-width: 100%;
  padding-bottom: calc(var(--swiper-padding) + 80px);
  padding-top: 35px;
}

.ed-jobs-section .swiper-controls {
  padding-top: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
}

.ed-jobs-swiper-wr .swiper {
  align-self: flex-end;
  width: clamp(300px, 300px + 112 * (100vw - 768px) / 672, 412px);
  max-width: 100%;
  padding-top: var(--swiper-padding);
  margin: 0;
}

.ed-jobs-swiper-wr .swiper-slide {
  display: flex;
  height: auto;
}

.ed-jobs-swiper-wr .swiper-slide.swiper-slide-active .ed-jobs-card__thumbnail {
  transform: translateY(-100%);
  transition: 0.3s 0.3s;
}

.ed-jobs-swiper-wr .swiper-slide.swiper-slide-active .ed-jobs-card__inner {
  background: var(--midnight);
  color: var(--white);
}

.ed-jobs-swiper-wr .swiper-slide.swiper-slide-active .ed-jobs-card__inner .read-more-btn {
  color: var(--white);
}

.ed-jobs-swiper-wr .swiper-slide.swiper-slide-active .ed-jobs-card__inner .read-more-btn i {
  border-color: var(--white);
  color: var(--white);
}

.ed-jobs-card {
  position: relative;
  transform: translate(0);
  display: flex;
  width: 100%;
  color: var(--black);
}

.ed-jobs-card__thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border-radius: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
  transform: translateY(-32px);
  transition: 0.3s;
  border: 1px solid var(--light-line-i);
}

.ed-jobs-card__thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.ed-jobs-card__inner {
  border-radius: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
  padding: 24px 32px;
  background: var(--gray-bg);
  min-height: clamp(233px, 233px + 42 * (100vw - 768px) / 672, 275px);
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  width: 100%;
}

.ed-jobs-card__title {
  margin-bottom: 12px;
}

.ed-jobs-card__description {
  --line-count:4;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.5;
  width: 100%;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  margin-bottom: 25px;
}

.ed-jobs-card .flex {
  margin-top: auto;
}

.ed-jobs-card:hover .ed-jobs-card__inner {
  background: var(--purple);
  color: var(--white);
}

.ed-jobs-card:hover .ed-jobs-card__inner .read-more-btn {
  color: var(--white);
}

.ed-jobs-card:hover .ed-jobs-card__inner .read-more-btn i {
  color: var(--white);
  border-color: var(--white);
}

.contact-us.contact-us-candidates .contact-us__title {
  font-size: clamp(36px, 36px + 28 * (100vw - 768px) / 672, 64px);
  line-height: 1.125;
  margin-bottom: 22px;
}

.contact-us.contact-us-candidates .contact-us__subtitle {
  font-size: clamp(18px, 18px + 4 * (100vw - 768px) / 672, 22px);
  line-height: 1.444;
  letter-spacing: 0.02em;
  font-weight: 500;
}

@media (max-width: 750px) {
  .hero-candidates__thumbnail::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: var(--midnight);
    opacity: 0.5;
    z-index: 3;
  }
  .hero-candidates__thumbnail img {
    -o-object-position: 60% center;
    object-position: 60% center;
  }
  .ects-wrapper {
    flex-direction: column;
  }
  .ects-options {
    max-width: 100%;
    width: 100%;
  }
  .ects-options-item__content [data-simplebar] {
    --line-count: 8;
  }
  .ects-options-item__content.active {
    max-height: 280px;
    padding: 29px 20px 32px;
  }
}
@media (max-width: 1200px) {
  .programme-caption {
    flex-direction: column;
  }
  .programme-caption__text {
    width: 100%;
  }
  .programme-caption-card {
    width: 600px;
    max-width: 100%;
  }
  .programme-offer {
    flex-direction: column-reverse;
  }
  .programme-offer-list {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .partner-section__wrapper {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .aspirational.aspirational-candidate-contact:before {
    height: calc(100% - 200px);
  }
  .work-section__caption {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 750px) {
  .hero-candidates__wrapper {
    align-items: stretch;
    padding-top: 40px;
  }
  .hero-candidates__wrapper .cont {
    display: flex;
    justify-content: center;
  }
  .hero-candidates__wrapper .hero__caption {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .hero-candidates__wrapper .hero__title {
    font-size: clamp(35px, 35px + 11 * (100vw - 320px) / 448, 46px);
  }
  .hero-candidates__wrapper .hero__description {
    margin-bottom: 35px;
  }
  .hero-candidates__wrapper .hero__btn-box {
    margin-top: auto;
  }
  .ed-jobs-section__wrapper {
    flex-direction: column;
  }
  .ed-jobs-section__header {
    padding-bottom: 0;
    padding-top: 0;
  }
  .ed-jobs-swiper-wr .swiper {
    width: 375px;
  }
}
@media not all and (min-width: 640px) {
  .text-image-section.text-image-section-candidate .text-image-section__wrap {
    gap: 35px;
  }
  .programme-caption-card__text {
    --line-count: 12;
  }
  .programme-offer-list {
    flex-direction: column;
    gap: 15px;
  }
  .programme-offer-card {
    width: 100%;
  }
  .resources .cont {
    flex-direction: column;
  }
  .resources__text {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .resources .title {
    padding-right: clamp(40px, 40px + 30 * (100vw - 320px) / 100, 70px);
  }
  .resources-card__content {
    flex-direction: column;
    text-align: center;
  }
  .resources-card .flex {
    justify-content: center;
  }
}
.hero-clients {
  /*min-height: clac(100 * var(--dvh, 1vh));*/
  min-height: 0;
  height: auto;
  position: relative;
  display: flex;
}

.hero-clients__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.hero-clients__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero-clients__thumbnail {
  position: absolute;
  width: 49.3055555556%;
  max-width: 710px;
  left: clamp(0px, 0px + 569 * (100vw - 1440px) / 1120, 569px);
  top: 50%;
  transform: translateY(-50%);
}

.hero-clients__thumbnail::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.hero-clients__thumbnail-front {
  position: absolute;
  width: 74.08%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: var(--pink);
}

.hero-clients__thumbnail-front::before {
  content: "";
  display: block;
  padding-top: 100%;
  background: var(--pink);
  opacity: 0.5;
  z-index: 1;
  position: relative;
  mix-blend-mode: color;
}

.hero-clients__thumbnail-front img {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.hero-clients__thumbnail-back img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -o-object-position: right center;
  object-position: right center;
}

.hero-clients__wrapper {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  min-height: clamp(381px, 381px + 329 * (100vw - 768px) / 672, 710px);
}

.hero-clients .hero__caption {
  max-width: clamp(347px, 347px + 245 * (100vw - 768px) / 672, 592px);
  margin-left: auto;
  position: relative;
  z-index: 4;
}

.hero-clients .hero__title {
  margin-bottom: 18px;
}

.hero-clients .hero__btn-box {
  margin-top: clamp(40px, 40px + 8 * (100vw - 768px) / 672, 48px);
  width: calc(100% + clamp(0px, 0px + 40 * (100vw - 768px) / 672, 40px));
}

.simple-text-section {
  padding: clamp(60px, 60px + 22 * (100vw - 768px) / 672, 82px) 0;
  background: var(--gray-bg);
  position: relative;
}

.simple-text-section .title {
  margin-bottom: 24px;
}

.hr-img-layer {
  position: relative;
  width: 100%;
  height: clamp(90px, 90px + 70 * (100vw - 768px) / 672, 160px);
}

.hr-img-layer img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.text-image-section.text-image-section-clients .bg-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
}

.text-image-section.text-image-section-clients .text-image-section__wrap {
  gap: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px);
}

.text-image-section.text-image-section-clients .text-image-section__image {
  width: clamp(280px, 280px + 348 * (100vw - 768px) / 672, 628px);
}

.text-image-section.text-image-section-clients .text-image-section__image::before {
  padding-bottom: 100%;
}

.text-image-section.text-image-section-clients .text-image-section__content {
  flex: 1;
}

.text-image-section.text-image-section-clients .text-image-section__text {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: clamp(24px, 24px + 3 * (100vw - 768px) / 672, 27px);
  letter-spacing: 0.01em;
}

.text-image-section.text-image-section-clients .text-image-section__text p:first-of-type {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: 1.4545;
  letter-spacing: 0.02em;
  font-weight: 500;
  margin-bottom: 24px;
}

.empowering-section {
  background: var(--gray-bg);
  padding: clamp(60px, 60px + 60 * (100vw - 768px) / 672, 120px) 0 40px;
}

.empowering-section .sup {
  margin-bottom: 17px;
}

.empowering-section .title {
  margin-bottom: 26px;
}

.empowering-section__description p {
  margin-bottom: 12px;
}

.empowering-section__description p:first-of-type {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: 1.4545;
  letter-spacing: 0.02em;
  font-weight: 500;
  margin-bottom: 24px;
}

.empowering-section__description p:last-of-type {
  margin-bottom: 0;
}

.empowering-section__wrapper {
  display: flex;
  gap: clamp(30px, 30px + 15 * (100vw - 768px) / 672, 45px);
  align-items: center;
}

.empowering-section__intro {
  flex: 1;
  padding-bottom: 5px;
}

.empowering-section-thumbnail {
  width: clamp(320px, 320px + 308 * (100vw - 768px) / 672, 628px);
  flex-shrink: 0;
}

.empowering-section-thumbnail__wrapper {
  width: 92.3566878981%;
  position: relative;
  overflow: hidden;
  border-radius: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  background: var(--lavender);
  margin-bottom: clamp(-279px, -125px + -154 * (100vw - 375px) / 1065, -125px);
}

.empowering-section-thumbnail__wrapper::before {
  content: "";
  display: block;
  padding-top: 125.34%;
}

.empowering-section-thumbnail__wrapper img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.empowering-section-thumbnail__description {
  width: 92.3566878981%;
  margin-left: auto;
  padding: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 18 * (100vw - 768px) / 672, 38px) clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  background: var(--midnight);
  border-radius: clamp(12px, 12px + 20 * (100vw - 768px) / 672, 32px);
  border: 1px solid var(--purple);
  color: var(--white);
  letter-spacing: 0.01em;
  position: relative;
  z-index: 1;
}

.empowering-section-thumbnail__description-text {
  width: 100%;
  --line-count: 12;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

.empowering-section-thumbnail__description .btn {
  margin-top: 24px;
}

.talent-section {
  padding: clamp(65px, 65px + 55 * (100vw - 768px) / 672, 120px) 0 86px;
}

.talent-section__wrapper {
  display: flex;
  gap: 19px;
  flex-direction: column;
}

.talent-item {
  border: 1px solid var(--light-line-i);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 0 0 0 0;
  transition: 0.3s;
}

.talent-item__bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  transition: 0.3s;
  opacity: 0;
}

.talent-item__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.talent-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: clamp(20px, 20px + 9 * (100vw - 768px) / 672, 29px) clamp(10px, 10px + 18 * (100vw - 768px) / 672, 28px) clamp(20px, 20px + 9 * (100vw - 768px) / 672, 29px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  transition: 0.3s;
  cursor: pointer;
}

.talent-item__header span {
  cursor: pointer;
  transition: color 0.3s;
  color: var(--black);
}

.talent-item__header:hover span {
  color: var(--pink);
}

.talent-item__header .toggle-btn {
  --icon-size: 14px;
  width: clamp(40px, 40px + 16 * (100vw - 768px) / 672, 56px);
  height: clamp(40px, 40px + 16 * (100vw - 768px) / 672, 56px);
}

.talent-item__content {
  border-top: 1px solid transparent;
  line-height: 1.2857;
  letter-spacing: 0.02em;
  max-height: 0;
  padding: 0 clamp(10px, 10px + 18 * (100vw - 768px) / 672, 28px) 0 clamp(20px, 20px + 9 * (100vw - 768px) / 672, 29px);
  overflow: hidden;
  transition: 0.3s;
}

.talent-item__description {
  --line-count: 0;
  --fz: clamp(24px, calc(24px + 4.00 * ((100vw - 768px) / 672)), 28px);
  --line-height: 1.2857;
  letter-spacing: 0.02em;
  margin-bottom: 48px;
  line-height: var(--line-height);
  font-size: var(--fz);
  color: var(--white);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  transition: 0.3s;
}

.talent-item__description.simplebar-scrollable-y {
  padding-right: 10px;
  width: calc(100% + 10px);
}

.talent-item__description .simplebar-track.simplebar-vertical {
  opacity: 0;
}

.talent-item.active {
  color: var(--white);
  border-radius: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.talent-item.active .talent-item__header {
  padding-top: clamp(30px, 30px + 19 * (100vw - 768px) / 672, 49px);
  padding-bottom: 21px;
  padding-left: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
  padding-right: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
}

.talent-item.active .talent-item__header span {
  color: var(--white);
}

.talent-item.active .talent-item__header:hover span {
  color: var(--light-line-i);
}

.talent-item.active .talent-item__content {
  border-top: 1px solid var(--rose);
  padding-top: 24px;
  padding-left: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
  padding-right: clamp(20px, 20px + 27 * (100vw - 768px) / 672, 47px);
  padding-bottom: clamp(35px, 35px + 21 * (100vw - 768px) / 672, 56px);
  max-height: 500px;
  overflow: visible;
}

.talent-item.active .talent-item__description {
  --line-count: 8;
}

.talent-item.active .talent-item__description .simplebar-track.simplebar-vertical {
  transition: opacity 0s 1s;
  opacity: 1;
}

.talent-item.active .toggle-btn {
  opacity: 0;
  pointer-events: none;
}

.talent-item.active .talent-item__bg {
  opacity: 1;
}

.benefits-section-wrapper {
  background: var(--gray-bg);
}

.benefits-section-wrapper .aspirational {
  position: relative;
  z-index: 1;
  padding-bottom: 0;
}

.benefits-section-wrapper .aspirational::before {
  display: none;
}

.benefits-section-wrapper .benefits-section {
  --mb: 266px;
  padding-bottom: var(--mb);
  margin-bottom: calc(var(--mb) * -1);
}

.benefits-section-wrapper .benefits-section .cont {
  padding-bottom: clamp(40px, 40px + 43 * (100vw - 768px) / 672, 83px);
}

.benefits-swiper-wr .swiper-slide {
  display: flex;
  height: auto;
}

.benefits-section {
  position: relative;
  padding: 119px 0;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

.benefits-section .section-bg {
  z-index: -1;
  position: fixed;
}

.benefits-section .sup {
  margin-bottom: 19px;
}

.benefits-section .title {
  text-align: center;
  max-width: 500px;
  margin: 0 auto clamp(35px, 35px + 22 * (100vw - 768px) / 672, 57px);
}

.benefits-section .slider-btn {
  margin-top: 45px;
}

.benefits-card {
  width: 100%;
  position: relative;
  transform: translate(0);
  padding: 32px 16px;
  text-align: center;
  border: 1px solid var(--light-line-i);
  border-radius: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
  overflow: hidden;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: clamp(250px, 250px + 63 * (100vw - 768px) / 672, 313px);
}

.benefits-card__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border-radius: inherit;
  background: var(--purple);
}

.benefits-card__bg::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, #560b75 35%, rgba(86, 11, 117, 0) 100%);
  z-index: 1;
}

.benefits-card__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
  z-index: 0;
}

.benefits-card__description {
  width: 100%;
  --line-count: 5;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.54;
  letter-spacing: 0.01em;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

.aspirational.aspirational-benefits .aspirational__wrapper {
  gap: clamp(35px, 35px + 21 * (100vw - 768px) / 672, 56px);
}

.aspirational.aspirational-benefits .aspirational-thumbnail {
  background: var(--pink);
  width: clamp(350px, 350px + 233 * (100vw - 768px) / 672, 583px);
}

.aspirational.aspirational-benefits .aspirational-thumbnail::before {
  padding-top: 89.19382504%;
}

.aspirational.aspirational-benefits .aspirational-thumbnail__bg img {
  width: calc(100% + 2px);
  max-width: revert;
}

.aspirational.aspirational-benefits .aspirational__col {
  justify-content: center;
  padding-top: 54px;
  padding-bottom: 54px;
}

.how-section {
  padding: clamp(60px, 60px + 58 * (100vw - 768px) / 672, 118px) 0 clamp(50px, 50px + 38 * (100vw - 768px) / 672, 88px);
}

.how-section .title {
  margin-bottom: clamp(35px, 35px + 21 * (100vw - 768px) / 672, 56px);
}

.how-section__wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: clamp(35px, 35px + 29 * (100vw - 768px) / 672, 64px);
}

.how-section__col p {
  margin-bottom: 14px;
}

.how-section__col p:last-of-type {
  margin-bottom: 0;
}

.how-section__col a {
  display: inline;
  color: var(--purple);
  text-decoration: underline;
  transition: 0.3s;
  text-underline-offset: 2px;
}

.how-section__col a:hover {
  color: var(--rose);
}

.contact-us-interior.contact-us-clients .contact-us__title {
  font-size: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  line-height: 1.1428;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.contact-us-interior.contact-us-clients .contact-us__subtitle {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  line-height: 1.4545;
  letter-spacing: 0.02em;
  font-weight: 500;
}

@media (max-width: 768px) {
  .hero-clients {
    min-height: calc(100 * var(--vh, 1vh));
  }
  .hero-clients__wrapper {
    align-items: stretch;
  }
  .hero-clients__wrapper .cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .hero-clients__thumbnail {
    position: relative;
    width: 550px;
    max-width: 80%;
    margin: 0 auto 35px;
    top: 0;
    transform: none;
  }
  .hero-clients__thumbnail-back img {
    transform: rotate(90deg);
  }
  .hero-clients .hero__caption {
    margin-top: auto;
    max-width: 100%;
    padding-bottom: 60px;
  }
}
@media (max-width: 850px) {
  .benefits-swiper-wr .swiper-slide {
    width: 250px;
    max-width: 100%;
  }
  .benefits-card {
    min-height: 250px;
  }
}
@media (max-width: 750px) {
  .empowering-section__wrapper {
    flex-direction: column;
  }
  .empowering-section-thumbnail {
    width: 450px;
    max-width: 100%;
  }
}
@media (max-width: 650px) {
  .how-section__wrapper {
    grid-template-columns: 1fr;
    gap: 14px;
  }
}
.agp-page img {
  width: 100%;
  height: 100%;
}

@media (min-width: 751px) {
  .job-details-page {
    overflow: visible !important;
  }
}
.hero-job-details {
  color: var(--white);
  height: auto;
  min-height: 0;
  overflow: hidden;
}

.hero-job-details__wrapper {
  position: relative;
  min-height: 573px;
  display: flex;
  padding: clamp(50px, 50px + 38 * (100vw - 768px) / 672, 88px) 0 50px;
}

.hero-job-details__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.hero-job-details__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.hero-job-details .cont {
  display: flex;
  align-items: center;
  gap: clamp(35px, 35px + 29 * (100vw - 768px) / 672, 64px);
}

.hero-job-details .sup {
  margin-bottom: 19px;
  font-size: 16px;
  letter-spacing: 0.2em;
  font-weight: 600;
  line-height: 1.5;
  color: var(--gray-bg);
}

.hero-job-details .hero__title {
  font-size: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  line-height: 1.1428;
  letter-spacing: 0.02em;
  font-weight: 600;
  text-transform: revert;
  margin-bottom: 25px;
}

.hero-job-details .hero__caption {
  width: clamp(320px, 320px + 308 * (100vw - 768px) / 672, 628px);
  flex-shrink: 0;
  max-width: 100%;
}

.hero-job-details .hero__info-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 40px 48px 24px;
  position: relative;
  flex: 1;
  max-width: 500px;
}

.hero-job-details .hero__info-list li {
  padding-bottom: 14px;
  position: relative;
  color: var(--purple);
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
}

.hero-job-details .hero__info-list li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: clamp(0px, 0px + 570 * (100vw - 768px) / 672, 570px);
  min-width: 100%;
  height: 1px;
  background: var(--pink);
}

.hero-job-details .hero__info-list li:last-of-type {
  margin-bottom: 0;
}

.hero-job-details .hero__info-list li:last-of-type::before {
  display: none;
}

.hero-job-details .hero__info-list::before {
  content: "";
  display: block;
  background: var(--gray-bg);
  border-radius: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px) 0 0 clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  width: 666px;
  height: 100%;
  pointer-events: none;
}

.hero-job-details .read-more-btn:hover {
  color: var(--purple);
}

.hero-job-details .read-more-btn:hover i {
  background: var(--purple);
}

.hero-job-details .hero__btn-box {
  margin-top: clamp(35px, 35px + 15 * (100vw - 768px) / 672, 50px);
}

.job-details-section {
  padding: 64px 0 clamp(50px, 50px + 7 * (100vw - 768px) / 672, 57px);
  position: relative;
  background: var(--gray-bg);
  transform: translate(0);
}

.job-details-section .section-bg-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  background: var(--white);
  z-index: -1;
}

.job-details-section__wrapper {
  display: flex;
  gap: clamp(25px, 25px + 17 * (100vw - 768px) / 672, 42px);
}

.job-details-section__main {
  width: clamp(400px, 400px + 530 * (100vw - 768px) / 672, 930px);
  flex-shrink: 0;
}

.job-details-section__main-top {
  padding-bottom: 64px;
}

.job-details-section__main-top p {
  margin-bottom: 14px;
}

.job-details-section__main-top p:first-of-type {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
  margin-bottom: 32px !important;
}

.job-details-section__main-top p:last-of-type {
  margin-bottom: 0;
}

.job-details-section__main .content-element {
  color: var(--black);
}

.job-details-section__main .content-element a {
  display: inline-block;
  text-decoration: underline;
  transition: 0.2s;
}

.job-details-section__main .content-element a:hover {
  color: var(--pink);
}

.job-details-section__main .content-element p {
  margin-bottom: 14px;
}

.job-details-section__main .content-element p:last-of-type {
  margin-bottom: 0;
}

.job-details-section__main .content-element h2 {
  margin-bottom: 24px;
  font-size: clamp(28px, 28px + 16 * (100vw - 768px) / 672, 44px);
  line-height: 1.2272;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.job-details-section__main .content-element h3 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 24px;
}

.job-details-section__main .content-element ul, .job-details-section__main .content-element ol {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #141D24;
}

.job-details-section__main .content-element ul li, .job-details-section__main .content-element ol li {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.5;
  font-weight: 300;
  font-family: var(--font-second);
}

.job-details-section__main .content-element ul li {
  padding-left: clamp(26px, 26px + 10 * (100vw - 768px) / 672, 36px);
  margin-bottom: 0 !important;
}

.job-details-section__main .content-element ul li::before {
  content: "";
  border-radius: 0;
  display: block;
  width: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  height: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  background: url("../../assets/images/list-marker.svg") no-repeat center/contain;
  left: 4px;
  top: 50%;
  opacity: 0.7;
  transform: translateY(-50%);
}

.job-details-section__main-bottom {
  padding-top: 64px;
}

.job-details-section__main-bottom .content-element {
  color: var(--black);
}

.job-details-section__main-bottom .content-element p {
  margin-bottom: 14px;
}

.job-details-section__main-bottom .content-element p:last-of-type {
  margin-bottom: 0;
}

.job-details-section__main-bottom .content-element h2 {
  margin-bottom: 24px;
  font-size: clamp(28px, 28px + 16 * (100vw - 768px) / 672, 44px);
  line-height: 1.2272;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.job-details-section__main-bottom .content-element h3 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 24px;
}

.job-details-section__main-bottom .content-element ul, .job-details-section__main-bottom .content-element ol {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #141D24;
}

.job-details-section__main-bottom .content-element ul li, .job-details-section__main-bottom .content-element ol li {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.job-details-section__main-bottom .content-element ul {
  gap: 18px;
}

.job-details-section__main-bottom .content-element ul li {
  padding-left: clamp(26px, 26px + 10 * (100vw - 768px) / 672, 36px);
  margin-bottom: 0 !important;
}

.job-details-section__main-bottom .content-element ul li::before {
  content: "";
  display: block;
  width: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  height: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  background: url("../../assets/images/list-marker.svg") no-repeat center/contain;
  left: 1px;
  top: 50%;
  opacity: 0.7;
  border-radius: 0;
  transform: translateY(-50%);
}

.job-details-section__aside-wrapper {
  position: sticky;
  top: 100px;
}

.job-details-section__aside .teammate-card__name {
  margin-bottom: 9px;
}

.job-details-section__aside .teammate-card-thumbnail {
  border-color: var(--light-line-ii);
}

.job-details-section__aside .teammate-card__position {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1.5;
  margin-bottom: 24px;
}

.jobs.jobs-job-details {
  padding-top: clamp(50px, 50px + 70 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(80px, 80px + 44 * (100vw - 768px) / 672, 124px);
  background: var(--gray-bg);
  overflow: hidden;
}

.jobs.jobs-job-details .jobs__suptitle {
  margin-bottom: 17px;
}

.apply-section {
  position: relative;
  color: var(--white);
  padding: 82px 0 64px;
  overflow: hidden;
}

.apply-section__wrapper {
  max-width: 932px;
}

.apply-section .title {
  margin-bottom: 34px;
}

.apply-section__description {
  padding-bottom: 48px;
}

.apply-section__description p {
  margin-bottom: 18px;
}

.apply-section__description p:last-of-type {
  margin-bottom: 0;
}

.apply-section .buttons {
  margin-bottom: 49px;
  gap: clamp(15px, 15px + 5 * (100vw - 768px) / 672, 20px);
}

.contact-us-job-details.contact-us-interior {
  overflow: hidden;
}

.contact-us-job-details.contact-us-interior .contact-us__title {
  font-size: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  line-height: 1.1428;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-bottom: 20px;
}

@media (max-width: 750px) {
  .hero-job-details__wrapper {
    padding-top: 100px;
  }
  .hero-job-details .hero__caption {
    width: 100%;
  }
  .hero-job-details .cont {
    flex-direction: column;
  }
  .hero-job-details .hero__info-list {
    max-width: 100%;
    width: 100%;
    padding: 30px 20px 10px;
  }
  .hero-job-details .hero__info-list::before {
    width: 100%;
    border-radius: 30px;
  }
  .job-details-section__wrapper {
    flex-direction: column;
    gap: 35px;
  }
  .job-details-section__main {
    width: 100%;
  }
  .job-details-section__aside .teammate-card {
    width: 375px;
    margin: 0 auto;
    max-width: 100%;
  }
  .job-details-about-section__wrapper {
    width: 100%;
  }
}
.hero-landing {
  height: auto;
  min-height: 0;
}

.hero-landing__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.hero-landing__bg::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  z-index: 2;
  background: #560b75;
  opacity: 0.5;
}

.hero-landing__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.hero-landing__wrapper {
  height: auto;
  min-height: calc(100 * var(--vh, 1vh) - var(--header-height));
  padding: clamp(50px, 100px + -50 * (100vw - 768px) / 672, 100px) 0 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.hero-landing .hero__title {
  margin-bottom: clamp(35px, 35px + 15 * (100vw - 768px) / 672, 50px);
}

.hero-landing .hero__title span {
  display: block;
  margin-top: clamp(10px, 10px + 4 * (100vw - 768px) / 672, 14px);
}

.hero-landing .hero__caption {
  width: 628px;
  max-width: 100%;
}

.hero-landing .btn--transp {
  color: var(--white);
}

.hero-landing .btn--transp:hover {
  color: var(--purple);
}

.landing-text-section {
  padding: clamp(60px, 60px + 22 * (100vw - 768px) / 672, 82px) 0;
  font-size: clamp(22px, 22px + 6 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  background: var(--gray-bg);
  letter-spacing: 0.02em;
  color: var(--black);
}

.simple-text-section.simple-text-section-landing {
  background: var(--white);
}

.simple-text-section.simple-text-section-landing .sup {
  margin-bottom: 18px;
}

.simple-text-section.simple-text-section-landing .simple-text-section-wrapper {
  display: flex;
  gap: 24px;
}

.simple-text-section.simple-text-section-landing .simple-text-section__intro {
  width: clamp(250px, 250px + 158 * (100vw - 768px) / 672, 408px);
  flex-shrink: 0;
  padding-top: clamp(0px, 0px + 5 * (100vw - 768px) / 672, 5px);
}

.simple-text-section.simple-text-section-landing .simple-text-section-description {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  letter-spacing: 0.01em;
}

.simple-text-section.simple-text-section-landing .simple-text-section-description p {
  margin-bottom: 14px;
}

.simple-text-section.simple-text-section-landing .simple-text-section-description p:last-of-type {
  margin-bottom: 0;
}

.jobs-offer-wrapper {
  position: relative;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

.jobs-offer-wrapper .section-bg {
  position: fixed;
}

.jobs-offer-wrapper .section-bg-mob {
  display: none;
}

@media (max-width: 650px) {
  .jobs-offer-wrapper .section-bg {
    display: none;
  }
  .jobs-offer-wrapper .section-bg-mob {
    display: block;
  }
}
.jobs-double-row {
  padding-top: clamp(60px, 60px + 60 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(60px, 60px + 22 * (100vw - 768px) / 672, 82px);
}

.jobs-double-row .sup {
  margin-bottom: 20px;
}

.jobs-swiper-wrapper-box {
  display: flex;
  flex-direction: column;
  gap: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  width: 100%;
  padding-top: clamp(40px, 40px + 16 * (100vw - 768px) / 672, 56px);
}

.jobs-swiper-wrapper-box .swiper {
  width: clamp(675px, 675px + 169 * (100vw - 768px) / 672, 844px);
  max-width: 100%;
  margin: 0 0 0 clamp(0px, 0px + 257 * (100vw - 768px) / 672, 257px);
}

.jobs-swiper-wrapper-box .swiper:nth-of-type(even) {
  margin-left: clamp(-10px, -10px + 51 * (100vw - 768px) / 672, 41px);
}

.offer-section {
  overflow: hidden;
  transform: translate(0);
}

.offer-section__wrapper {
  display: flex;
}

.offer-section .title {
  font-size: clamp(26px, 26px + 10 * (100vw - 768px) / 672, 36px);
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--purple);
  margin-bottom: clamp(25px, 25px + 7 * (100vw - 768px) / 672, 32px);
  line-height: 1.333;
}

.offer-section__col {
  width: 50%;
  flex-shrink: 0;
  position: relative;
  padding: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px) 0 clamp(30px, 30px + 19 * (100vw - 768px) / 672, 49px) clamp(20px, 20px + 44 * (100vw - 768px) / 672, 64px);
}

.offer-section__col::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: var(--white);
  z-index: -1;
  border-radius: 48px;
  border: 1px solid var(--light-line-i);
}

.offer-section__col:nth-of-type(1) {
  padding: clamp(30px, 30px + 26 * (100vw - 768px) / 672, 56px) clamp(20px, 20px + 44 * (100vw - 768px) / 672, 64px) clamp(20px, 20px + 29 * (100vw - 768px) / 672, 49px) 0;
}

.offer-section__col:nth-of-type(1)::before {
  left: auto;
  right: 0;
  border-radius: 48px;
}

.offer-section-list {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.offer-section-list-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
  padding-left: clamp(38px, 38px + 10 * (100vw - 768px) / 672, 48px);
  padding-bottom: 16px;
  border-bottom: 1px solid var(--light-line-ii);
}

.offer-section-list-item:last-of-type {
  border: 0;
}

.offer-section-list-item i.icon-check-second {
  display: block;
  position: absolute;
  left: 5px;
  top: clamp(13px, 14px + -1 * (100vw - 375px) / 1065, 14px);
  color: var(--pink);
  font-size: clamp(16px, 16px + 3 * (100vw - 768px) / 672, 19px);
}

.offer-section-list-item::before {
  content: "";
  display: block;
}

.offer-section-list-item h3 {
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  font-weight: 600;
  line-height: 1.45;
  letter-spacing: 0.02em;
  color: var(--black);
}

.offer-section-list-item__description {
  width: 100%;
  --line-count: 3;
  --fz: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  --line-height: 1.5;
  line-height: var(--line-height);
  font-size: var(--fz);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
}

.jt-apply-section {
  padding: 82px 0;
  background: var(--gray-bg);
}

.jt-apply-section .sup {
  margin-bottom: 19px;
}

.jt-apply-section .title {
  margin-bottom: 25px;
}

.jt-apply-section__description {
  width: 639px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto 56px;
  color: var(--black);
}

.jt-apply-section-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
}

.jt-apply-section-list .work-card__header {
  padding-right: clamp(0px, 0px + 20 * (100vw - 375px) / 1065, 20px);
}

@media (max-width: 992px) {
  .jt-apply-section-list {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-auto-rows: 1fr;
  }
  .jt-apply-section-list .work-card {
    width: 100%;
    max-width: 400px;
  }
}
@media (max-width: 768px) {
  .jobs-swiper-wrapper-box .swiper {
    margin: 0 !important;
    width: 100%;
  }
}
@media (max-width: 650px) {
  .offer-section {
    padding-bottom: 40px;
  }
  .offer-section__wrapper {
    flex-direction: column;
    gap: 20px;
  }
  .offer-section__col {
    width: 100%;
    padding: 30px 20px !important;
  }
  .offer-section__col::before {
    width: 100%;
    border-radius: 20px !important;
  }
  .offer-section-list-item__description {
    --line-count: 7;
  }
  .simple-text-section.simple-text-section-landing .simple-text-section__intro {
    width: 100%;
  }
  .simple-text-section.simple-text-section-landing .simple-text-section__intro .title {
    margin-bottom: 0;
  }
  .simple-text-section.simple-text-section-landing .simple-text-section-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 420px) {
  .offer-section .title {
    padding-right: clamp(0px, 0px + 100 * (100vw - 320px) / 100, 100px);
  }
}
.contact-us-page {
  overflow: visible !important;
}

.hero-contact {
  height: auto;
  min-height: 0;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

.hero-contact__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.hero-contact__bg img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
}

.hero-contact__wrapper {
  height: auto;
  min-height: calc(100 * var(--vh, 1vh) - var(--header-height));
  padding: clamp(50px, 100px + -50 * (100vw - 768px) / 672, 100px) 0 50px;
  display: flex;
  position: relative;
}

.hero-contact .cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: clamp(35px, 35px + 64 * (100vw - 1300px) / 140, 99px);
}

.hero-contact .hero__caption {
  min-width: 579px;
  flex-shrink: 0;
}

.hero-contact .hero__title {
  margin-bottom: 26px;
  font-size: clamp(36px, 36px + 36 * (100vw - 375px) / 1065, 72px);
}

@media (max-width: 370px) {
  .hero-contact .hero__title {
    font-size: 36px;
  }
}
.hero-contact .hero__description {
  margin-bottom: 48px;
  padding-right: clamp(0px, 0px + 50 * (100vw - 768px) / 672, 50px);
}

.hero-contact .swiper {
  overflow: visible;
}

.contact-swiper-wr {
  flex: 1;
  min-width: 0;
}

.hero__switchers {
  display: flex;
  padding: 3px;
  border: 1px solid var(--light-line-i);
  border-radius: 1000px;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  background: rgba(255, 255, 255, 0.3);
}

.hero__switchers .switch-bg {
  width: 154px;
  height: 44px;
  top: 4px;
  position: absolute;
  left: 5px;
  z-index: 0;
  background: var(--white);
  border-radius: inherit;
  transition: left 0.5s, top 0.5s;
}

.switch-btn {
  display: flex;
  align-items: center;
  padding: 5px clamp(14px, 14px + 9 * (100vw - 768px) / 672, 23px);
  min-height: 44px;
  flex-shrink: 0;
  border-radius: inherit;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  color: var(--white);
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
  font-family: var(--font-second);
  font-weight: 500;
}

.switch-btn.active {
  color: var(--black-regular);
}

.switch-btn:hover {
  color: var(--black);
}

.hero-contact-form {
  border-radius: clamp(12px, 12px + 20 * (100vw - 768px) / 672, 32px);
  border: 1px solid var(--rose);
  padding: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px) clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  background: var(--midnight);
  color: var(--white);
}

.hero-contact-form__title {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--rose);
  margin-bottom: 21px;
}

.hero-contact-form__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px 12px;
}

.hero-contact-form__footer {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  flex-wrap: wrap;
  padding-top: 32px;
  border-top: 1px solid var(--light-line-i);
}

.hero-contact-form .meta-select .choices__inner {
  background: none;
}

.hero-contact-form .meta-select .choices__list--single .choices__item, .hero-contact-form .meta-select .meta-select .choices__list--multiple {
  color: var(--white);
}

.hero-contact-form .meta-select .choices[data-type*=select-one]:after, .hero-contact-form .meta-select .choices[data-type*=select-multiple]:after {
  border-color: var(--white);
  right: clamp(18px, 18px + 3 * (100vw - 768px) / 672, 21px);
  margin-top: -2px;
}

.hero-contact-form .meta-checkbox {
  max-width: 249px;
}

.hero-contact-form input, .hero-contact-form textarea, .hero-contact-form .input-wr {
  color: inherit;
}

.hero-contact-form textarea {
  scrollbar-width: thin;
  scrollbar-color: var(--white) rgba(255, 255, 255, 0.2);
}

.hero-contact-form textarea::-webkit-scrollbar {
  width: 6px;
}

.hero-contact-form textarea::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0;
}

.hero-contact-form textarea::-webkit-scrollbar-thumb {
  background: var(--white);
  border-radius: 0;
}

.hero-contact-form .btn {
  gap: 8px;
  padding: 5px 23px 5px 29px !important;
}

.hero-contact-form_pink {
  background: var(--pink);
  border-color: var(--rose);
}

.hero-contact-form_pink .meta-checkbox.meta-checkbox-light .meta-checkbox__box {
  background: var(--rose);
}

.hero-contact-form_white {
  background: var(--white);
  color: var(--dark-gray);
  border-color: var(--light-line-ii);
}

.hero-contact-form_white .hero-contact-form__title {
  color: var(--black);
}

@media (min-width: 1300px) {
  .hero-contact-form_white .upload-wr {
    gap: 38px;
  }
  .hero-contact-form_white .upload-wr .input-wr {
    max-width: 269px;
  }
}
.hero-contact-form_white .meta-select .choices__list--single .choices__item, .hero-contact-form_white .meta-select .meta-select .choices__list--multiple {
  color: var(--black);
}

.hero-contact-form_white .meta-select .choices[data-type*=select-one]:after, .hero-contact-form_white .meta-select .choices[data-type*=select-multiple]:after {
  border-color: var(--black);
}

.hero-contact-form_white .upload-btn-wr .cv_file_name {
  color: var(--mid-gray);
}

.hero-contact-form_white .upload-btn-wr .upload-btn {
  color: var(--midnight);
}

.hero-contact-form_white .upload-btn-wr .upload-btn i {
  color: var(--pink);
}

.hero-contact-form_white .upload-btn-wr:hover .upload-btn {
  color: var(--pink);
}

.hero-contact-form_white .meta-checkbox.meta-checkbox-light .meta-checkbox__text {
  color: var(--mid-gray);
}

.hero-contact-form_white .meta-checkbox.meta-checkbox-light .meta-checkbox__text a {
  color: var(--pink);
}

.hero-contact-form_white input::-moz-placeholder, .hero-contact-form_white textarea::-moz-placeholder {
  color: inherit !important;
}

.hero-contact-form_white input::placeholder, .hero-contact-form_white textarea::placeholder {
  color: inherit !important;
}

.hero-contact-form_white textarea {
  scrollbar-width: thin;
  scrollbar-color: var(--midnight) rgba(30, 30, 30, 0.2);
}

.hero-contact-form_white textarea::-webkit-scrollbar {
  width: 6px;
}

.hero-contact-form_white textarea::-webkit-scrollbar-track {
  background: rgba(30, 30, 30, 0.2);
  border-radius: 0;
}

.hero-contact-form_white textarea::-webkit-scrollbar-thumb {
  background: var(--midnight);
  border-radius: 0;
}

.upload-wr {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: flex-end;
}

.ht-contact-section {
  padding: clamp(60px, 60px + 21 * (100vw - 768px) / 672, 81px) 0 clamp(60px, 60px + 24 * (100vw - 768px) / 672, 84px);
  background: var(--white);
}

.ht-contact-section .sup {
  margin-bottom: 20px;
}

.ht-contact-section .title {
  margin-bottom: 32px;
}

.ht-contact-section__wrapper {
  display: flex;
  gap: clamp(35px, 35px + 21 * (100vw - 768px) / 672, 56px);
}

.ht-contact-section__map-wrapper {
  flex-shrink: 0;
  width: clamp(320px, 320px + 296 * (100vw - 768px) / 672, 616px);
  margin-top: -7px;
  margin-right: clamp(0px, 0px + 12 * (100vw - 768px) / 672, 12px);
}

.ht-contact-section__map-sticky {
  position: sticky;
  top: 100px;
}

.ht-contact-section__map {
  border-radius: 24px;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-bottom: clamp(20px, 20px + 11 * (100vw - 768px) / 672, 31px);
}

.ht-contact-section__map::before {
  content: "";
  display: block;
  padding-top: 80.032%;
}

.ht-contact-section__map iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  border: 1px solid var(--light-line-ii) !important;
}

.ht-contact-section__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ht-contact-section__list li {
  width: 100%;
  background: var(--gray-bg);
  padding: 24px 32px 32px;
  border-radius: 20px;
  border: 1px solid var(--light-line-ii);
  color: var(--black);
}

.ht-contact-section__list li h3 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.2857;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin-bottom: 20px;
}

.ht-contact-section__list li div {
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: clamp(24px, 24px + 3 * (100vw - 768px) / 672, 27px);
  letter-spacing: 0.01em;
}

.ht-contact-section__list li div p {
  margin-bottom: 12px;
}

.ht-contact-section__list li div p:last-of-type {
  margin-bottom: 0;
}

.ht-contact-section__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ht-contact-section__info li a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--midnight);
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s;
  font-family: var(--font-second);
  font-weight: 500;
}

.ht-contact-section__info li a img {
  width: 32px;
  height: auto;
  flex-shrink: 0;
}

.ht-contact-section__info li a:hover {
  color: var(--pink);
}

@media (max-width: 1200px) {
  .hero-contact .cont {
    flex-direction: column;
  }
  .hero-contact .hero__caption {
    min-width: 0;
    width: 100%;
    max-width: 100%;
  }
  .hero-contact .contact-swiper-wr {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 992px) {
  .ht-contact-section__wrapper {
    flex-direction: column;
  }
  .ht-contact-section__map-wrapper {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .hero-contact-form__grid {
    grid-template-columns: 1fr;
  }
  .hero-contact-form .upload-btn-wr {
    width: 200px;
    max-width: 100%;
    padding: 20px 0;
    flex-wrap: wrap;
  }
  .hero-contact-form .upload-btn-wr .cv_file_name {
    width: 200px;
  }
  .hero__switchers {
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
  }
  .hero__switchers .switch-btn {
    width: 100%;
    justify-content: center;
  }
  .hero__switchers .switch-bg {
    border-radius: 12px;
  }
}
@media (max-width: 450px) {
  .hero-contact-form .upload-btn-wr {
    margin: 0 auto;
  }
}
/*# sourceMappingURL=styles.51b8ee04.css.map */
